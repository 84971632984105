import * as React from 'react';

const SvgMinimize2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgMinimize2"
    {...props}
  >
    <path
      d="M14 10l7-7M10 14l-7 7m17-11h-6V4M4 14h6v6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgMinimize2;
