import React from 'react';

export type LOPlatformColorProps = React.SVGAttributes<SVGElement>;

export const LOPlatformColor: React.FunctionComponent<
  LOPlatformColorProps
> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2656.69"
      height="288.07"
      viewBox="0 0 2656.69 288.07"
      {...props}
    >
      <path
        d="m49.39,210.99c-12.1,3.2-19.2,15.7-16,27.8s15.7,19.2,27.8,16c12.1-3.2,19.2-15.7,16-27.8s-15.7-19.2-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m226.99,210.89c-12.1,3.2-19.2,15.7-16,27.8s15.7,19.2,27.8,16,19.2-15.7,16-27.8-15.7-19.3-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m226.89,33.29c-12.1,3.2-19.2,15.7-16,27.8s15.7,19.2,27.8,16c12.1-3.2,19.2-15.7,16-27.8s-15.7-19.3-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m49.29,33.39c-12.1,3.2-19.2,15.7-16,27.8s15.7,19.2,27.8,16c12.1-3.2,19.2-15.7,16-27.8s-15.7-19.2-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m73.09,122.29c-12.1,3.2-19.2,15.7-16,27.8,3.2,12.1,15.7,19.2,27.8,16s19.2-15.7,16-27.8c-3.3-12.1-15.7-19.3-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m203.19,122.09c-12.1,3.2-19.2,15.7-16,27.8,3.2,12.1,15.7,19.2,27.8,16,12.1-3.2,19.2-15.7,16-27.8-3.3-12.1-15.7-19.3-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m138.19,187.19c-12.1,3.2-19.2,15.7-16,27.8,3.2,12.1,15.7,19.2,27.8,16s19.2-15.7,16-27.8c-3.3-12.1-15.7-19.3-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m137.89,57.19c-12.1,3.2-19.2,15.7-16,27.8,3.2,12.1,15.7,19.2,27.8,16,12.1-3.2,19.2-15.7,16-27.8-3.2-12.1-15.7-19.3-27.8-16h0Z"
        fill="#929da9"
      />
      <path
        d="m192.09,66.09c6,22.5,29.1,35.8,51.6,29.7,3.6-1,7-2.4,10.2-4.2h0c1.4-.8,3-1.4,4.7-1.9,10.8-3,23.5,2.7,27.8,13.1,5.2,12.9-2.1,27-15.1,30.5-10.4,2.8-21-2.1-25.8-11.1-1.1-2.1-2.3-4.1-3.8-5.9-10.3-12.1-27.1-17.9-43.5-13.5-22.7,6.1-36.2,29.5-30.1,52.2s29.5,36.2,52.2,30.1c8.6-2.3,15.9-7.1,21.3-13.4,1.6-1.9,2.9-4,4.1-6.2,3.3-6,9.3-10.5,16.9-11.5,10.2-1.4,20.3,4.5,24,14.1,5,12.8-2.3,26.8-15.2,30.2-6,1.6-12.3.8-17.3-2h0c-9.5-5.5-21.1-7.3-32.5-4-21.2,6-34.3,27.7-29.8,49.2,1,4.5,2.6,8.7,4.8,12.5h0c.8,1.4,1.4,2.9,1.8,4.5,3.2,11.5-2.8,24.4-14.1,28.3-12.6,4.4-26-2.9-29.4-15.5-2.8-10.3,2-20.8,11-25.7,2.1-1.1,4.1-2.4,5.9-3.9,12.2-10.3,18-27.1,13.6-43.6-6.2-23.2-30.4-36.7-53.5-29.7-21.2,6.4-34,28.4-29.3,50,2,9.1,6.8,16.8,13.1,22.5,2.1,1.9,4.6,3.4,7.1,4.8,5.9,3.3,10.4,9.3,11.4,16.8,1.4,10.2-4.5,20.3-14.1,24-12.8,5-26.8-2.3-30.2-15.2-1.6-6-.8-12.3,2-17.3h0c5.5-9.5,7.3-21.1,4-32.5-6-21.2-27.7-34.3-49.2-29.8-4.5,1-8.7,2.6-12.5,4.8h0c-1.7,1-3.6,1.6-5.6,2.1-12.1,3.2-24.5-3.9-27.8-16-3.2-12.1,3.9-24.5,16-27.8,10.4-2.8,21,2.1,25.9,11.2,1.1,2.1,2.3,4.1,3.8,5.8,10.3,12.1,27.1,17.9,43.5,13.5,22.7-6.1,36.2-29.5,30.1-52.2s-29.5-36.2-52.2-30.1c-8.6,2.3-15.9,7.1-21.3,13.5-1.6,1.9-2.9,4-4.1,6.1-3.3,6-9.3,10.4-16.9,11.5-10.2,1.4-20.2-4.5-24-14.1-5-12.8,2.3-26.8,15.2-30.2,6-1.6,12.3-.8,17.3,2h0c9.9,5.7,22.2,7.4,34.1,3.6,20.3-6.6,32.6-27.8,28.2-48.7-1-4.5-2.6-8.7-4.8-12.5h0c-.8-1.3-1.3-2.8-1.8-4.4-3.5-12.1,3.2-25.4,15.3-28.9,12.2-3.5,24.8,3.7,28.1,15.9,2.8,10.4-2.1,21-11.2,25.9-2.1,1.1-4.1,2.3-5.9,3.8-12.2,10.3-18,27.1-13.6,43.6,6.1,22.7,29.5,36.2,52.2,30.1s36.2-29.5,30.1-52.2c-2.1-7.6-6.1-14.2-11.3-19.4-2.6-2.5-5.6-4.6-8.8-6.4-5.2-3.1-9.1-8.1-10.5-14.5-2.5-11.2,3.9-22.8,14.8-26.4,12.5-4.2,25.8,3,29.1,15.6,1.6,6,.8,12.3-2,17.3h0c-5.2,9.3-7,20.7-4,31.9h0Z"
        fill="#929da9"
      />
      <path
        d="m497.79,182.39c1.8,1.7,2.7,3.9,2.7,6.5s-.9,4.7-2.7,6.4c-1.8,1.7-4,2.5-6.7,2.5h-49.7c-2.7,0-4.9-.9-6.7-2.7-1.8-1.8-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.8-6.7,1.8-1.8,4.2-2.7,7-2.7,2.5,0,4.7.9,6.6,2.7,1.8,1.8,2.8,4,2.8,6.7v79.9h39.9c2.7.1,4.9.9,6.7,2.6h0Z"
        fill="#35393d"
      />
      <path
        d="m580.69,195.09c-1.9,1.8-4.2,2.7-6.7,2.7-2.8,0-5-.9-6.8-2.7-1.8-1.8-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.8-6.7,1.8-1.8,4.2-2.7,7-2.7,2.5,0,4.7.9,6.6,2.7,1.8,1.8,2.8,4,2.8,6.7v88.5c-.1,2.7-1.1,5-3,6.7h0Z"
        fill="#35393d"
      />
      <path
        d="m671.99,108.29v26.9h33.1c2.7,0,4.9.8,6.7,2.5s2.7,3.9,2.7,6.5c0,2.2-.9,4.2-2.7,6-1.8,1.7-4,2.6-6.7,2.6h-33.1v35.7c0,2.7-.9,4.9-2.8,6.7-1.9,1.8-4.1,2.7-6.7,2.7-2.9,0-5.2-.9-7-2.6s-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.7-6.7s4-2.7,6.7-2.7h49.7c2.7,0,4.9.8,6.7,2.5s2.7,3.9,2.7,6.5c0,2.2-.9,4.2-2.7,6-1.8,1.7-4,2.6-6.7,2.6h-39.9Z"
        fill="#35393d"
      />
      <path
        d="m852.49,182.89c1.8,1.8,2.7,3.9,2.7,6.2,0,2.6-.9,4.6-2.7,6.3-1.8,1.6-4,2.4-6.7,2.4h-51.3c-2.7,0-4.9-.9-6.7-2.7-1.8-1.8-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.7-6.7s4-2.7,6.7-2.7h51.3c2.7,0,4.9.8,6.7,2.5,1.8,1.7,2.7,3.9,2.7,6.5s-.9,4.6-2.6,6.2-4,2.4-6.7,2.4h-41.5v26h34.6c2.7,0,4.9.8,6.7,2.5,1.8,1.7,2.7,3.9,2.7,6.5s-.9,4.6-2.6,6.2-4,2.4-6.7,2.4h-34.6v28.3h41.5c2.5.2,4.8,1.1,6.5,2.9h0Z"
        fill="#35393d"
      />
      <path
        d="m1010.99,171.99c-4.5,8.4-10.7,15.1-18.6,20s-16.8,7.3-26.7,7.3-18.8-2.4-26.7-7.3c-7.9-4.9-14.1-11.6-18.5-20s-6.7-17.7-6.7-27.8,2.2-19.4,6.7-27.8c4.4-8.4,10.6-15.1,18.5-20s16.8-7.3,26.7-7.3,18.8,2.4,26.7,7.3c7.9,4.9,14.1,11.6,18.6,20s6.7,17.7,6.7,27.8-2.2,19.4-6.7,27.8h0Zm-17.3-46.4c-2.8-5.6-6.6-10-11.5-13.2-4.9-3.3-10.4-4.9-16.5-4.9s-11.8,1.6-16.6,4.8c-4.8,3.2-8.6,7.6-11.3,13.2-2.7,5.6-4.1,11.8-4.1,18.7s1.4,13.1,4.1,18.7,6.5,10,11.3,13.2,10.4,4.8,16.6,4.8,11.6-1.6,16.5-4.9,8.7-7.7,11.5-13.2c2.8-5.6,4.1-11.8,4.1-18.6s-1.3-13-4.1-18.6h0Z"
        fill="#35393d"
      />
      <path
        d="m1176.19,93.19c1.9,1.8,2.8,4,2.8,6.7v88.6c0,2.7-.9,4.9-2.6,6.7-1.7,1.8-4,2.7-6.9,2.7s-5.2-.9-7-2.6-2.7-4-2.7-6.7v-61.7l-25.3,38.6c-1,1.2-2.2,2.2-3.5,3s-2.7,1.1-4.1,1c-1.3.1-2.7-.2-4-1s-2.5-1.8-3.5-3l-25.3-39.9v63.1c0,2.8-.8,5-2.4,6.7s-3.8,2.6-6.4,2.6-4.6-.9-6.3-2.7c-1.6-1.8-2.4-4-2.4-6.7v-88.6c0-2.5.9-4.7,2.8-6.6,1.9-1.8,4.2-2.8,6.8-2.8,1.5,0,3.1.4,4.6,1.1,1.5.8,2.7,1.8,3.5,3l33.4,51.3,34.3-51.3c.8-1.2,1.9-2.2,3.3-3s2.8-1.1,4.2-1.1c2.6-.1,4.9.8,6.7,2.6h0Z"
        fill="#35393d"
      />
      <path
        d="m1264.59,195.09c-1.9,1.8-4.2,2.7-6.7,2.7-2.8,0-5-.9-6.8-2.7-1.8-1.8-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.8-6.7,1.8-1.8,4.2-2.7,7-2.7,2.5,0,4.7.9,6.6,2.7,1.8,1.8,2.8,4,2.8,6.7v88.5c-.1,2.7-1,5-3,6.7h0Z"
        fill="#35393d"
      />
      <path
        d="m1411.59,103.69c0,2.1-.8,4.2-2.3,6.3-1.6,2.1-3.7,3.2-6.3,3.2-1.7,0-3.5-.5-5.2-1.4-5-2.3-10.4-3.5-16.2-3.5-7.1,0-13.4,1.5-18.7,4.5-5.3,3-9.4,7.2-12.2,12.7-2.9,5.5-4.3,11.7-4.3,18.7,0,12,3.2,21.2,9.7,27.4,6.5,6.2,15,9.3,25.5,9.3,6.2,0,11.6-1.2,16.2-3.5,1.8-.8,3.5-1.2,4.9-1.2,2.7,0,4.9,1.1,6.7,3.4,1.5,1.9,2.3,4,2.3,6.3,0,1.6-.4,3.1-1.2,4.4-.8,1.3-2,2.3-3.5,3.1-8,4-16.4,6-25.4,6-10,0-19.2-2.1-27.5-6.4-8.4-4.2-15.1-10.5-20.1-18.7-5-8.3-7.5-18.3-7.5-30,0-10.4,2.4-19.7,7.1-28s11.3-14.7,19.7-19.4c8.4-4.6,17.8-7,28.3-7,9.1,0,17.5,2,25.3,6,3.1,1.6,4.7,4.2,4.7,7.8h0Z"
        fill="#35393d"
      />
      <path
        d="m1562.79,90.39c5.3,0,10.3,1.5,14.9,4.6s8.4,7.2,11.2,12.4c2.9,5.2,4.3,11,4.3,17.4s-1.4,12.1-4.3,17.3c-2.9,5.2-6.6,9.4-11.2,12.5s-9.6,4.7-14.9,4.7h-28.2l1.5-2.8v31.7c0,2.7-.8,4.9-2.4,6.7-1.6,1.8-3.8,2.7-6.4,2.7s-4.6-.9-6.3-2.7c-1.6-1.8-2.4-4-2.4-6.7v-88.5c0-2.7.9-4.9,2.7-6.7,1.8-1.8,4-2.7,6.7-2.7h34.8v.1h0Zm0,51.5c1.9,0,3.8-.8,5.7-2.4,1.8-1.6,3.4-3.8,4.6-6.4s1.8-5.3,1.8-8.2-.6-5.7-1.8-8.3c-1.2-2.5-2.8-4.6-4.6-6.2-1.8-1.6-3.7-2.4-5.7-2.4h-28.5l1.8-2.8v39l-1.7-2.4h28.4v.1h0Z"
        fill="#929da9"
      />
      <path
        d="m1717.19,179.69c2.7,0,4.9.8,6.7,2.5,1.8,1.7,2.7,3.9,2.7,6.5s-.9,4.7-2.7,6.4c-1.8,1.7-4,2.5-6.7,2.5h-49.7c-2.7,0-4.9-.9-6.7-2.7-1.8-1.8-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.8-6.7,1.8-1.8,4.2-2.7,7-2.7,2.4,0,4.6.9,6.5,2.7,1.9,1.8,2.8,4,2.8,6.7v82.5l-3.4-2.6h43.4v.1h0Z"
        fill="#929da9"
      />
      <path
        d="m1840.09,114.49l-33.1,77.8c-.7,1.9-1.8,3.4-3.3,4.5s-3.1,1.6-4.8,1.6c-2.8,0-4.8-.8-6.1-2.3s-2-3.4-2-5.7c0-.9.2-1.9.5-2.9l38.1-91.5c.8-2,2.1-3.6,3.7-4.7,1.7-1.1,3.5-1.6,5.4-1.4,1.8,0,3.5.5,5.1,1.6s2.7,2.6,3.4,4.5l37.7,89.7c.5,1.3.8,2.6.8,3.7,0,2.8-.9,5-2.7,6.6s-3.7,2.4-5.9,2.4c-1.8,0-3.5-.6-5-1.7s-2.6-2.7-3.4-4.6l-32.9-76.7,4.5-.9h0Zm-28.3,59l8.4-17.6h41.3l2.9,17.6h-52.6,0Z"
        fill="#929da9"
      />
      <path
        d="m1945.39,107.99c-2.7,0-4.9-.8-6.7-2.4s-2.7-3.8-2.7-6.4.9-4.8,2.7-6.4,4-2.4,6.7-2.4h68c2.7,0,4.9.8,6.7,2.4s2.7,3.8,2.7,6.4-.9,4.8-2.7,6.4-4,2.4-6.7,2.4h-68Zm33.8,89.6c-2.9,0-5.2-.9-7-2.7-1.8-1.8-2.8-4-2.8-6.7v-88.8h19.9v88.8c0,2.7-.9,4.9-2.8,6.7-1.9,1.8-4.3,2.7-7.3,2.7h0Z"
        fill="#929da9"
      />
      <path
        d="m2108.09,197.59c-2.9,0-5.2-.9-7-2.7-1.8-1.8-2.7-4-2.7-6.7v-88.5c0-2.7.9-4.9,2.7-6.7,1.8-1.8,4-2.7,6.7-2.7h49.7c2.7,0,4.9.8,6.7,2.5s2.7,3.9,2.7,6.5c0,2.2-.9,4.2-2.7,6-1.8,1.7-4,2.6-6.7,2.6h-41.3l1.4-1.8v30.8l-1.1-2h34.1c2.7,0,4.9.8,6.7,2.5s2.7,3.9,2.7,6.5c0,2.2-.9,4.2-2.7,6-1.8,1.7-4,2.6-6.7,2.6h-34.4l1.4-1.4v37c0,2.7-.9,4.9-2.8,6.7-1.9,1.9-4.1,2.8-6.7,2.8h0Z"
        fill="#929da9"
      />
      <path
        d="m2333.69,143.99c0,7.7-1.3,14.8-3.8,21.4-2.6,6.6-6.1,12.5-10.8,17.5-4.6,5.1-10.2,9-16.5,11.9-6.4,2.9-13.3,4.3-20.9,4.3s-14.5-1.4-20.8-4.3-11.8-6.8-16.5-11.9c-4.6-5.1-8.2-10.9-10.8-17.5s-3.8-13.8-3.8-21.4,1.3-14.8,3.8-21.4,6.1-12.5,10.8-17.5c4.6-5.1,10.1-9,16.5-11.9,6.3-2.9,13.3-4.3,20.8-4.3s14.5,1.4,20.9,4.3,11.9,6.8,16.5,11.9,8.2,10.9,10.8,17.5c2.5,6.6,3.8,13.7,3.8,21.4Zm-19.9,0c0-6.8-1.4-13-4.1-18.6-2.8-5.6-6.5-10-11.3-13.2-4.8-3.3-10.4-4.9-16.7-4.9s-11.9,1.6-16.7,4.9-8.5,7.7-11.2,13.2-4.1,11.7-4.1,18.7,1.4,13,4.1,18.6c2.7,5.6,6.5,10,11.2,13.2,4.8,3.3,10.4,4.9,16.7,4.9s11.9-1.6,16.7-4.9,8.6-7.7,11.3-13.2c2.7-5.7,4.1-11.9,4.1-18.7Z"
        fill="#929da9"
      />
      <path
        d="m2408.19,197.59c-2.9,0-5.4-.9-7.7-2.7-2.2-1.8-3.4-4-3.4-6.7v-88.5c0-2.7.9-4.9,2.7-6.7,1.8-1.8,4-2.7,6.7-2.7h44.1c5.3,0,10.3,1.4,14.9,4.2,4.6,2.8,8.4,6.7,11.2,11.6,2.9,5,4.3,10.6,4.3,17.1,0,3.9-.8,7.7-2.4,11.3-1.6,3.7-3.7,7-6.4,9.9-2.7,3-5.7,5.3-9.1,6.9l.2-3.5c2.9,1.5,5.2,3.3,7.1,5.4,1.9,2.1,3.4,4.3,4.4,6.7,1.1,2.4,1.7,5,1.9,7.7.4,2.6.7,4.8.8,6.7.2,1.9.5,3.6.9,4.9.5,1.3,1.4,2.3,2.7,3.1,2.2,1.3,3.6,3.2,4.2,5.7.6,2.5,0,4.7-1.8,6.7-1.1,1.3-2.6,2.2-4.3,2.5-1.7.4-3.5.4-5.2,0s-3.2-.8-4.3-1.5c-1.7-1-3.5-2.5-5.2-4.5s-3.1-4.7-4.2-8c-1.1-3.4-1.6-7.7-1.6-13,0-1.7-.3-3.3-.9-4.8-.6-1.5-1.5-2.8-2.6-3.8-1.1-1.1-2.6-1.9-4.3-2.4-1.7-.6-3.8-.8-6.1-.8h-30.9l2.3-3.5v33.2c0,2.7-.7,4.9-2.1,6.7-1.3,1.9-3.3,2.8-5.9,2.8h0Zm6-54.8h35.7c1.8,0,3.6-.8,5.4-2.5,1.7-1.7,3.2-4,4.3-6.9s1.7-6.1,1.7-9.6c0-4.5-1.2-8.2-3.6-11.2-2.4-3-5-4.5-7.7-4.5h-35.5l1.8-4.9v43.3l-2.1-3.7h0Z"
        fill="#929da9"
      />
      <path
        d="m2563.99,90.29c1.4,0,2.9.4,4.5,1.1,1.6.8,2.8,1.8,3.6,3l37.2,57.2-7.7-.3,38.1-56.9c1.9-2.8,4.4-4.1,7.5-4.1,2.4,0,4.6.9,6.6,2.6,1.9,1.7,2.9,4,2.9,6.7v88.6c0,2.7-.9,4.9-2.6,6.7-1.7,1.8-4,2.7-6.9,2.7s-5.2-.9-7-2.7c-1.8-1.8-2.7-4-2.7-6.7v-72.1l6,1.4-31.2,47.6c-.9,1.1-2.1,2.1-3.4,2.9-1.4.8-2.8,1.2-4.2,1.1-1.3.1-2.7-.3-4.1-1.1-1.4-.8-2.5-1.8-3.4-2.9l-29.2-46.2,4-8.1v77.4c0,2.7-.8,4.9-2.4,6.7-1.6,1.8-3.8,2.7-6.4,2.7s-4.6-.9-6.3-2.7c-1.6-1.8-2.4-4-2.4-6.7v-88.6c0-2.5.9-4.7,2.8-6.6,1.8-1.8,4.1-2.7,6.7-2.7h0Z"
        fill="#929da9"
      />
    </svg>
  );
};

export default LOPlatformColor;
