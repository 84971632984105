import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { loginSelectors } from '../../redux/modules/Login';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { getTestProps } from '../../util/testProps';
import { ButtonLink } from '@lifeomic/chroma-react/components/ButtonLink';

export const testIds = {
  signupButton: 'customSSOSignup-button',
};

const messages = {
  label: {
    id: 'customSSOSignup.label',
    defaultMessage: 'Sign up with your organization’s single sign on provider',
  },
  signupButton: {
    id: 'customSSOSignup.signupButton',
    defaultMessage: 'Sign up',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  signupButton: {
    marginTop: theme.spacing(4),
    height: 40,
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    textAlign: 'center',
    color: theme.palette.black[500],
    marginBottom: theme.spacing(8),
  },
}));

export type CustomSSOSignupClasses = GetClasses<typeof useStyles>;

export type CustomSSOSignupOwnProps = {};

export type CustomSSOSignupProps = CustomSSOSignupOwnProps;

const CustomSSOSignup = (props: CustomSSOSignupProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);

  const cognitoSignupUrl = useSelector(loginSelectors.selectCognitoSignupUrl);
  const absoluteSignupUrl = `${window.location.origin}${cognitoSignupUrl}`;

  return (
    <div>
      <Text className={classes.label}>{formatMessage(messages.label)}</Text>
      <ButtonLink
        to={absoluteSignupUrl}
        {...getTestProps(testIds.signupButton)}
        className={classes.signupButton}
        variant="contained"
      >
        {formatMessage(messages.signupButton)}
      </ButtonLink>
    </div>
  );
};

export default React.memo(CustomSSOSignup);
