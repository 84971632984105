import queryString from 'query-string';

const generateReturnToLoginLink = ({ subdomain }: { subdomain: string }) => {
  // We will strip out the subdomain from any and all parts of the URL
  const searchParams = queryString.parse(window.location.search);

  const modifiedSearchParams: Record<string, string> = {};

  for (const param in searchParams) {
    if (typeof searchParams[param] !== 'string') {
      return;
    }

    const value = searchParams[param] as string;
    modifiedSearchParams[param] = value?.replace(
      `https://${subdomain}.`,
      'https://'
    );
  }

  return `${window.location.origin?.replace(
    new RegExp(`https://${subdomain}.`, 'g'),
    'https://'
  )}${window.location.pathname}?${queryString.stringify(modifiedSearchParams)}`;
};

export default generateReturnToLoginLink;
