/**
 * Polyfill Event if it's not available.
 */
import isPowerChart from './util/isPowerChart';

(function () {
  if (
    typeof window.CustomEvent === 'function' &&
    typeof window.Event === 'function'
  ) {
    return false;
  }

  console.log(`Event will be polyfilled...`);
  function CustomEvent(event: any, params: any) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  // @ts-ignore
  window.CustomEvent = CustomEvent;
  // @ts-ignore
  window.Event = CustomEvent;
})();

/**
 * Polyfill Proxy.
 */
import 'proxy-polyfill';

/**
 * Pollyfill Intl.
 */
import IntlPolyfill from 'intl';

(function () {
  try {
    if (isPowerChart()) {
      require('intl');
      require('intl/locale-data/jsonp/en-US.js');
      require('intl/locale-data/jsonp/en.js');

      window.Intl = IntlPolyfill;
      // @ts-ignore
      IntlPolyfill.__applyLocaleSensitivePrototypes();
      console.log('Intl polyfilled!');
    }
  } catch (error) {
    window.Intl = IntlPolyfill;
    // @ts-ignore
    IntlPolyfill.__applyLocaleSensitivePrototypes();
    console.log('Intl polyfilled (caught error)!');
  }
})();
