import * as React from 'react';

const SvgBookOpen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgBookOpen"
    {...props}
  >
    <path
      d="M12 17.95V7.65m0 0a6.307 6.307 0 00-2.075-1.263A7.251 7.251 0 007.4 5.95a7.064 7.064 0 00-2.425.425A6.008 6.008 0 003 7.55v10.4a6.008 6.008 0 011.975-1.175A7.064 7.064 0 017.4 16.35a6.978 6.978 0 012.525.475A7.457 7.457 0 0112 18.05m0-10.4a6.307 6.307 0 012.075-1.263A7.251 7.251 0 0116.6 5.95a7.064 7.064 0 012.425.425A6.008 6.008 0 0121 7.55v10.4a6.008 6.008 0 00-1.975-1.175 7.064 7.064 0 00-2.425-.425 6.978 6.978 0 00-2.525.475A7.457 7.457 0 0012 18.05"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgBookOpen;
