import {
  Color,
  SimplePaletteColorOptions,
} from '@lifeomic/chroma-react/esm/colors/types';

/*
  Lifeology - Green Scale
*/

const shades: Color | any = {
  50: '#edfde8',
  100: '#d2f8c7',
  200: '#b2f3a1',
  300: '#8eed78',
  400: '#6de854',
  500: '#46e22c',
  600: '#30d123',
  700: '#00bc16',
  800: '#00a705',
  900: '#008300'
};

const lifeologyGreen: SimplePaletteColorOptions & Color = {
  ...shades,
  main: '#46e22c',
  light: '#b2f3a1',
  dark: '#008300',
  contrastText: '#008300'
};

export default lifeologyGreen;
