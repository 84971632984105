import * as React from 'react';
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IProps {
  className: string;
  bg?: string;
  fg?: string;
  fgA?: string;
  fgB?: string;
  fgC?: string;
  fgD?: string;
}

export const ConfettiBackground: FC<IProps> = ({
  className = 'bg--confetti',
  bg = '#6AE0E4',
  fg = 'white',
  fgA = '#F5F8FA',
  fgB = '#44C2C7',
  fgC = '#34989B',
  fgD = '#262C32',
}) => {
  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 2880 1620"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMax slice"
    >
      <rect x="0" y="0" width="2880" height="1620" fill={bg} />
      <path
        d="M783.355,1189.216a3,3,0,0,0,4.938,1.616l22.743-20.388a3,3,0,0,0-1.069-5.085l-29.029-9.5a3,3,0,0,0-3.869,3.469Z"
        fill={fg}
      />
      <path
        d="M755.359,555.094a3,3,0,0,0,3.945-3.382l-4.538-24.225a3,3,0,0,0-4.9-1.725L731.154,541.8a3,3,0,0,0,.957,5.107Z"
        fill={fgA}
      />
      <rect
        x="1070.96"
        y="476.097"
        width="11.817"
        height="36.929"
        rx="2"
        transform="translate(-106.704 436.313) rotate(-21.85)"
        fill={fgB}
        opacity="0.1"
      />
      <rect
        x="927.293"
        y="889.284"
        width="26.625"
        height="36.929"
        rx="2"
        transform="translate(-364.053 826.426) rotate(-40.415)"
        fill={fgD}
        opacity="0.1"
      />
      <circle cx="844.265" cy="357" r="7" fill={fgD} />
      <circle cx="188.951" cy="540.152" r="12.556" fill={fgD} />
      <circle cx="1160.327" cy="745.679" r="8.124" fill={fgC} opacity="0.6" />
      <path
        d="M1835.89,362.438a3,3,0,0,1,4.94-1.617l22.74,20.389a3,3,0,0,1-1.07,5.085l-29.02,9.5a3,3,0,0,1-3.87-3.468Z"
        fill="#e0f4f2"
      />
      <path
        d="M1837.36,1003.344a3,3,0,0,1,3.94,3.382l-4.53,24.226a3.007,3.007,0,0,1-4.91,1.725l-18.71-16.043a3,3,0,0,1,.96-5.107Z"
        fill={fgA}
      />
      <path
        d="M1566.33,1060.82a3,3,0,0,1,4.11-3.17l26.74,10.96a2.994,2.994,0,0,1,.7,5.14l-22.86,17.68a3,3,0,0,1-4.81-1.97Z"
        fill="#e0f4f2"
      />
      <rect
        x="1901.264"
        y="585.838"
        width="11.817"
        height="36.929"
        rx="2"
        transform="translate(-84.264 795.793) rotate(-23.074)"
        fill={fgD}
        opacity="0.1"
      />
      <rect
        x="1774.154"
        y="1262.067"
        width="17.755"
        height="24.627"
        rx="2"
        transform="translate(-402.165 1442.196) rotate(-39.946)"
        fill={fgB}
        opacity="0.1"
      />
      <circle cx="1937.76" cy="1201.5" r="6.5" fill={fgD} />
      <circle cx="2110.86" cy="309.048" r="12.556" fill={fgD} />
      <path
        d="M404.868,1534.673a3,3,0,0,0,3.927-3.4l-5.775-29.993a3,3,0,0,0-4.91-1.7l-23.087,20a3,3,0,0,0,.983,5.1Z"
        fill={fg}
      />
      <path
        d="M2337.759,959.434a3,3,0,0,0-.869-5.123l-23.106-8.577a3,3,0,0,0-4,3.315l4.125,24.3a3,3,0,0,0,4.871,1.808Z"
        fill={fgA}
      />
      <rect
        x="2445.422"
        y="634.158"
        width="11.817"
        height="36.929"
        rx="2"
        transform="translate(1418.194 2969.423) rotate(-80.875)"
        fill={fgB}
        opacity="0.1"
      />
      <rect
        x="226.009"
        y="1241.673"
        width="36.929"
        height="26.625"
        rx="2"
        transform="translate(-202.514 57.088) rotate(-9.439)"
        fill={fgD}
        opacity="0.1"
      />
      <circle cx="582.815" cy="1355.149" r="12.556" fill={fgD} />
      <circle cx="218.601" cy="983.186" r="8.124" fill={fgC} opacity="0.6" />
      <path
        d="M2289.775,1522.1a3,3,0,0,1-.532-5.17l24.747-17.9a3,3,0,0,1,4.743,2.124l3.13,30.375a3,3,0,0,1-4.21,3.046Z"
        fill="#e0f4f2"
      />
      <rect
        x="2522.791"
        y="1497.822"
        width="36.929"
        height="11.817"
        rx="2"
        transform="translate(-237.274 504.25) rotate(-10.83)"
        fill={fgD}
        opacity="0.1"
      />
      <circle
        cx="2295.915"
        cy="1242.059"
        r="12.556"
        transform="translate(595.195 3222.334) rotate(-77.756)"
        fill={fgC}
        opacity="0.6"
      />
      <circle cx="2688.931" cy="730.555" r="7" fill={fgD} />
      <path
        d="M967.777,133.846a3,3,0,0,0,.557,5.166l27.935,12.35a3,3,0,0,0,4.2-3.065l-3.272-30.368a3,3,0,0,0-4.753-2.1Z"
        fill={fg}
      />
      <rect
        x="527.101"
        y="554.211"
        width="36.929"
        height="26.625"
        rx="2"
        transform="translate(-200.721 818.088) rotate(-64.69)"
        fill={fgD}
        opacity="0.1"
      />
      <rect
        x="1373.554"
        y="880.354"
        width="36.929"
        height="11.817"
        rx="2"
        transform="translate(-245.579 682.666) rotate(-25.397)"
        fill={fgB}
        opacity="0.1"
      />
      <path
        d="M2206.993,188.355a3,3,0,0,0-.254,5.19l25.673,16.547a3,3,0,0,0,4.622-2.375l1.494-30.508a3,3,0,0,0-4.368-2.815Z"
        fill={fg}
      />
      <path
        d="M231.23,316.49a3,3,0,0,0,4.3,2.911L257.7,308.623a3,3,0,0,0,.368-5.183l-20.416-13.807a3,3,0,0,0-4.673,2.273Z"
        fill={fgA}
      />
      <rect
        x="2501.544"
        y="252.327"
        width="36.929"
        height="26.625"
        rx="2"
        transform="translate(881.722 2198.778) rotate(-55.737)"
        fill={fgD}
        opacity="0.1"
      />
      <circle cx="2734.378" cy="434.724" r="8.124" fill={fgC} opacity="0.6" />
      <rect
        x="150.492"
        y="98.973"
        width="26.625"
        height="36.929"
        rx="2"
        transform="translate(19.735 258.39) rotate(-80.012)"
        fill={fgD}
        opacity="0.1"
      />
    </svg>
  );
};
