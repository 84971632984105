import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

type formatMessageSignature = (
  descriptor: MessageDescriptor,
  values?: any
) => string;

const testRegex = (value: string, regex: RegExp) => {
  if (!value) return false;

  return regex.test(value);
};

export const trimWhitespaceFromForm = (formValues: any) => {
  const trimmedValues = { ...formValues };
  for (const [key, value] of Object.entries(formValues)) {
    trimmedValues[key as keyof typeof formValues] = String(value).trim();
  }
  return trimmedValues;
};

const messages = {
  required: {
    id: 'validationFailure.required',
    defaultMessage: 'Field is required',
  },
  invalidPasswordFormat: {
    id: 'validationFailure.required',
    defaultMessage: 'Invalid password format',
  },
  invalidEmail: {
    id: 'validationFailure.invalidEmail',
    defaultMessage: 'Invalid email',
  },
  noUppercase: {
    id: 'validationFailure.noUppercase',
    defaultMessage: 'Uppercase letters are not allowed',
  },
  usernameEmail: {
    id: 'validationFailure.usernameEmail',
    defaultMessage: 'Username cannot be an email',
  },
};

const expressions = {
  lowerCase: /[a-z]/,
  upperCase: /[A-Z]/,
  // one of the following: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~
  specialCharacter:
    /[(^|$|*|.|[|\]|{|}|(|)|?|\-|"|!|@|#|%|&|/|\\|,|>|<|'|:|;|||_|~)]/,
  number: /\d/,
  min8Chars: /^.{8,}$/,
  email:
    /^[-a-zA-Z0-9@:%.+~#=_]{2,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%+.~#?&//=_]*)$/,
  anyUppercase: /[A-Z]/g,
};

export const validationChecks = {
  required: (value: any) => {
    const emptyArray = isArray(value) && value.length === 0;
    const emptyValue = isNil(value);
    const emptyString = isString(value) && isEmpty(value);
    return emptyArray || emptyValue || emptyString;
  },
  oneLowerCaseLetter: (value: string) =>
    testRegex(value, expressions.lowerCase),
  oneUpperCaseLetter: (value: string) =>
    testRegex(value, expressions.upperCase),
  oneSpecialCharacter: (value: string) =>
    testRegex(value, expressions.specialCharacter),
  oneNumber: (value: string) => testRegex(value, expressions.number),
  min8Chars: (value: string) => testRegex(value, expressions.min8Chars),
  email: (value: string) => testRegex(value, expressions.email),
  noUppercase: (value: string) => !testRegex(value, expressions.anyUppercase),
};

const getValidators = (formatMessage: formatMessageSignature) => ({
  required: (value: any) =>
    validationChecks.required(value)
      ? formatMessage(messages.required)
      : undefined,
  password: (value: any) => {
    if (validationChecks.required(value)) {
      return formatMessage(messages.required);
    } else if (
      !validationChecks.oneLowerCaseLetter(value) ||
      !validationChecks.oneUpperCaseLetter(value) ||
      !validationChecks.oneSpecialCharacter(value) ||
      !validationChecks.oneNumber(value) ||
      !validationChecks.min8Chars(value)
    ) {
      return formatMessage(messages.invalidPasswordFormat);
    }
  },
  email: (value: any) => {
    if (validationChecks.required(value)) {
      return formatMessage(messages.required);
    } else if (!validationChecks.email(value)) {
      return formatMessage(messages.invalidEmail);
    }
  },
  signupEmail: (value: any) => {
    if (validationChecks.required(value)) {
      return formatMessage(messages.required);
    } else if (!validationChecks.email(value)) {
      return formatMessage(messages.invalidEmail);
    } else if (!validationChecks.noUppercase(value)) {
      return formatMessage(messages.noUppercase);
    }
  },
  signupUsername: (value: any) => {
    if (validationChecks.required(value)) {
      return formatMessage(messages.required);
    } else if (!validationChecks.noUppercase(value)) {
      return formatMessage(messages.noUppercase);
    } else if (validationChecks.email(value)) {
      return formatMessage(messages.usernameEmail);
    }
  },
  signupName: (value: any) => {
    if (validationChecks.required(value)) {
      return formatMessage(messages.required);
    }
  },
});

function useValidators() {
  const formatMessage = useIntl().formatMessage;
  const validators = React.useMemo(
    () => getValidators(formatMessage),
    [formatMessage]
  );
  return validators;
}

export default useValidators;
