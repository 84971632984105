import useAPI, { RequestConfig } from './useApi';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import urls from '../../util/urls';
import { useIntl } from 'react-intl';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthResponse } from '../../util/handleAuthResponse';
import config from '../../util/config';
import { loginSelectors } from '../../redux/modules/Login';

const messages = {
  apiErrorMessage: {
    id: 'resetPasswordErrorMessage.apiErrorMessage',
    defaultMessage:
      'An error occurred when resetting password.  Error: "{error}"',
  },
  genericError: {
    id: 'resetPasswordErrorMessage.genericError',
    defaultMessage: 'An error occurred when resetting password',
  },
};

export type ResetPasswordResponse = AuthResponse & {
  originalUrl: string;
};

function useResetPasswordEndpoint() {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);

  const clientId = customClientId || config.oauthClientId;

  const onFailure = (error: AxiosError) => {
    const errorMessage = get(error, 'response.data.error', null) as string;

    const notificationMessage = errorMessage
      ? formatMessage(messages.apiErrorMessage, { error: errorMessage })
      : formatMessage(messages.genericError);

    dispatch(
      notificationsActions.addNotification(notificationMessage, {
        statusType: 'error',
      })
    );
  };

  const makeAPIRequest = (
    confirmationCode: string,
    password: string,
    username: string,
    onSuccess: (axiosResponse: AxiosResponse<ResetPasswordResponse>) => void
  ): RequestConfig => ({
    url: urls.api.auth.resetPassword,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      confirmationCode,
      password,
      username,
      clientId,
    },
    onSuccess,
    onFailure,
  });

  return useAPI(makeAPIRequest);
}

export default useResetPasswordEndpoint;
