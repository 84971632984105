import { v4 } from 'uuid';

export const localStorageKey = '@@lifeomic/store/auth-stateId';

export function createStateId() {
  const state = v4();
  localStorage.setItem(localStorageKey, state);
  return state;
}

export function getStateParam() {
  return localStorage.getItem(localStorageKey);
}

export function validateState(state: string): boolean {
  const storedValue = localStorage.getItem(localStorageKey);
  return storedValue === state;
}
