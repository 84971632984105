import * as React from 'react';

const SvgRotateCw = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgRotateCw"
    {...props}
  >
    <path
      d="M14.7 7l2.7.55 2.7.55.3-2.55.3-2.55m.3 9.7a9.038 9.038 0 01-9 8.3 9.011 9.011 0 117.5-14"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgRotateCw;
