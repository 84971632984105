import React from 'react';
import { LoginApps } from '../../util/loginApps';
import PhcEntryRoot from './PhcEntryRoot';
import SharedEntryRoot from './SharedEntryRoot';
import useLoginApp from '../../redux/hooks/useLoginApp';

export const appEntryRoots: Record<LoginApps, React.FC<EntryRootProps>> = {
  [LoginApps.phc]: PhcEntryRoot,
  [LoginApps.lifeology]: SharedEntryRoot,
  [LoginApps.marketplace]: SharedEntryRoot,
  [LoginApps.skillspring]: SharedEntryRoot,
};

export interface EntryRootOwnProps {
  isInitializing: boolean;
}

export type EntryRootProps = EntryRootOwnProps;

const EntryRoot = (props: EntryRootProps) => {
  const loginApp = useLoginApp();

  return appEntryRoots[loginApp](props);
};

export default React.memo(EntryRoot);
