import color from 'color';
import { CustomTheme } from '../hooks/useCustomTheme';
import { CSSProperties } from '@mui/styles';

export const customThemeButtonStyles = (
  customTheme: CustomTheme | undefined
): CSSProperties => {
  if (!customTheme) {
    return {};
  }

  return {
    color: customTheme.textOnPrimaryColor,
    backgroundColor: customTheme.primaryColor,
    '&:hover': {
      backgroundColor: color(customTheme.primaryColor).darken(0.2).hex(),
    },
    '&:disabled': {
      backgroundColor: customTheme.disabledColor,
    },
  };
};
