import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { DotLoader } from '@lifeomic/chroma-react/components/DotLoader';
import ContentContainer from './ContentContainer';
import SignInContainer from './SignInContainer';
import SignupContainer from '../common/SignupContainer';
import { Fade } from '@mui/material';
import { EntryRootProps } from '.';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import { SEA_GREEN } from '@lifeomic/phc-web-toolkit/dist/theme/phc/styles/content';

export const useStyles = makeStyles((theme: Theme) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 680,
    minHeight: 370,
  },
  platformDots: {
    width: theme.pxToRem(100),
    '& > circle': {
      '&:nth-child(1)': {
        fill: theme.palette.blue.main,
      },
      '&:nth-child(2)': {
        fill: '#3170a8',
      },
      '&:nth-child(3)': {
        fill: '#437b79',
      },
      '&:nth-child(4)': {
        fill: SEA_GREEN,
      },
    },
  },
  monoChromeDots: {
    fill: theme.palette.primary.main,
  },
}));

export type ResendingStatusMessageRootClasses = GetClasses<typeof useStyles>;

const SharedEntryRoot = (props: EntryRootProps) => {
  const classes = useStyles(props);
  const loginApp = useLoginApp();
  const content = props.isInitializing ? (
    <div className={classes.loaderContainer}>
      <DotLoader
        dotStyle={
          loginApp === LoginApps.skillspring
            ? classes.monoChromeDots
            : classes.platformDots
        }
      />
    </div>
  ) : (
    <>
      <SignInContainer />
      <SignupContainer />
    </>
  );
  return (
    <Fade in>
      <div>
        <ContentContainer>{content}</ContentContainer>
      </div>
    </Fade>
  );
};

export default SharedEntryRoot;
