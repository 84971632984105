import * as React from 'react';

const SvgActivityFolder = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    data-icon="SvgActivityFolder"
    {...props}
  >
    <path d="M22 19a2 2 0 01-2 2H4a2 2 0 01-2-2V5a2 2 0 012-2h5l2 3h9a2 2 0 012 2z" />
    <path d="M15.698 13.5h-1.479l-1.11 3.328-2.218-6.656-1.11 3.328H8.302" />
  </svg>
);

export default SvgActivityFolder;
