import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Paper } from '@lifeomic/chroma-react/components/Paper';
import { AlertCircle } from '@lifeomic/chromicons';
import CollapsableDetails from './CollapsableDetails';
import { getTestProps } from '../../util/testProps';
import { Text } from '@lifeomic/chroma-react/components/Text';

export const testIds = {
  header: 'ErrorDisplay-header',
};

const messages = {
  header: {
    id: 'errorDisplay.header',
    defaultMessage: 'Something went wrong...',
  },
  genericDetails: {
    id: 'errorDisplay.header',
    defaultMessage:
      'Please go back to the original site or click your original link and try again.  If this problem persists, feel free to email support@lifeomic.com',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.pxToRem(600),
    maxWidth: '100%',
    borderRadius: theme.pxToRem(10),
    padding: theme.spacing(2),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  alertIcon: {
    marginRight: theme.spacing(2),
    height: theme.pxToRem(30),
    width: theme.pxToRem(30),
  },
  subText: {
    marginTop: theme.spacing(4),
  },
}));

export type ErrorDisplayClasses = GetClasses<typeof useStyles>;

export type ErrorDisplayOwnProps = {};

export type ErrorDisplayProps = ErrorDisplayOwnProps;

const ErrorDisplay = (props: ErrorDisplayProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);

  return (
    <Paper className={classes.root}>
      <div className={classes.headerContainer}>
        <AlertCircle className={classes.alertIcon} aria-hidden />
        <Text {...getTestProps(testIds.header)} size="headline">
          {formatMessage(messages.header)}
        </Text>
      </div>
      <Text size="body" className={classes.subText}>
        {formatMessage(messages.genericDetails)}
      </Text>
      <CollapsableDetails />
    </Paper>
  );
};

export default React.memo(ErrorDisplay);
