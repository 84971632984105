import * as React from 'react';

const SvgResetDate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    data-icon="SvgResetDate"
    {...props}
  >
    <path d="M8 5.778H3.778A1.778 1.778 0 002 7.556V20a1.778 1.778 0 001.778 1.778h12.444A1.778 1.778 0 0018 20v-3.333M6.444 4v3.556M2 11.111h6m13.851-.652a5.2 5.2 0 11-.335-5.195" />
    <path d="M20.961 1.343l.555 3.921h0l-3.86.475" />
  </svg>
);

export default SvgResetDate;
