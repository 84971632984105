import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import useIsMobile from '../../../hooks/useIsMobile';
import clsx from 'clsx';
import SignInButton from '../SignInButton';
import SharedDeviceToggle from '../../common/SharedDeviceToggle';
import { SignInSSOButton } from '../SignInSSOButton';
import useIsCustomSSOProvider from '../../../hooks/useIsCustomSSOProvider';
import { useSelector } from 'react-redux';
import { loginSelectors } from '../../../redux/modules/Login';
import { SignInContainerProps } from '.';
import skillSpringLogo from '../../../static/images/skillspring-logo-stacked.svg';
import { Text } from '@lifeomic/chroma-react/components/Text';

const messages = defineMessages({
  welcomeBack: {
    id: 'signInContainer.welcomeBack',
    defaultMessage: 'Welcome Back!',
  },
});

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentRoot: {
    flex: 1,
    minHeight: theme.pxToRem(370),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileRootVariant: {
    flex: 'unset',
    height: 'unset',
    width: '100%',
    minHeight: 'unset',
    padding: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  logo: {
    width: theme.pxToRem(195),
    marginBottom: theme.spacing(4),
  },
  mobileLogoVariant: {
    marginBottom: theme.spacing(2),
    width: theme.pxToRem(45),
    height: theme.pxToRem(30),
  },
  welcomeBackText: {
    color: theme.palette.text.hint,
  },
  toggleContainer: {
    width: '100%',
    height: 45,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

export type SignInContainerlasses = GetClasses<typeof useStyles>;

const SkillSpringSignInContainer = (props: SignInContainerProps) => {
  const { formatMessage } = useIntl();
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const isCustomOAuthProvider = useIsCustomSSOProvider();
  const disableSsoSwitching = useSelector(
    loginSelectors.selectDisableSsoSwitching
  );

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.contentRoot,
          isMobile && classes.mobileRootVariant
        )}
      >
        <img src={skillSpringLogo} className={classes.logo} />
        <Text size="headline" className={classes.welcomeBackText}>
          {formatMessage(messages.welcomeBack)}
        </Text>
        <SignInButton />
        {!disableSsoSwitching && !isCustomOAuthProvider && (
          <SignInSSOButton className={classes.marginTop} />
        )}
      </div>
      {!isMobile && (
        <div className={classes.toggleContainer}>
          <SharedDeviceToggle />
        </div>
      )}
    </div>
  );
};

export default SkillSpringSignInContainer;
