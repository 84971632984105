import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { push } from 'connected-react-router';
import urls from '../../util/urls';
import { getTestProps } from '../../util/testProps';

export const testIds = {
  signUpLinkButton: 'singUpLinkButton-button',
};

const messages = {
  label: {
    id: 'SingupLinkButton.label',
    defaultMessage: 'Create an account',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 40,
    width: 250,
    maxWidth: '80%',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export type SingupLinkButtonClasses = GetClasses<typeof useStyles>;

export type SingupLinkButtonOwnProps = {};

export type SingupLinkButtonProps = SingupLinkButtonOwnProps;

const SingupLinkButton = (props: SingupLinkButtonProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const setSignup = useCallback(() => {
    dispatch(push(urls.app.roots.signup));
  }, []);

  return (
    <Button
      {...getTestProps(testIds.signUpLinkButton)}
      className={classes.root}
      onClick={setSignup}
    >
      {formatMessage(messages.label)}
    </Button>
  );
};

export default React.memo(SingupLinkButton);
