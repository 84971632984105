import { LoginApps } from './loginApps';

export type Config = {
  oauthClientId: string;
  appsBaseUri: string;
  oauthBaseUri: string;
  localDevAuthRedirectUrl: string;
  nodeEnv: string;
  disableBugsnagReporting: string;
  loginApp: LoginApps;
  recaptchaKey?: string;
  recaptchaEnabled: boolean;
  awsPartition: 'aws' | 'aws-us-gov';
  loginAppBasePath: string;
  authBasePath: string;
  cookieDomain?: string;
  lifeomicTargetEnvironment: string;
};

const config = ((window as any).__INITIAL_STATE__ || { config: {} })
  .config as Config;

export default config;
