import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Text, TextProps } from '@lifeomic/chroma-react/components/Text';

export const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
}));

export type PromptSubHeaderClasses = GetClasses<typeof useStyles>;

export type PromptSubHeaderOwnProps = {};

export type PromptSubHeaderProps = PromptSubHeaderOwnProps & TextProps;

const PromptSubHeader = (props: PromptSubHeaderProps) => {
  const { children, ...textProps } = props;

  const classes = useStyles(props);

  return (
    <Text className={classes.subHeader} size="body" {...textProps}>
      {children}
    </Text>
  );
};

export default React.memo(PromptSubHeader);
