import axios from 'axios';
export interface FeatureToggles {
  passwordlessAuth: boolean;
}

let featureTogglesFetchPromise: Promise<FeatureToggles> | undefined;

export async function fetchFeatures() {
  if (!featureTogglesFetchPromise) {
    featureTogglesFetchPromise = axios({
      method: 'GET',
      url: '/api/v1/features-public',
    })
      .then((response) => {
        if (response.status !== 200) {
          console.error('Fetch for feature toggles failed');
          return {
            passwordlessAuth: false,
          };
        }
        return response.data as FeatureToggles;
      })
      .catch(() => {
        console.error('Fetch for feature toggles failed');
        return {
          passwordlessAuth: false,
        };
      });
  }
  return featureTogglesFetchPromise;
}
