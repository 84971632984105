export type CustomTheme = {
  primaryColor?: string;
  disabledColor?: string;
  textOnPrimaryColor?: string;
  backgroundImageUrl?: string;
};

export const useCustomTheme = (): CustomTheme | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const themeSearchString = urlParams.get('theme');
  let theme: CustomTheme | undefined;
  try {
    theme = themeSearchString ? JSON.parse(themeSearchString) : undefined;
  } catch (e) {
    console.error('Error parsing theme', e);
  }
  return theme;
};
