import * as React from 'react';

const SvgVideo2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgVideo2"
    {...props}
  >
    <path
      d="M13.85 18.29H5.269A2.269 2.269 0 013 16.02V7.98a2.269 2.269 0 012.269-2.27h8.581a2.269 2.269 0 012.269 2.27v8.04a2.269 2.269 0 01-2.269 2.27zm2.269-4.584l4.881 2V8.294l-4.881 2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgVideo2;
