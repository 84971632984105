import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import AuthPromptPaper from '../common/AuthPromptPaper';
import PromptHeader from '../common/PromptHeader';
import queryString from 'query-string';
import PromptSubHeader from '../common/PromptSubHeader';
import urls from '../../util/urls';
import { withRouter, RouteProps } from 'react-router-dom';
import windowMethods from '../../util/windowMethods';

export const REDIRECT_DELAY = 7000;
export type IDP = 'google' | 'facebook' | 'signinwithapple';

const messages = {
  header: {
    id: 'PendingSocialLogout.header',
    defaultMessage: 'Almost done...',
  },
  subHeader: {
    id: 'PendingSocialLogout.subHeader',
    defaultMessage:
      'You will soon be redirected to {idp} to log out of your {idp} account on this device.',
  },
};

const providerMap = {
  google: 'Google',
  facebook: 'Facebook',
  signinwithapple: 'Apple',
  email: 'Email',
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
}));

export type PendingSocialLogoutClasses = GetClasses<typeof useStyles>;

export type PendingSocialLogoutOwnProps = {};

export type PendingSocialLogoutProps = PendingSocialLogoutOwnProps & RouteProps;

const PendingSocialLogout = (props: PendingSocialLogoutProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);

  const targetSocial: IDP = queryString.parse(props.location.search).idp as IDP;

  React.useEffect(() => {
    window.setTimeout(() => {
      windowMethods.setLocationHref(
        targetSocial === 'google'
          ? urls.external.googleLogout
          : targetSocial === 'facebook'
          ? urls.external.facebookLogout
          : urls.external.appleLogout
      );
    }, REDIRECT_DELAY);
  }, []);

  const subheader = formatMessage(messages.subHeader, {
    idp: providerMap[targetSocial],
  });

  return (
    <AuthPromptPaper className={classes.root}>
      <PromptHeader>{formatMessage(messages.header)}</PromptHeader>
      <PromptSubHeader>{subheader}</PromptSubHeader>
    </AuthPromptPaper>
  );
};

export default React.memo(withRouter(PendingSocialLogout));
