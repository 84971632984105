import React from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { Divider } from '@lifeomic/chroma-react/components/Divider';
import LoginForm from './LoginForm';
import AuthPromptPaper from '../common/AuthPromptPaper';
import ForgotPasswordLink from './ForgotPasswordLink';
import PromptHeader from '../common/PromptHeader';
import SocialLogin from '../common/SocialLogin';
import { useSelector } from 'react-redux';
import { loginSelectors } from '../../redux/modules/Login';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import useIsInGovCloudEnv from '../../hooks/useIsInGovCloudEnv';
import SignInButton from '../Entry/SignInButton';
import { Box } from '@lifeomic/chroma-react/components/Box';

const messages = {
  header: {
    id: 'login.header',
    defaultMessage: 'Welcome Back!',
  },
  signInOption: {
    id: 'login.signInOption',
    defaultMessage: 'or',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  prompt: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  formContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  divider: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  orBox: {
    '& hr': {
      flex: 1,
      margin: 0,
    },
  },
  orSignUpText: {
    color: theme.palette.text.hint,
    padding: theme.spacing(0, 2),
  },
  signInButton: {
    marginBottom: theme.spacing(2),
  },
}));

export type LoginClasses = GetClasses<typeof useStyles>;

export type LoginOwnProps = {};

export type LoginProps = LoginOwnProps;

const Login = (props: LoginProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const loginApp = useLoginApp();
  const isPhcLogin = loginApp === LoginApps.phc;
  const isInGovCloudEnv = useIsInGovCloudEnv();
  const socialLoginDisabled = useSelector(
    loginSelectors.selectSocialLoginDisabled
  );

  const clientDisablesFacebook = useSelector(
    loginSelectors.selectCustomClientDisablesFacebook
  );
  const clientDisablesGoogle = useSelector(
    loginSelectors.selectCustomClientDisablesGoogle
  );
  const clientDisablesApple = useSelector(
    loginSelectors.selectCustomClientDisablesApple
  );
  const clientDisablesLifeomic = useSelector(
    loginSelectors.selectCustomClientDisablesLifeomic
  );
  const clientDisablesCustom = useSelector(
    loginSelectors.selectCustomClientDisablesCustom
  );

  const customClientPreventsSocial =
    clientDisablesFacebook && clientDisablesGoogle && clientDisablesApple;

  const disableFacebook = clientDisablesFacebook;
  const disableGoogle = clientDisablesGoogle;
  const disableApple = !isPhcLogin || clientDisablesApple;

  return (
    <AuthPromptPaper className={clsx(classes.prompt)}>
      <div className={classes.contentContainer}>
        <PromptHeader>{formatMessage(messages.header)}</PromptHeader>
        {!customClientPreventsSocial &&
          !socialLoginDisabled &&
          !isInGovCloudEnv && (
            <>
              <SocialLogin
                disableApple={disableApple}
                disableFacebook={disableFacebook}
                disableGoogle={disableGoogle}
              />
              {!clientDisablesLifeomic && (
                <>
                  <Box className={classes.orBox} align="center" fullWidth>
                    <Divider />
                    <Text size="body" className={classes.orSignUpText}>
                      {formatMessage(messages.signInOption)}
                    </Text>
                    <Divider />
                  </Box>
                </>
              )}
            </>
          )}
        {!clientDisablesLifeomic && (
          <div className={classes.formContainer}>
            <LoginForm />
          </div>
        )}
        {!clientDisablesLifeomic && !clientDisablesCustom && (
          <>
            <Divider className={classes.divider} />
          </>
        )}
        {!clientDisablesCustom && (
          <div className={classes.signInButton}>
            <SignInButton sso={true} />
          </div>
        )}
      </div>
      {!clientDisablesLifeomic && <ForgotPasswordLink />}
    </AuthPromptPaper>
  );
};

export default React.memo(Login);
