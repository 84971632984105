import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import AuthPromptPaper from '../common/AuthPromptPaper';
import PromptHeader from '../common/PromptHeader';
import PromptSubHeader from '../common/PromptSubHeader';
import PasswordlessLoginForm from './PasswordlessLoginForm';

const messages = {
  confirmHeader: {
    id: 'confirmHeader.header',
    defaultMessage: 'Check your email',
  },
  confirmSubHeader: {
    id: 'confirmHeader.subHeader',
    defaultMessage:
      'You will receive an email with a sign-in link and a 6-character code. You can either click the link or enter the code below. These options will expire soon.',
  },
  incorrectCode: {
    id: 'confirmHeader.incorrectChallengeCode',
    defaultMessage: 'The code you entered was not correct. Please try again.',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  authPromptPaper: {
    paddingBottom: theme.spacing(4),
  },
  formContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
}));

const PasswordlessLogin = () => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles({});
  const urlParams = new URLSearchParams(window.location.search);
  const incorrectCode = !!urlParams.get('incorrectCode');

  return (
    <AuthPromptPaper className={classes.authPromptPaper}>
      <PromptHeader>{formatMessage(messages.confirmHeader)}</PromptHeader>
      <PromptSubHeader>
        {formatMessage(
          incorrectCode ? messages.incorrectCode : messages.confirmSubHeader
        )}
      </PromptSubHeader>
      <PasswordlessLoginForm />
    </AuthPromptPaper>
  );
};

export default React.memo(PasswordlessLogin);
