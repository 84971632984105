import * as React from 'react';

const SvgRefreshCcw = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgRefreshCcw"
    {...props}
  >
    <path
      d="M8.4 10.044H3v-5.4M18.973 9.07A7.557 7.557 0 007.439 5.876 35.482 35.482 0 003 10.044m18 9.282v-5.9h-5.3M5.306 14.488a7.472 7.472 0 006.876 5.148 6.913 6.913 0 004.457-1.654A36.616 36.616 0 0021 13.423"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgRefreshCcw;
