import * as React from 'react';

const SvgCornerRightDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCornerRightDown"
    {...props}
  >
    <path
      d="M3 3h7.105a5 5 0 015 5v13M21 15.105L15.105 21M9.21 15.105l5.9 5.9"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCornerRightDown;
