import * as React from 'react';

const SvgSitting = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgSitting"
    {...props}
  >
    <path
      d="M11 3a1.792 1.792 0 11-1.268.525A1.792 1.792 0 0111 3zm2.622 8.693a2.223 2.223 0 00-2.193-1.862h0A2.1 2.1 0 009.2 12.053l.752 3.109h7.21l2.328 4.727M4.506 8.045c.245.98-.385-1.744 2.515 10.646h8.11M11.44 21v-2.309"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgSitting;
