import { IDP } from '../components/PendingSocialLogout';

export const localStorageKey = '@@lifeomic/store/social-idp';

export function setSocialIdp(idp: IDP) {
  window.localStorage.setItem(localStorageKey, idp);
}

export function getSocialIdp(): IDP {
  return window.localStorage.getItem(localStorageKey) as IDP;
}
