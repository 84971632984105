import * as React from 'react';

const SvgApple = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgApple"
    {...props}
  >
    <path
      d="M19.237 15.653a6.887 6.887 0 01-2.055 3.847 5.331 5.331 0 01-3.6 1.5h-.208c-.069 0-.137-.007-.205-.013a13.067 13.067 0 00-2.34 0c-.068.006-.137.01-.205.013s-.138 0-.208 0a5.331 5.331 0 01-3.6-1.5A7.033 7.033 0 015.6 10.9a5.1 5.1 0 013.674-2.53 4.568 4.568 0 01.925-.033 4.766 4.766 0 01.892.142 3.681 3.681 0 001.818 0 4.766 4.766 0 01.892-.142 4.568 4.568 0 01.925.033A5.1 5.1 0 0118.4 10.9a6.945 6.945 0 01.837 4.753zM12.871 8.21a4.072 4.072 0 003.854-.966 3.454 3.454 0 001.074-2.7 3.519 3.519 0 00-1.061-.669 3.389 3.389 0 00-1.274-.247A3.471 3.471 0 0012 6.983m-.058 1.439A5.911 5.911 0 009.8 3"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgApple;
