import parseQueryParams, {
  ParsedParams,
  IDP,
  fullyDecodeURIComponent,
} from './parseQueryParams';
import { window } from './globals';
import {
  clearAuthStorage,
  isLocalStorageEnabled,
} from '@lifeomic/phc-web-toolkit/dist/util/authStorage';
import axios, { AxiosResponse } from 'axios';
import urls from './urls';
import handleAuthResponse, {
  TokenAuthResponse,
  tokenResponseToAuthResponse,
} from './handleAuthResponse';
import { getIsSharedDevice } from './sharedDevice';
import windowMethods from './windowMethods';
import config from './config';
import { LoginApps } from './loginApps';
import { createStateId, validateState } from './csrfToken';
import { getSocialIdp } from './socialLoginIdp';

export type ClientIdentityProviders =
  | 'LIFEOMIC'
  | 'CUSTOM'
  | 'GOOGLE'
  | 'FACEBOOK'
  | 'SIGNINWITHAPPLE';

interface ParsedQueryParams {
  redirectDomain: string;
  subDomain: string;
  app: string;
  destination: string;
  search: {
    [key: string]: string | string[];
  };
  locationHash: string;
  disableSsoSwitching: boolean;
  inviteId?: string;
  evc?: string;
}

export interface InitializationResult {
  isRedirecting: boolean;
  parsedParams: ParsedQueryParams;
  customClientId?: string;
  pendingSocialLogout?: IDP;
  pendingSharedLogout?: boolean;
  noSocial: boolean;
  identityProviders?: ClientIdentityProviders[];
}

export interface GetAccountClientIdResponse {
  clientId: string;
  identityProviders: ClientIdentityProviders[];
}

const loginApp = config.loginApp ?? LoginApps.phc;

// key to store app context between logouts
const logoutParamKey = '@@lifeomic/store/logout-params';

function getAppToPersist(): string {
  switch (loginApp) {
    case LoginApps.lifeology:
      return 'lifeology-admin';
    case LoginApps.skillspring:
      return '';
    case LoginApps.phc:
      return 'me';
  }
}

function setLogoutParams(params: ParsedParams = {}) {
  // only persist params necessary for keeping "app" context but not "session" context
  const paramsToPersist: ParsedParams = {
    app: params.app || getAppToPersist(),
  };

  window.localStorage.setItem(logoutParamKey, JSON.stringify(paramsToPersist));
}

function getLogoutParams(): ParsedParams {
  const rawValue = window.localStorage.getItem(logoutParamKey);

  return rawValue ? JSON.parse(rawValue) : {};
}

function getParams(): ParsedParams {
  const parsedParams = parseQueryParams(
    window.location.search,
    new URL(window.location.href)
  );

  /*
    if redirecting back from logout, retrieve the previously stored params from local storage.
    This helps maintain context between logouts
  */
  if (parsedParams.logout === 'success') {
    return {
      ...getLogoutParams(),
      idp: parsedParams.idp,
      logout: 'success',
    };
  } else {
    return parsedParams || {};
  }
}

async function exchangeAuthCodeForToken(
  authCode: string,
  clientId: string,
  redirectUrl: string
): Promise<void> {
  try {
    const preRequestTime = new Date();
    const res: AxiosResponse<TokenAuthResponse> = await axios({
      url: urls.api.auth.token,
      method: 'POST',
      data: {
        grantType: 'authorization_code',
        clientId,
        redirectUri: urls.api.auth.redirectUri(),
        code: authCode,
        issueCookies: true,
        cookieDomain: config.cookieDomain,
      },
    });

    const authResponse = tokenResponseToAuthResponse(res.data);
    handleAuthResponse(
      authResponse,
      preRequestTime,
      null,
      redirectUrl,
      clientId
    );
  } catch (e) {
    console.error(e);
    throw new Error(`Error occurred during authorization code flow`);
  }
}

async function fetchClientDetails(
  subDomain: string
): Promise<GetAccountClientIdResponse | null> {
  if (!subDomain) return null;

  try {
    const res: AxiosResponse<GetAccountClientIdResponse> = await axios({
      url: urls.api.auth.getClientId(subDomain),
      method: 'GET',
    });

    return res.data || null;
  } catch (e) {
    console.error(e);
    throw new Error(
      `Error occurred retrieving the OAuth Client id for account: "${subDomain}"`
    );
  }
}

function getDefaultApp(): string {
  switch (loginApp) {
    case LoginApps.lifeology:
      return 'admin';
    case LoginApps.marketplace:
      return '';
    case LoginApps.skillspring:
      return '';
    case LoginApps.phc:
      return 'app-switcher';
  }
}

// if no app specified in the url, redirect to default app
const DEFAULT_APP = getDefaultApp();

export default async (): Promise<InitializationResult> => {
  // retrieve params from url or from local storage (in the event of redirecting back from logout)
  const params = getParams();
  const {
    redirectDomain,
    subDomain,
    app = DEFAULT_APP,
    destination,
    // cognito redirect params
    code,
    'login-app-redirect-url': loginAppRedirectUrl,
    clientId,
    logout,
    idp,
    'no-social': noSocialRawValue,
    locationHash,
    customClientIdAccount,
    evc,
    state,
    ...search
  } = params;

  // NOTE: We want inviteId to stay in the `...search` above.
  const inviteId = params.inviteId;

  const disableSsoSwitching = params.disableSsoSwitching === '1';
  const noSocial = noSocialRawValue === 'true';

  // disableSsoSwitching is a param for phc-login. Remove it from search parameter
  // so that it doesn't get propagated to the app that started this.
  if (search.disableSsoSwitching !== undefined) {
    delete search.disableSsoSwitching;
  }

  function startCognitoLogout() {
    // logout request from phc app
    // persist the params so context is persisted after redirecting back
    setLogoutParams(params);

    // Persist app identifier thru redirectUri
    const appIdentifier = search?.appIdentifier as string;

    // navigate to cognito logout;
    windowMethods.setLocationHref(
      urls.api.auth.cognito.logout(idp, appIdentifier)
    );
    return {
      isRedirecting: true,
      parsedParams: null as ParsedQueryParams,
      noSocial,
    };
  }

  if (logout === 'pending') {
    startCognitoLogout();
  } else if (code) {
    /*
      the presence of the code query param indicates that initialization is occuring
      from a redirect from cognito.  This is part of the authorization code flow.
      The authorization code must be exchanged for access tokens.
    */
    if (!validateState(state)) {
      throw new Error('OAuth state mismatch');
    }
    await exchangeAuthCodeForToken(
      code,
      clientId,
      fullyDecodeURIComponent(loginAppRedirectUrl)
    );
    return {
      isRedirecting: true,
      parsedParams: null,
      noSocial,
    };
  } else {
    let pendingSocialLogout: IDP = null;
    let pendingSharedLogout = false;

    const socialLoginIdp = idp ?? getSocialIdp();
    const isSharedDevice = getIsSharedDevice();

    if (logout === 'success') {
      // if returning from logout redirect, clear the persisted auth
      clearAuthStorage();

      // if user logged out of cognito with a social idp and on a shared device, also log out of social account
      if (isSharedDevice) {
        if (socialLoginIdp) {
          pendingSocialLogout = socialLoginIdp;
        } else {
          /*
          Being a shared computer, navigate to a "dead end" page upon successful logout.  This will require users to reuse the same original url
          to navigate to their target app
          */
          pendingSharedLogout = true;
        }

        // Being a shared computer, we aggressively clear all local storage.
        window.localStorage.clear();
      }
    } else if (socialLoginIdp && isSharedDevice) {
      startCognitoLogout();
    }

    // marketplace-ui and skillspring console sits at the root, so we don't require "app" for it
    if (
      loginApp !== LoginApps.marketplace &&
      loginApp !== LoginApps.skillspring &&
      !app
    ) {
      throw new Error('app is required for login');
    }

    if (!isLocalStorageEnabled())
      throw new Error(
        'Local storage is currently disabled.  Local storage is required to use the Precision Health Cloud.  Please try using this site outside of private mode.'
      );

    createStateId();
    const customClientId = await fetchClientDetails(
      customClientIdAccount || subDomain
    );
    return {
      isRedirecting: false,
      parsedParams: {
        redirectDomain,
        subDomain,
        app,
        destination,
        search,
        locationHash,
        disableSsoSwitching,
        inviteId,
        evc,
      },
      customClientId: customClientId?.clientId ?? clientId,
      identityProviders: customClientId?.identityProviders,
      pendingSocialLogout,
      pendingSharedLogout,
      noSocial,
    };
  }
};
