import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import useValidators from '../../hooks/useValidatiors';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { useVerificationEndpoint } from '../../hooks/api';
import { AxiosResponse } from 'axios';
import { CodeConfirmationResponse } from '../../hooks/api/useVerificationEndpoint';
import { loginSelectors } from '../../redux/modules/Login';
import handleAuthResponse from '../../util/handleAuthResponse';
import { getTestProps } from '../../util/testProps';
import clsx from 'clsx';
import queryString from 'query-string';
import config from '../../util/config';
import { LoginApps } from '../../util/loginApps';
import useLoginApp from '../../redux/hooks/useLoginApp';

const messages = {
  verificationCode: {
    id: 'verificationCodeForm.verificationCode',
    defaultMessage: 'Verification Code',
  },
  submit: {
    id: 'verificationCodeForm.submit',
    defaultMessage: 'Submit',
  },
};

export const testIds = {
  code: 'verificationCodeForm-code',
  submitButton: 'verificationCodeForm-submit',
};

export type FormValues = {
  code: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
  },
  signupButton: {
    marginTop: theme.spacing(4),
    height: 40,
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export type VerificationCodeFormClasses = GetClasses<typeof useStyles>;

export type VerificationCodeFormOwnProps = {};

export type VerificationCodeFormProps = VerificationCodeFormOwnProps;

type QueryParams = {
  username?: string;
  email?: string;
  originalUrl?: string;
  verificationCode?: string;
};

const VerificationCodeForm = (props: VerificationCodeFormProps) => {
  const {
    username,
    email: providedEmail,
    originalUrl: providedUrl,
    verificationCode: providedCode,
  }: QueryParams = queryString.parse(window.location.search);
  const loginApp = useLoginApp();
  const isPhcLogin = loginApp === LoginApps.phc;
  const isMarketplaceLogin = loginApp === LoginApps.marketplace;

  const initialValues: FormValues = {
    code: providedCode || '',
  };

  const formRef = React.useRef<FormikProps<FormValues>>();
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const validators = useValidators();
  const email = useSelector(loginSelectors.selectSignupEmail) || providedEmail;
  const originalUrl =
    useSelector(loginSelectors.selectOriginalUrl) || providedUrl;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);
  const [res, verifyCode] = useVerificationEndpoint(username);

  const onSubmit = React.useCallback(
    (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      const { code } = formValues;
      const shouldUseCookies = isMarketplaceLogin || isPhcLogin;

      const preRequestTime = new Date();
      verifyCode(
        {
          code,
          issueCookies: shouldUseCookies ? true : undefined,
          cookieDomain: shouldUseCookies ? config.cookieDomain : undefined,
        },
        (axiosResponse: AxiosResponse<CodeConfirmationResponse>) => {
          const redirectUrl = axiosResponse.data.originalUrl || originalUrl;
          handleAuthResponse(
            axiosResponse.data,
            preRequestTime,
            email,
            redirectUrl,
            customClientId
          );
        }
      );
      actions.setSubmitting(false);
    },
    [
      customClientId,
      email,
      originalUrl,
      verifyCode,
      isMarketplaceLogin,
      isPhcLogin,
    ]
  );

  React.useEffect(() => {
    // Only auto-submit if all requirements are met.
    // `username` & `providedCode` only exist here as URL params which prevents this from firing based on user interaction.
    const isSubmittable = username && email && originalUrl && providedCode;

    if (isSubmittable && formRef.current.submitCount === 0) {
      formRef.current.handleSubmit();
    }
  }, [username, email, originalUrl, providedCode]);

  return (
    <Formik
      innerRef={formRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form className={classes.formContainer}>
          <Field
            {...getTestProps(testIds.code)}
            aria-label="Verification Code"
            fullWidth
            name="code"
            autoComplete="one-time-code"
            component={FormikTextField}
            placeholder={formatMessage(messages.verificationCode)}
            validate={validators.required}
          />
          <Button
            {...getTestProps(testIds.submitButton)}
            className={clsx(classes.signupButton)}
            disabled={formikProps.isSubmitting || res.isLoading}
            type="submit"
            variant="contained"
          >
            {formatMessage(messages.submit)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(VerificationCodeForm);
