import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox } from '@lifeomic/chroma-react/components/Checkbox';
import { makeStyles } from '@lifeomic/chroma-react/styles';
import {
  getIsSharedDevice,
  setIsSharedDevice,
} from '../../../util/sharedDevice';
import { getTestProps } from '../../../util/testProps';

const messages = {
  label: {
    id: 'sharedDeviceToggle.checkboxLabel',
    defaultMessage: 'This is a shared computer',
  },
};

const useStyles = makeStyles((theme) => ({
  box: {
    strokeOpacity: 0.5,
  },
  label: {
    '& p': {
      fontSize: theme.typography.button.fontSize,
      margin: theme.spacing(0.375, 0, 0, 0.625),
    },
  },
}));

export const testIds = {
  checkbox: 'sharedDeviceToggle-checkbox',
};

export interface SharedDeviceToggleOwnProps {
  className?: string;
}

export type SharedDeviceToggleProps = SharedDeviceToggleOwnProps;

const SharedDeviceToggle = (props: SharedDeviceToggleProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles({});
  const [isSharedDevice, setSharedDeviceState] = useState<boolean>(
    getIsSharedDevice()
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSharedDeviceState(e.target.checked);

    // mirror state in local storage
    setIsSharedDevice(e.target.checked);
  }, []);

  return (
    <div className={props.className}>
      <Checkbox
        classes={{
          box: classes.box,
          label: classes.label,
        }}
        onChange={handleChange}
        {...getTestProps(testIds.checkbox)}
        checked={isSharedDevice}
        label={formatMessage(messages.label)}
      />
    </div>
  );
};

export default React.memo(SharedDeviceToggle);
