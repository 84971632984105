/**
 * Determines if running in an e2e environment.
 *
 * It is generally not good testing practice to alter the execution
 * of your app depending on tests.  Use this only when necessary
 */
function useIsRunningInE2E() {
  return !!(window as any).Cypress;
}

export default useIsRunningInE2E;
