import * as React from 'react';

const SvgAtSymbol = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgAtSymbol"
    {...props}
  >
    <path
      d="M12.000000000000002 8.5a3.5 3.5 0 103.5 3.5A3.5 3.5 0 0012 8.5zm3.5 0v4.455a2.716 2.716 0 003.084 2.529 2.9 2.9 0 002.349-2.584A5.911 5.911 0 0021 12a9 9 0 10-3.935 7.44"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgAtSymbol;
