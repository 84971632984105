import { Components } from '@lifeomic/chroma-react/styles/overrides/ChromaComponents';
import { createPalette, createTheme } from '@lifeomic/chroma-react/styles';
import { OverridesCreator } from '@lifeomic/chroma-react/styles/overrides';

const green = {
  50: '#E7F6E9',
  100: '#CDECC0',
  200: '#B8E3A5',
  300: '#81D25C',
  400: '#5EC065',
  500: '#3fb549',
  600: '#36a640',
  700: '#299435',
  800: '#1D832A',
  900: '#006316',
};

export const palette = createPalette({
  primary: {
    ...green,
    light: green[300],
    main: green[800],
    dark: green[900],
    contrastText: '#fff',
  },
});

export const componentOverrides: OverridesCreator = (): Components => ({}); // re-enable if overrides are needed

export const theme = createTheme({
  components: componentOverrides,
  palette,
});
