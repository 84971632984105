import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import ResendMessage from './ResendMessage';
import { useSelector } from 'react-redux';
import { loginSelectors } from '../../../redux/modules/Login';
import { useResendCodeEndpoint } from '../../../hooks/api';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));

export type ResendCodeSectionClasses = GetClasses<typeof useStyles>;

export type ResendCodeSectionOwnProps = {};

export type ResendCodeSectionProps = ResendCodeSectionOwnProps;

const ResendCodeSection = (props: ResendCodeSectionProps) => {
  const classes = useStyles(props);
  const username = useSelector(loginSelectors.selectSignupUsername);
  const [resendCodeState, resendCode] = useResendCodeEndpoint();

  const handleResend = React.useCallback(() => {
    resendCode(username);
  }, [resendCode, username]);

  return (
    <div className={classes.root}>
      <ResendMessage
        onResend={handleResend}
        isLoading={resendCodeState.isLoading}
      />
    </div>
  );
};

export default React.memo(ResendCodeSection);
