import React from 'react';
import getBugsnagClient from './getBugsnagClient';
import config from '../config';

export type RootErrorBoundaryOwnProps = {};

export type RootErrorBoundaryProps = RootErrorBoundaryOwnProps;

const RootErrorBoundary: React.FC<RootErrorBoundaryProps> = ({ children }) => {
  if (config.disableBugsnagReporting === 'true')
    return <React.Fragment>{children}</React.Fragment>;
  try {
    const apiKey = (window as any).__INITIAL_STATE__.config.bugsnagKey;
    const releaseStage = (window as any).__INITIAL_STATE__.config.releaseStage;
    const beforeSend = (report: any) => {
      report.breadcrumbs = report.breadcrumbs.map((b: any) => {
        if (
          (b.metaData || {}).targetText &&
          (b.metaData || {}).targetText !== '(...)'
        ) {
          b.metaData.targetText = '[REDACTED]';
        }
        return b;
      });
    };
    const client = getBugsnagClient({ apiKey, releaseStage, beforeSend });
    const BugsnagBoundary = client.getPlugin('react');
    return <BugsnagBoundary>{children}</BugsnagBoundary>;
  } catch (e) {
    console.warn('Error boundary not loaded');
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default RootErrorBoundary;
