import React, { useState } from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import BrandedBackground from './common/BrandedBackground';
import ErrorHandler from './ErrorHandler';
import Entry from './Entry';
import { useDispatch } from 'react-redux';
import { loginActions } from '../redux/modules/Login';
import Verification from './Verification';
import Login from './Login';
import VerifyPasswordless from './VerifyPasswordless';
import { appErrorsActions } from '../redux/modules/AppErrors';
import AppNotifications from './common/AppNotifications';
import { Switch, Route } from 'react-router-dom';
import urls from '../util/urls';
import ForgotPassword from './ForgotPassword';
import initializeApp, { InitializationResult } from '../util/initializeApp';
import PendingSocialLogout from './PendingSocialLogout';
import { push } from 'connected-react-router';
import Signup from './Signup';
import PendingSharedLogout from './PendingSharedLogout';
import { SSORedirect } from './SSORedirect';
import PasswordlessLogin from './EmailLogin';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export type LoginRootClasses = GetClasses<typeof useStyles>;

export type AppRootOwnProps = {};

export type AppRootProps = AppRootOwnProps;

export interface SearchParams {
  redirectDomain: string;
  subDomain: string;
  app: string;
  destination: string;
}

const App = (props: AppRootProps) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const [isInitializing, setIsInitializing] = useState(true);

  React.useEffect(() => {
    initializeApp()
      .then((initializationResult: InitializationResult) => {
        if (!initializationResult.isRedirecting) {
          const {
            redirectDomain,
            subDomain,
            app,
            destination,
            search,
            locationHash,
            disableSsoSwitching,
            inviteId,
            evc,
          } = initializationResult.parsedParams;

          dispatch(
            loginActions.setLoginParams(
              redirectDomain,
              subDomain,
              app,
              destination,
              search,
              initializationResult.noSocial,
              disableSsoSwitching,
              locationHash,
              inviteId,
              evc
            )
          );

          if (initializationResult.customClientId) {
            dispatch(
              loginActions.setSubDomainClientId(
                initializationResult.customClientId
              )
            );
          }

          if (initializationResult.identityProviders?.length) {
            dispatch(
              loginActions.setClientIdentityProviders(
                initializationResult.identityProviders
              )
            );
          }

          if (initializationResult.pendingSocialLogout) {
            dispatch(
              push(
                urls.app.roots.pendingSocialLogout({
                  idp: initializationResult.pendingSocialLogout,
                })
              )
            );
          } else if (initializationResult.pendingSharedLogout) {
            dispatch(push(urls.app.roots.pendingSharedLogoutAction()));
            setIsInitializing(false);
          } else {
            setIsInitializing(false);
          }
        }
      })
      .catch((e) => {
        dispatch(appErrorsActions.registerError(e));
      });
  }, []);

  return (
    <BrandedBackground className={classes.root}>
      <ErrorHandler>
        <Switch>
          <Route
            exact
            path={urls.app.roots.app()}
            render={() => <Entry isInitializing={isInitializing} />}
          />
          <Route
            exact
            path={urls.app.roots.verification()}
            render={() => <Verification />}
          />
          <Route exact path={urls.app.roots.login()} render={() => <Login />} />
          <Route
            exact
            path={urls.app.roots.passwordlessLogin()}
            render={() => <PasswordlessLogin />}
          />
          <Route exact path={urls.app.roots.signup} render={() => <Signup />} />
          <Route
            exact
            path={urls.app.roots.passwordlessAuthVerify()}
            render={() => <VerifyPasswordless />}
          />
          <Route
            exact
            path={urls.app.roots.forgotPassword()}
            render={() => <ForgotPassword />}
          />
          <Route
            exact
            path={urls.app.roots.pendingSocialLogout()}
            render={() => <PendingSocialLogout />}
          />
          <Route
            exact
            path={urls.app.roots.pendingSharedLogoutAction()}
            render={() => <PendingSharedLogout />}
          />
          <Route
            exact
            path={urls.app.roots.ssoRedirect}
            render={() => <SSORedirect />}
          />
        </Switch>
      </ErrorHandler>
      <AppNotifications />
    </BrandedBackground>
  );
};

export default App;
