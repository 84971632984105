import {
  TextField,
  TextFieldProps,
} from '@lifeomic/chroma-react/components/TextField';
import { FieldProps, getIn } from 'formik';
import React from 'react';

interface FormikTextFieldProps
  extends Omit<TextFieldProps, 'form'>,
    FieldProps {}

export const FormikTextField: React.FC<FormikTextFieldProps> = ({
  className,
  label,
  value,
  name,
  form: { isSubmitting, touched, errors },
  // field gives us things like `name`, `onChange, `onBlur`, etc.
  field,
  disabled,
  ...rootProps
}) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <TextField
      className={className}
      label={label}
      value={value}
      hasError={showError}
      errorMessage={showError && fieldError}
      disabled={disabled ?? isSubmitting}
      {...field}
      {...rootProps}
    />
  );
};
