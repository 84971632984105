import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { appErrorSelectors } from '../../redux/modules/AppErrors';
import { Collapse } from '@mui/material';
import { IconButton } from '@lifeomic/chroma-react/components/IconButton';
import { ChevronUp, ChevronDown } from '@lifeomic/chromicons';
import { Text } from '@lifeomic/chroma-react/components/Text';

const messages = {
  label: {
    id: 'collapsableDetails.label',
    defaultMessage: 'Details',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  detailsContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
}));

export type CollapsableDetailsClasses = GetClasses<typeof useStyles>;

export type CollapsableDetailsOwnProps = {};

export type CollapsableDetailsProps = CollapsableDetailsOwnProps;

const CollapsableDetails = (props: CollapsableDetailsProps) => {
  const [isCollapsed, setCollapse] = React.useState(false);
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const hasRegisteredAppError = useSelector(
    appErrorSelectors.selectHasAppErrorsOccurred
  );
  const errorMessages = useSelector(appErrorSelectors.selectErrorMessages);

  if (!hasRegisteredAppError || !errorMessages) return null;

  return (
    <>
      <div className={classes.header}>
        <Text size="body">{formatMessage(messages.label)}</Text>
        <IconButton
          aria-label="expand details"
          size={0}
          icon={(isCollapsed ? ChevronUp : ChevronDown) as any}
          onClick={() => setCollapse(!isCollapsed)}
        />
      </div>
      <Collapse in={isCollapsed}>
        <div className={classes.detailsContainer}>
          <Text size="caption">{errorMessages}</Text>
        </div>
      </Collapse>
    </>
  );
};

export default React.memo(CollapsableDetails);
