import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { Box } from '@lifeomic/chroma-react/components/Box';
import clsx from 'clsx';
import useIsMobile from '../../../hooks/useIsMobile';
import SignupForm from '../SignupForm';
import useIsCustomSSOProvider from '../../../hooks/useIsCustomSSOProvider';
import CustomSSOSignup from '../CustomSSOSignup';
import SocialLogin from '../../common/SocialLogin';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { SignupContainerProps } from '.';
import useIsInGovCloudEnv from '../../../hooks/useIsInGovCloudEnv';
import { Divider } from '@lifeomic/chroma-react/components/Divider';
import { IconButton } from '@lifeomic/chroma-react/components/IconButton';
import { Tooltip } from '@lifeomic/chroma-react/components/Tooltip';
import urls from '../../../util/urls';
import { ologyColors } from '../../../styles';
import { ArrowLeft as BackIcon, X as CloseIcon } from '@lifeomic/chromicons';

const messages = {
  createAccountText: {
    id: 'signupContainer.createAccount',
    defaultMessage: 'CREATE AN ACCOUNT',
  },
  loginAccountText: {
    id: 'signupContainer.loginAccount',
    defaultMessage: 'LIFEOLOGY LOGIN',
  },
  separateAccount: {
    id: 'signupContainer.separateAccount',
    defaultMessage: 'This is separate from your ',
  },
  lifeologyCommunityLogin: {
    id: 'signupContainer.lifeologyCommunityLogin',
    defaultMessage: 'Lifeology Community login',
  },
  or: {
    id: 'signupContainer.or',
    defaultMessage: 'or',
  },
  signUpWithEmail: {
    id: 'signupContainer.signUpWithEmail',
    defaultMessage: 'Sign up with your email',
  },
  backToOptions: {
    id: 'signupContainer.backToOptions',
    defaultMessage: 'Back to sign up options',
  },
  closeEmailSignUp: {
    id: 'signupContainer.backToOptions',
    defaultMessage: 'Close email sign up',
  },
  emailSignUp: {
    id: 'signupContainer.emailSignUp',
    defaultMessage: 'Email Sign Up',
  },
  signUpDisclaimer: {
    id: 'signupContainer.signUpDisclaimer',
    defaultMessage: 'By logging into an account, you are agreeing to our ',
  },
  privacyPolicy: {
    id: 'signupContainer.privacyPolicy',
    defaultMessage: 'privacy policy',
  },
  and: {
    id: 'signupContainer.and',
    defaultMessage: ' and ',
  },
  termsOfUse: {
    id: 'signupContainer.termsOfUse',
    defaultMessage: 'terms of use',
  },
  period: {
    id: 'signupContainer.endPunctuation',
    defaultMessage: '.',
  },
};

// Disabling self-registration / account create access points for the Lifeology
// product; specifically for the 'member' subscription project. This login
// page is still used for Enterprise users who are added to their projects
// through the standard PHC user invitation flows.
const disableSelfService = true;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    height: '100%',
    minHeight: 480,
    backgroundColor: theme.palette.common.white,
  },
  mobileRootVariant: {
    flex: 'unset',
    height: 'unset',
    width: '100%',
    minHeight: 'unset',
  },
  content: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 'inherit',
  },
  loginAccountText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    margin: theme.spacing(2.75, -4, 2),
    width: `calc(100% + ${theme.spacing(8)}px)`,
  },
  orBox: {
    marginBottom: theme.spacing(4),
    width: theme.pxToRem(200),
    '& hr': {
      flex: 1,
      margin: 0,
    },
  },
  orSignUpText: {
    color: theme.palette.text.hint,
    padding: theme.spacing(0, 2),
  },
  emailSignUpHeader: {
    marginBottom: theme.spacing(0.5),
    '& button:first-child': {
      marginTop: theme.spacing(-0.5),
      marginLeft: theme.spacing(-1.5),
    },
    '& button:last-child': {
      marginTop: theme.spacing(-0.5),
      marginRight: theme.spacing(-1.5),
    },
  },
  emailSignUpBtn: {
    background: theme.palette.common.white,
    borderRadius: theme.pxToRem(19),
    boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.175)`,
    color: theme.palette.black[800],
    height: theme.pxToRem(38),
    maxHeight: 'none',
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  backToOptions: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '&, & *': {
      color: theme.palette.primary[900],
    },
  },
  disclaimer: {
    color: theme.palette.text.hint,
    lineHeight: theme.pxToRem(18),
    textAlign: 'center',
  },
  link: {
    color: ologyColors.link,
    '&:hover, &:focus': {
      color: ologyColors.linkHover,
    },
  },
}));

export type SignupContainerClasses = GetClasses<typeof useStyles>;

const LifeologySignupContainer = (props: SignupContainerProps) => {
  const [showSignup, setShowSignup] = useState(false);
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const isCustomSSOProvider = useIsCustomSSOProvider();
  const isInGovCloudEnv = useIsInGovCloudEnv();

  const toggleSignup = () => {
    setShowSignup(!showSignup);
    window &&
      document &&
      setTimeout(() => {
        (window as any).root.scrollTo(0, document.body.scrollHeight);
      }, 100);
  };

  return (
    <div className={clsx(classes.root, isMobile && classes.mobileRootVariant)}>
      <div className={classes.content}>
        <Text size="headline" className={classes.loginAccountText}>
          {formatMessage(messages.loginAccountText)}
        </Text>
        <Box marginX={2} marginTop={1}>
          <Text className={classes.disclaimer} size="caption">
            {formatMessage(messages.separateAccount)}
            <a
              href={urls.external.lifeologyCommunityLogin}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {formatMessage(messages.lifeologyCommunityLogin)}
            </a>
            {formatMessage(messages.period)}
          </Text>
        </Box>
        {disableSelfService && (
          <>
            <Divider className={classes.divider} />
          </>
        )}
        {!disableSelfService && !isCustomSSOProvider && !isInGovCloudEnv && (
          <>
            {!showSignup && (
              <>
                <SocialLogin disableApple />
                <Box className={classes.orBox} align="center" fullWidth>
                  <Divider />
                  <Text size="body" className={classes.orSignUpText}>
                    {formatMessage(messages.or)}
                  </Text>
                  <Divider />
                </Box>
              </>
            )}
            {showSignup ? (
              <>
                <Divider className={classes.divider} />
                <Box
                  className={classes.emailSignUpHeader}
                  align="center"
                  justify="space-between"
                  fullWidth
                >
                  <Tooltip title={formatMessage(messages.backToOptions)}>
                    <IconButton
                      aria-label={formatMessage(messages.backToOptions)}
                      icon={BackIcon}
                      onClick={toggleSignup}
                      size={0}
                    />
                  </Tooltip>
                  <Text size="body" weight="bold">
                    {formatMessage(messages.emailSignUp)}
                  </Text>
                  <Tooltip title={formatMessage(messages.closeEmailSignUp)}>
                    <IconButton
                      aria-label={formatMessage(messages.closeEmailSignUp)}
                      icon={CloseIcon}
                      onClick={toggleSignup}
                      size={0}
                    />
                  </Tooltip>
                </Box>
              </>
            ) : (
              <Button
                variant="text"
                onClick={toggleSignup}
                className={classes.emailSignUpBtn}
              >
                <Text size="subbody" weight="bold">
                  {formatMessage(messages.signUpWithEmail)}
                </Text>
              </Button>
            )}
          </>
        )}

        <Box marginX={2}>
          <Text className={classes.disclaimer} size="caption">
            {formatMessage(messages.signUpDisclaimer)}
            <a
              href={urls.external.lifeologyPrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {formatMessage(messages.privacyPolicy)}
            </a>
            {formatMessage(messages.and)}
            <a
              href={urls.external.lifeologyTermsOfUse}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              {formatMessage(messages.termsOfUse)}
            </a>
            {formatMessage(messages.period)}
          </Text>
        </Box>

        {showSignup && (
          <>{isCustomSSOProvider ? <CustomSSOSignup /> : <SignupForm />}</>
        )}
      </div>
    </div>
  );
};

export default LifeologySignupContainer;
