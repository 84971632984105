import * as React from 'react';

const SvgRefreshCw = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgRefreshCw"
    {...props}
  >
    <path
      d="M3 12a8.994 8.994 0 0116.9-4.3M21 12a8.988 8.988 0 01-13.538 7.763A9.277 9.277 0 014.2 16.5M14.7 7l2.7.55 2.7.55.3-2.55.3-2.55M9.3 17l-2.7-.55-2.7-.55-.3 2.55L3.3 21"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgRefreshCw;
