import * as React from 'react';

const SvgRotateCcw = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgRotateCcw"
    {...props}
  >
    <path
      d="M3.05 2.75l.3 2.55.3 2.55 2.7-.55 2.7-.55m-4.8 0a8.954 8.954 0 11-1.5 5.7"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgRotateCcw;
