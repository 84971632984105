import { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';

export type UseAPIConfig = {
  initialData?: any;
  errorNotificationIntlMessage?: MessageDescriptor;
};

export interface ApiRequestState {
  data: any;
  isLoading: boolean;
  isError: boolean;
  error: Error;
  isComplete: boolean;
  hasMadeRequest: boolean;
}

export type UseAPIOwnRequestConfig = {
  onSuccess?: (axiosResponse?: AxiosResponse<any>) => void;
  onFailure?: (axiosError?: AxiosError) => void;
};

export type RequestConfig = AxiosRequestConfig & UseAPIOwnRequestConfig;

export type GenerateAxiosRequestSignature = (...args: any[]) => RequestConfig;
export type TriggerRequestSignature = (...args: any[]) => void;

const useApi = (
  fn: GenerateAxiosRequestSignature,
  useApiConfig: UseAPIConfig = {}
): [ApiRequestState, TriggerRequestSignature] => {
  const [res, setRes] = useState<ApiRequestState>({
    data: useApiConfig.initialData || null,
    isLoading: false,
    isError: false,
    error: null,
    isComplete: false,
    hasMadeRequest: false,
  });
  const [req, setReq] = useState<RequestConfig>();
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;

  useEffect(() => {
    if (!req) return;

    setRes({
      data: null,
      isLoading: true,
      isError: false,
      error: null,
      isComplete: false,
      hasMadeRequest: true,
    });

    axios(req)
      .then((res) => {
        if (req.onSuccess) req.onSuccess(res);

        setRes({
          data: res.data,
          isLoading: false,
          isError: false,
          error: null,
          isComplete: true,
          hasMadeRequest: true,
        });
      })
      .catch((axiosError: AxiosError) => {
        if (useApiConfig.errorNotificationIntlMessage) {
          dispatch(
            notificationsActions.addNotification(
              formatMessage(useApiConfig.errorNotificationIntlMessage),
              { statusType: 'error' }
            )
          );
        }

        if (req.onFailure) req.onFailure(axiosError);

        setRes({
          data: res.data,
          isLoading: false,
          isError: true,
          error: axiosError,
          isComplete: true,
          hasMadeRequest: true,
        });
      });
  }, [req]);

  return [res, (...args: any[]) => setReq(fn(...args))];
};

export default useApi;
