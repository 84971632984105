import React from 'react';
import usePasswordlessVerificationEndpoint from '../../hooks/api/usePasswordlessVerificationEndpoint';
import { AxiosResponse } from 'axios';
import handleAuthResponse, {
  AuthResponse,
} from '../../util/handleAuthResponse';
import { getPasswordlessAuth } from '../../util/passwordlessAuth';
import { useEffectOnce } from 'react-use';
import { useIntl } from 'react-intl';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import config from '../../util/config';
import SignInButton from '../Entry/SignInButton';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';
import { loginSelectors } from '../../redux/modules/Login';
import { useSelector } from 'react-redux';

const messages = {
  verifying: {
    id: 'verifyPasswordless.verifying',
    defaultMessage: 'Verifying...',
  },
  verified: {
    id: 'verifyPasswordless.verified',
    defaultMessage: 'Verified',
  },
  error: {
    id: 'verifyPasswordless.error',
    defaultMessage:
      'We were not able to sign you in. Ensure you are using the same browser used to initiate the request. Click the button to try again.',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  signInButton: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
}));

const VerifyPasswordlessForm = () => {
  const formatMessage = useIntl().formatMessage;
  const { session, originalUrl, username } = getPasswordlessAuth();
  const [verifyPasswordlessState, verifyPasswordless] =
    usePasswordlessVerificationEndpoint(session, username);
  const loginApp = useLoginApp();
  const classes = useStyles({});
  const app = useSelector(loginSelectors.selectApp);
  const destination = useSelector(loginSelectors.selectDestination);
  const isPhcLogin = loginApp === LoginApps.phc;
  const isMarketplaceLogin = loginApp === LoginApps.marketplace;

  useEffectOnce(() => {
    async function verify() {
      const shouldUseCookies = isMarketplaceLogin || isPhcLogin;

      const issueCookies = shouldUseCookies ? true : undefined;
      const cookieDomain = shouldUseCookies ? config.cookieDomain : undefined;

      const preRequestTime = new Date();
      verifyPasswordless(
        { issueCookies, cookieDomain, app, destination },
        (axiosResponse: AxiosResponse<AuthResponse>) => {
          handleAuthResponse(
            axiosResponse.data,
            preRequestTime,
            username,
            originalUrl
          );
        }
      );
    }
    verify();
  });

  const error = (
    <FormBox justify="center" className={classes.signInButton}>
      <p>{formatMessage(messages.error)}</p>
      <SignInButton sso={false} />
    </FormBox>
  );

  return verifyPasswordlessState.isLoading ? (
    <p>{formatMessage(messages.verifying)}</p>
  ) : verifyPasswordlessState.isError ? (
    error
  ) : (
    <p>{formatMessage(messages.verified)}</p>
  );
};

export default React.memo(VerifyPasswordlessForm);
