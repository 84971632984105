import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { Tooltip } from '@mui/material';
import { loginSelectors } from '../../../redux/modules/Login';
import { Facebook } from '../../Icons/Facebook';
import { Apple } from '../../Icons/Apple';
import { Google } from '../../Icons/Google';
import { setSocialIdp } from '../../../util/socialLoginIdp';
import { IDP } from '../../PendingSocialLogout';

const messages = {
  loginAppleTooltip: {
    id: 'socialButton.loginAppleTooltip',
    defaultMessage: 'Login using Apple',
  },
  loginFacebookTooltip: {
    id: 'socialButton.loginFacebookTooltip',
    defaultMessage: 'Login using Facebook',
  },
  loginGoogleTooltip: {
    id: 'socialButton.loginGoogleTooltip',
    defaultMessage: 'Login using Google',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.125)`,
    borderRadius: '50%',
    margin: theme.spacing(2, 1.75),
  },
}));

export interface SocialButtonOwnProps {
  provider: 'facebook' | 'google' | 'signinwithapple';
}

export type SocialButtonProps = SocialButtonOwnProps;

const SocialButton = (props: SocialButtonProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);

  const { provider, ...restProps } = props;

  const appleLink = useSelector(loginSelectors.selectAppleIdpUrl);
  const facebookLink = useSelector(loginSelectors.selectFacebookIdpUrl);
  const googleLink = useSelector(loginSelectors.selectGoogleIdpUrl);

  const providerMap = {
    google: {
      icon: Google,
      link: googleLink,
      provider: 'Google',
      tooltip: messages.loginGoogleTooltip,
      idp: 'google' as IDP,
    },
    facebook: {
      icon: Facebook,
      link: facebookLink,
      provider: 'Facebook',
      tooltip: messages.loginFacebookTooltip,
      idp: 'facebook' as IDP,
    },
    signinwithapple: {
      icon: Apple,
      link: appleLink,
      provider: 'SignInWithApple',
      tooltip: messages.loginAppleTooltip,
      idp: 'signinwithapple' as IDP,
    },
  };

  const hrefLink = providerMap[provider]?.link;
  const Icon = providerMap[provider]?.icon;
  const idp: IDP = providerMap[provider]?.idp;

  const content = () => {
    function goToSocialLogin() {
      setSocialIdp(idp);
      window.location.assign(hrefLink);
    }

    return (
      <a
        aria-label={`${provider} login`}
        className={classes.root}
        onClick={goToSocialLogin}
        style={{ cursor: 'pointer' }}
        {...restProps}
      >
        <Icon />
      </a>
    );
  };

  return (
    <>
      <Tooltip title={formatMessage(providerMap[provider]?.tooltip)}>
        {content()}
      </Tooltip>
    </>
  );
};

export default React.memo(SocialButton);
