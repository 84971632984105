import { Components } from '@lifeomic/chroma-react/styles/overrides/ChromaComponents';
import { createPalette, createTheme } from '@lifeomic/chroma-react/styles';
import { OverridesCreator } from '@lifeomic/chroma-react/styles/overrides';

export const palette = createPalette({
  primary: {
    main: '#006eb7',
    800: '#00539a',
  },
});

export const componentOverrides: OverridesCreator = (): Components => ({});

export const breakpoints = {
  xl: 1500,
  lg: 1200,
  md: 1000,
  sm: 600,
  xs: 300,
};

export const theme = createTheme({
  breakpoints,
  palette,
  components: componentOverrides,
  typography: {
    fontFamily: '"Work Sans", "Roboto", sans-serif',
    // Add Work Sans as secondary font as well so Chroma's default
    // secondary font is not used anywhere in this app
    fontFamilySecondary: '"Work Sans", "Roboto", sans-serif',
  },
});
