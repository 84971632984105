type PersistedValue = 'true' | 'false';
export const localStorageKey = '@@lifeomic/store/is-shared-device';

export function setIsSharedDevice(isShared: boolean) {
  const value: PersistedValue = isShared ? 'true' : 'false';
  window.localStorage.setItem(localStorageKey, value);
}

export function getIsSharedDevice(): boolean {
  const storedValue = window.localStorage.getItem(
    localStorageKey
  ) as PersistedValue;
  return storedValue === 'true';
}
