import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Button } from '@lifeomic/chroma-react/components/Button';
import clsx from 'clsx';
import useIsMobile from '../../../hooks/useIsMobile';
import { useSelector, useDispatch } from 'react-redux';
import { loginSelectors } from '../../../redux/modules/Login';
import useIsCustomSSOProvider from '../../../hooks/useIsCustomSSOProvider';
import { push } from 'connected-react-router';
import urls from '../../../util/urls';
import { getTestProps } from '../../../util/testProps';
import { ButtonLink } from '@lifeomic/chroma-react/components/ButtonLink';
import { SignInButtonProps } from '.';
import { customThemeButtonStyles } from '../../../util/customTheming';
import { CustomTheme, useCustomTheme } from '../../../hooks/useCustomTheme';

export const testIds = {
  signInButton: 'signInButton-button',
};

const messages = {
  ssoSignInButtonText: {
    id: 'signInButton.ssoSignInButtonText',
    defaultMessage: 'Sign in with SSO',
  },
  signInButtonText: {
    id: 'signInButton.signInButtonText',
    defaultMessage: 'Sign in',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    height: 40,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.boxShadows.popover,
    '&:hover': {
      color: theme.palette.graphite[900],
      backgroundColor: theme.palette.graphite[100],
    },
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  customThemeButton: ({ customTheme }: { customTheme: CustomTheme }) =>
    customThemeButtonStyles(customTheme),
  mobileRootVariant: {
    width: 250,
    maxWidth: '80%',
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export type SignInButtonClasses = GetClasses<typeof useStyles>;

const PhcSignInButton = (props: SignInButtonProps) => {
  const formatMessage = useIntl().formatMessage;
  const customTheme = useCustomTheme();
  const classes = useStyles({
    ...props,
    customTheme,
  });
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isCustomOAuthProvider = useIsCustomSSOProvider();
  const sso = props.sso === undefined ? isCustomOAuthProvider : props.sso;
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);

  const setLogin = useCallback(() => {
    dispatch(push(urls.app.roots.login({ originalUrl })));
  }, []);
  const cognitoLoginUrl = useSelector(loginSelectors.selectCognitoLoginUrl);
  const absoluteLoginUrl = `${window.location.origin}${cognitoLoginUrl}`;

  if (sso) {
    return (
      <ButtonLink
        target="_self"
        to={absoluteLoginUrl}
        {...getTestProps(testIds.signInButton)}
        className={clsx(
          classes.root,
          isMobile && classes.mobileRootVariant,
          customTheme && classes.customThemeButton
        )}
      >
        {formatMessage(messages.ssoSignInButtonText)}
      </ButtonLink>
    );
  } else {
    return (
      <Button
        {...getTestProps(testIds.signInButton)}
        className={clsx(
          classes.root,
          isMobile && classes.mobileRootVariant,
          customTheme && classes.customThemeButton
        )}
        onClick={setLogin}
      >
        {formatMessage(messages.signInButtonText)}
      </Button>
    );
  }
};

export default PhcSignInButton;
