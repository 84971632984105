import * as React from 'react';

const SvgVideo2Off = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgVideo2Off"
    {...props}
  >
    <path
      d="M3.006 3l17.978 18m-4.893-4.625a2.27 2.27 0 01-2.241 1.915H5.269A2.269 2.269 0 013 16.02V7.98a2.269 2.269 0 012.269-2.27m6.092 0h2.489a2.269 2.269 0 012.269 2.27v2.314M21 15.706V8.294l-4.881 2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgVideo2Off;
