import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, FormikHelpers, FormikProps } from 'formik';
import { makeStyles } from '@lifeomic/chroma-react/styles';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import { Button } from '@lifeomic/chroma-react/components/Button';
import useValidators from '../../hooks/useValidatiors';
import { useDispatch } from 'react-redux';
import { getTestProps } from '../../util/testProps';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import { ologyColors } from '../../styles';
import { Hash } from '@lifeomic/chromicons';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';
import urls from '../../util/urls';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import { CustomTheme, useCustomTheme } from '../../hooks/useCustomTheme';
import { customThemeButtonStyles } from '../../util/customTheming';

const messages = {
  passwordlessCode: {
    id: 'passwordlessLoginForm.passwordlessCode',
    defaultMessage: 'Passwordless Code',
  },
  signInButton: {
    id: 'passwordlessLoginForm.signInButton',
    defaultMessage: 'Sign In',
  },
  incorrectChallengeCode: {
    id: 'passwordlessLoginForm.incorrectChallengeCode',
    defaultMessage: 'Code was not valid',
  },
  missingChallengeCode: {
    id: 'passwordlessLoginForm.missingChallengeCode',
    defaultMessage: 'You must enter a challenge code to sign in from here',
  },
};

export const testIds = {
  passwordlessCode: 'passwordlessLoginForm-passwordlessCode',
  submitButton: 'passwordlessLoginForm-submitButton',
};

export type FormValues = {
  challengeCode: string;
};

export const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(1),
    height: theme.pxToRem(40),
    width: '100%',
  },
  customThemeButton: ({ customTheme }: { customTheme: CustomTheme }) =>
    customThemeButtonStyles(customTheme),
  lifeologyButton: {
    borderRadius: theme.pxToRem(20),
    backgroundColor: 'none',
    backgroundImage: ologyColors.btnGradient,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundImage: ologyColors.btnGradientHover,
    },
    '&:disabled': {
      backgroundImage: ologyColors.btnGradient,
      opacity: 0.65,
    },
  },
  challengeCode: {
    marginTop: theme.spacing(1),
  },
}));

const PasswordlessLoginForm = () => {
  const customTheme = useCustomTheme();
  const classes = useStyles({ customTheme });
  const formatMessage = useIntl().formatMessage;
  const validators = useValidators();
  const dispatch = useDispatch();
  const loginApp = useLoginApp();
  const isLifeologyLogin = loginApp === LoginApps.lifeology;

  const onSubmit = React.useCallback(
    (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      const { challengeCode } = formValues;
      dispatch(
        push(
          urls.app.roots.passwordlessAuthVerify({
            challengeCode,
            retryCode: true,
            theme: customTheme ? JSON.stringify(customTheme) : undefined,
          })
        )
      );
      actions.setSubmitting(false);
    },
    []
  );

  const urlParams = new URLSearchParams(window.location.search);
  const incorrectCode = !!urlParams.get('incorrectCode');

  const validateForm = React.useCallback((formValues: FormValues) => {
    const errors: any = {};
    if (incorrectCode && !formValues.challengeCode) {
      errors.challengeCode = formatMessage(messages.incorrectChallengeCode);
    } else if (!formValues.challengeCode) {
      errors.challengeCode = formatMessage(messages.missingChallengeCode);
    }
    return errors;
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnMount={!!incorrectCode}
      initialValues={{ challengeCode: '' }}
      validate={validateForm}
    >
      {(formikProps: FormikProps<FormValues>) => {
        return (
          <Form>
            <FormBox>
              <Field
                {...getTestProps(testIds.passwordlessCode)}
                aria-label={formatMessage(messages.passwordlessCode)}
                fullWidth
                className={classes.challengeCode}
                name="challengeCode"
                component={FormikTextField}
                placeholder={formatMessage(messages.passwordlessCode)}
                validate={validators.required}
                startAdornment={<Hash aria-hidden />}
              />
              <Button
                {...getTestProps(testIds.submitButton)}
                className={clsx(
                  classes.submitButton,
                  isLifeologyLogin && classes.lifeologyButton,
                  customTheme && classes.customThemeButton
                )}
                disabled={formikProps.isSubmitting || !formikProps.isValid}
                type="submit"
                variant="contained"
              >
                {formatMessage(messages.signInButton)}
              </Button>
            </FormBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PasswordlessLoginForm;
