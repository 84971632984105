import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import clsx from 'clsx';

import { BrandedBackgroundProps } from '.';
import { ConfettiBackground } from '../../decor/ConfettiBackground';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    minHeight: '100%',
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 0,
  },
  content: {
    zIndex: 1,
  },
}));

const LifeologyBrandedBackground: React.FC<BrandedBackgroundProps> = (
  props: BrandedBackgroundProps
) => {
  const classes = useStyles(props);
  const { className, ...toPass } = props;

  return (
    <div className={clsx(classes.root, className)} {...toPass}>
      <ConfettiBackground className={classes.background} />
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

export default LifeologyBrandedBackground;
