import * as React from 'react';

export const Google = (props: any) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path
            d="M31,18.5H20.75v4.25h5.9C26.1,25.45,23.8,27,20.75,27a6.5,6.5,0,0,1,0-13,6.359,6.359,0,0,1,4.05,1.45l3.2-3.2A10.993,10.993,0,1,0,20.75,31.5c5.5,0,10.5-4,10.5-11A9.126,9.126,0,0,0,31,18.5Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="M8.75,27V14l8.5,6.5Z" fill="#fbbc05" />
      </g>
      <g clipPath="url(#a)">
        <path d="M8.75,14l8.5,6.5,3.5-3.05,12-1.95v-7h-24Z" fill="#ea4335" />
      </g>
      <g clipPath="url(#a)">
        <path d="M8.75,27l15-11.5,3.95.5,5.05-7.5v24h-24Z" fill="#34a853" />
      </g>
      <g clipPath="url(#a)">
        <path d="M32.75,32.5l-15.5-12-2-1.5,17.5-5Z" fill="#4285f4" />
      </g>
    </svg>
  );
};
