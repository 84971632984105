import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Formik,
  Form,
  Field,
  FormikProps,
  FormikHelpers,
  FormikErrors,
} from 'formik';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import useValidators, {
  trimWhitespaceFromForm,
} from '../../hooks/useValidatiors';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loginSelectors } from '../../redux/modules/Login';
import { useLoginEndpoint, usePasswordlessEndpoint } from '../../hooks/api';
import { AxiosResponse } from 'axios';
import { LoginResponse } from '../../hooks/api/useLoginEndpoint';
import handleAuthResponse from '../../util/handleAuthResponse';
import { getTestProps } from '../../util/testProps';
import urls, { appBaseSubdomains } from '../../util/urls';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import { ologyColors } from '../../styles';
import clsx from 'clsx';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';
import config from '../../util/config';
import { User, Lock, Login, Mail } from '@lifeomic/chromicons';
import {
  Radio,
  RadioGroupMinimal,
} from '@lifeomic/chroma-react/components/Radio';
import { Collapse, Fade } from '@mui/material';
import { PasswordlessResponse } from '../../hooks/api/usePasswordlessEndpoint';
import { setPasswordlessAuth } from '../../util/passwordlessAuth';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import { push } from 'connected-react-router';
import { Box } from '@lifeomic/chroma-react/components/Box';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { Divider } from '@lifeomic/chroma-react/components/Divider';
import { CustomTheme, useCustomTheme } from '../../hooks/useCustomTheme';
import { customThemeButtonStyles } from '../../util/customTheming';

const getMessages = (loginApp: string) => {
  const signInDefaultMessage =
    loginApp === LoginApps.lifeology ? 'SIGN IN' : 'Sign in';

  const messages = {
    usernameOrEmail: {
      id: 'loginForm.userName',
      defaultMessage: 'Username or email',
    },
    password: {
      id: 'loginForm.password',
      defaultMessage: 'Password',
    },
    signin: {
      id: 'loginForm.signin',
      defaultMessage: signInDefaultMessage,
    },
    passwordlessSignin: {
      id: 'loginForm.passwordlessSignin',
      defaultMessage: 'Sign in via Email',
    },
    or: {
      id: 'loginForm.or',
      defaultMessage: 'or',
    },
    useSSO: {
      id: 'loginForm.useSSO',
      defaultMessage: 'Sign in using SSO',
    },
    loginWithPasswordless: {
      id: 'loginForm.loginWithPassword',
      defaultMessage: 'We’ll email you a link for a passwordless sign in.',
    },
    loginWithPasswordRadio: {
      id: 'loginForm.loginWithPasswordRadio',
      defaultMessage: 'Password',
    },
    loginWithoutPasswordRadio: {
      id: 'loginForm.loginWithoutPasswordRadio',
      defaultMessage: 'Passwordless',
    },
    sendSuccess: {
      id: 'passwordlessForm.sendSuccess',
      defaultMessage:
        'An email will be sent to you if "{username}" is a recognized email or username',
    },
    lifeMobileAppsTOU: {
      id: 'laPreAuthConsent.lifeMobileAppsTOU',
      defaultMessage: 'LIFE Mobile Applications Terms of Use',
    },
    privacyPolicy: {
      id: 'laPreAuthConsent.privacyPolicy',
      defaultMessage: 'Privacy Policy',
    },
  };

  return messages;
};

const getSsoLinkStyles = (theme: Theme, isPhcLogin: boolean) => {
  return isPhcLogin
    ? {}
    : {
        border: 'solid 1px ' + theme.palette.primary.dark,
        borderRadius: theme.pxToRem(24),
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.button.fontSize,
        height: theme.pxToRem(35),
        letterSpacing: theme.pxToRem(0.5),
        padding: theme.spacing(1, 0),
        width: '100%',
        '&:hover': {
          color: theme.palette.primary[900],
          borderColor: theme.palette.primary[900],
          textDecoration: 'none',
        },
      };
};

export const testIds = {
  username: 'loginForm-username',
  password: 'loginForm-password',
  submitButton: 'loginForm-submitButton',
  passwordToggle: 'loginForm-passwordToggle',
  passwordlessToggle: 'loginForm-passwordlessToggle',
};

export type FormValues = {
  usernameOrEmail: string;
  password: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  loginButton: {
    height: theme.pxToRem(40),
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  lifeologyButton: {
    borderRadius: theme.pxToRem(20),
    backgroundColor: 'none',
    backgroundImage: ologyColors.btnGradient,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundImage: ologyColors.btnGradientHover,
    },
    '&:disabled': {
      backgroundImage: ologyColors.btnGradient,
      opacity: 0.65,
    },
  },
  customThemeSignInButton: ({ customTheme }: SharedLoginFormProps) =>
    customThemeButtonStyles(customTheme),
  criteria: {
    marginTop: theme.spacing(1),
  },
  radioBtn: {
    flex: 1,
  },
  lifeologyRadioMinBtn: {
    '& input:checked + div label > p': {
      color: theme.palette.text.primary,
    },
  },
  customThemeRadioMinBtn: ({ customTheme }: SharedLoginFormProps) => ({
    '& input:checked + div label > p': {
      color: customTheme?.textOnPrimaryColor,
    },
    '& input:checked + div::before': {
      backgroundColor: customTheme?.primaryColor,
    },
  }),
  loginWithPasswordless: {
    color: theme.palette.text.hint,
    margin: theme.spacing(0, 3, 1),
    textAlign: 'center',
  },
  collapseContainer: {
    '&.MuiCollapse-hidden': {
      marginBottom: 0,
    },
  },
  orBox: {
    marginBottom: theme.spacing(2),
    '& hr': {
      flex: 1,
      margin: 0,
    },
  },
  or: {
    color: theme.palette.text.hint,
    padding: theme.spacing(0, 2),
  },
  nonPhcSsoLink: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  ssoLink: ({ isPhcLogin }: SharedLoginFormProps) =>
    getSsoLinkStyles(theme, isPhcLogin),
}));

export type LoginFormClasses = GetClasses<typeof useStyles>;

export type LoginFormOwnProps = {};

export type LoginFormProps = LoginFormOwnProps;

export interface LoginAppProps {
  isPhcLogin: boolean;
  customTheme?: CustomTheme;
}

export type SharedLoginFormProps = LoginFormProps & LoginAppProps;

const initialValues: FormValues = {
  usernameOrEmail: '',
  password: '',
};

const getInitialErrors = (
  validator: (value: any) => string
): FormikErrors<FormValues> => {
  return {
    usernameOrEmail: validator(initialValues.usernameOrEmail),
    password: validator(initialValues.password),
  };
};

const LoginForm = (props: LoginFormProps) => {
  const formatMessage = useIntl().formatMessage;
  const loginApp = useLoginApp();
  const isPhcLogin = loginApp === LoginApps.phc;
  const isSkillSpringLogin = loginApp === LoginApps.skillspring;
  const isLifeologyLogin = loginApp === LoginApps.lifeology;
  const isMarketplaceLogin = loginApp === LoginApps.marketplace;

  const customTheme = useCustomTheme();

  const customProps = { ...props, isPhcLogin, customTheme };
  const classes = useStyles(customProps);
  const validators = useValidators();
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);
  const app = useSelector(loginSelectors.selectApp);
  const destination = useSelector(loginSelectors.selectDestination);
  const dispatch = useDispatch();
  const clientId =
    useSelector(loginSelectors.selectCustomClientId) || config.oauthClientId;
  const [sendEmailRequestState, sendEmail] = usePasswordlessEndpoint();
  const [isPasswordSignIn, setIsPasswordSignIn] = useState<boolean>(false);

  const [loginRequestState, loginUser] = useLoginEndpoint();

  const getSubdomain = () => {
    const parts = window.location.host.split('.');
    return parts.length > 1 && parts[0] !== loginApp ? parts.shift() : '';
  };
  const getRedirectDomain = () =>
    `${window.location.protocol}//${window.location.host}`;
  const subdomain = getSubdomain();
  const redirectDomain =
    process.env.NODE_ENV !== 'production' ? getRedirectDomain() : undefined;
  const onSubmit = React.useCallback(
    (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      const { usernameOrEmail } = trimWhitespaceFromForm(formValues);
      const { password } = formValues;
      const shouldUseCookies = isMarketplaceLogin || isPhcLogin;
      const preRequestTime = new Date();
      if (isPasswordSignIn) {
        loginUser(
          {
            username: usernameOrEmail,
            password,
            issueCookies: shouldUseCookies ? true : undefined,
            cookieDomain: shouldUseCookies ? config.cookieDomain : undefined,
            app,
            destination,
          },
          (axiosResponse: AxiosResponse<LoginResponse>) => {
            handleAuthResponse(
              axiosResponse.data,
              preRequestTime,
              null,
              originalUrl,
              clientId
            );
          }
        );
      } else {
        sendEmail(
          usernameOrEmail,
          (_axiosResponse: AxiosResponse<PasswordlessResponse>) => {
            setPasswordlessAuth({
              session: _axiosResponse.data.session,
              originalUrl,
              username: usernameOrEmail,
            });
            dispatch(
              push(
                urls.app.roots.passwordlessLogin(
                  customTheme ? { theme: JSON.stringify(customTheme) } : {}
                )
              )
            );
            dispatch(
              notificationsActions.addNotification(
                formatMessage(messages.sendSuccess, {
                  username: usernameOrEmail,
                }),
                { statusType: 'success' }
              )
            );
          }
        );
      }
      actions.setSubmitting(false);
    },
    [loginUser, originalUrl, clientId, isMarketplaceLogin, isPhcLogin]
  );

  const handlePasswordCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPasswordSignIn(e.target.value === 'password');
  };

  const messages = getMessages(loginApp);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialErrors={getInitialErrors(validators.required)}
    >
      {(formikProps: FormikProps<FormValues>) => (
        <Form>
          <FormBox>
            <Field
              {...getTestProps(testIds.username)}
              aria-label="Username or email"
              fullWidth
              name="usernameOrEmail"
              component={FormikTextField}
              placeholder={formatMessage(messages.usernameOrEmail)}
              validate={validators.required}
              startAdornment={<User aria-hidden />}
            />
            <RadioGroupMinimal
              aria-label="Password Selector"
              name="passwordSelector"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePasswordCheck(e)
              }
              value={'passwordless'}
              fullWidth
            >
              <Radio
                className={clsx(
                  classes.radioBtn,
                  customTheme && classes.customThemeRadioMinBtn,
                  isLifeologyLogin && classes.lifeologyRadioMinBtn
                )}
                {...getTestProps(testIds.passwordlessToggle)}
                value="passwordless"
                label={formatMessage(messages.loginWithoutPasswordRadio)}
              />
              <Radio
                className={clsx(
                  classes.radioBtn,
                  customTheme && classes.customThemeRadioMinBtn,
                  isLifeologyLogin && classes.lifeologyRadioMinBtn
                )}
                {...getTestProps(testIds.passwordToggle)}
                value="password"
                label={formatMessage(messages.loginWithPasswordRadio)}
              />
            </RadioGroupMinimal>
            <Collapse
              in={!isPasswordSignIn}
              className={classes.collapseContainer}
            >
              <Fade in={!isPasswordSignIn}>
                <Text className={classes.loginWithPasswordless} size="caption">
                  {formatMessage(messages.loginWithPasswordless)}
                </Text>
              </Fade>
            </Collapse>
            <Collapse
              in={isPasswordSignIn}
              className={classes.collapseContainer}
            >
              <Fade in={isPasswordSignIn}>
                <div>
                  <Field
                    {...getTestProps(testIds.password)}
                    aria-label="Password"
                    fullWidth
                    name="password"
                    component={FormikTextField}
                    placeholder={formatMessage(messages.password)}
                    validate={isPasswordSignIn && validators.required}
                    type="password"
                    startAdornment={<Lock aria-hidden />}
                  />
                </div>
              </Fade>
            </Collapse>
            <Button
              {...getTestProps(testIds.submitButton)}
              className={clsx(
                classes.loginButton,
                customTheme && classes.customThemeSignInButton,
                isLifeologyLogin && classes.lifeologyButton
              )}
              icon={isPasswordSignIn ? Login : Mail}
              disabled={
                sendEmailRequestState.isLoading ||
                loginRequestState.isLoading ||
                formikProps.isSubmitting ||
                !formikProps.isValid
              }
              type="submit"
              variant="contained"
            >
              {formatMessage(
                isPasswordSignIn ? messages.signin : messages.passwordlessSignin
              )}
            </Button>
          </FormBox>
          {!isPhcLogin && !isSkillSpringLogin && subdomain && (
            <Box direction="column" align="center" marginTop={2}>
              <Box className={classes.orBox} align="center" fullWidth>
                <Divider />
                <Text className={classes.or}>{formatMessage(messages.or)}</Text>
                <Divider />
              </Box>
              <a
                className={clsx(
                  classes.ssoLink,
                  !isPhcLogin ? classes.nonPhcSsoLink : undefined
                )}
                href={
                  subdomain === appBaseSubdomains[loginApp]
                    ? `/login${urls.app.roots.ssoRedirect}${window.location.search}`
                    : urls.api.auth.cognito.login(
                        subdomain,
                        redirectDomain,
                        null,
                        'admin'
                      )
                }
              >
                {formatMessage(messages.useSSO)}
              </a>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(LoginForm);
