import * as React from 'react';

const SvgLifeology = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgLifeology"
    {...props}
  >
    <path
      d="M14.6 20.6a7.806 7.806 0 01-1.275.312A7.207 7.207 0 0112.2 21c-3.4 0-5.525-1.275-6.8-2.863A7.885 7.885 0 013.7 13.4V7.3a4.89 4.89 0 01.85-2.75A3.765 3.765 0 017.8 3a3.645 3.645 0 013.162 1.55A4.966 4.966 0 0111.8 7.3v10c0 .15 0 .3.013.45a1.884 1.884 0 00.087.45 3.2 3.2 0 00.788 1.612 2.532 2.532 0 001.912.788zm0 0a8.543 8.543 0 003.75-2.287A7.723 7.723 0 0020.3 13.4m-8.5-2.8l2.85-1.9 2.85-1.9-3.25-1.1L11 4.6m1 9.972l8.3-5.1V13.4L16.15 16 12 18.6M7.747 6.9v-.009"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgLifeology;
