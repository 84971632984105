import React from 'react';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';
import LifeologySignupContainer from './LifeologySignupContainer';
import { PhcSignupContainer } from './PhcSignupContainer';

const NoSignUpContainer: React.FC = () => {
  return null;
};

export const appSignupContainers: Record<
  LoginApps,
  React.FC<SignupContainerProps>
> = {
  [LoginApps.phc]: PhcSignupContainer,
  [LoginApps.lifeology]: LifeologySignupContainer,
  [LoginApps.marketplace]: NoSignUpContainer,
  [LoginApps.skillspring]: PhcSignupContainer,
};

export type SignupContainerProps = { enableAutoSignUp?: boolean };

const SignupContainer = (props: SignupContainerProps) => {
  const loginApp = useLoginApp();

  return appSignupContainers[loginApp](props);
};

export default React.memo(SignupContainer);
