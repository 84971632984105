import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import VerifyPasswordlessForm from './VerifyPasswordlessForm';
import AuthPromptPaper from '../common/AuthPromptPaper';
import PromptHeader from '../common/PromptHeader';
import { Text } from '@lifeomic/chroma-react/components/Text';

const messages = {
  confirmUser: {
    id: 'verification.confirmUser',
    defaultMessage: 'Verifying User',
  },
  confirmUserText: {
    id: 'verification.confirmUserText',
    defaultMessage: 'Please wait while we confirm your access . . .',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  prompt: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  contentContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emailIndicator: {
    marginTop: theme.spacing(4),
  },
  emailDisplay: {
    marginBottom: theme.spacing(4),
    wordBreak: 'break-all',
    maxWidth: '100%',
  },
  subHeader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: theme.palette.black[500],
    textAlign: 'center',
  },
}));

const VerifyPasswordless = () => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles({});

  return (
    <AuthPromptPaper className={classes.prompt}>
      <div className={classes.contentContainer}>
        <PromptHeader>{formatMessage(messages.confirmUser)}</PromptHeader>
        <Text className={classes.subHeader}>
          {formatMessage(messages.confirmUserText)}
        </Text>
        <VerifyPasswordlessForm />
      </div>
    </AuthPromptPaper>
  );
};

export default VerifyPasswordless;
