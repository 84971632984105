import * as React from 'react';

const SvgFolderMinus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgFolderMinus"
    {...props}
  >
    <path
      d="M10.8 6.4h0a1.538 1.538 0 00-1.5-1.5H4a.945.945 0 00-1 .887A1.065 1.065 0 003 5.9v12.2a.945.945 0 00.887 1 1.065 1.065 0 00.113 0h16a.945.945 0 001-.887 1.065 1.065 0 000-.113V8.9a.945.945 0 00-.887-1 1.065 1.065 0 00-.113 0h-7.7a1.48 1.48 0 01-1.088-.45A1.516 1.516 0 0110.8 6.4zm-.623 6.948h3.646"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgFolderMinus;
