import * as React from 'react';

const SvgCloudSnow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCloudSnow"
    {...props}
  >
    <path
      d="M20.201999999999998 14.215A3.677 3.677 0 0018.894 8.6 5.551 5.551 0 107.88 7.2a4.158 4.158 0 00-.645-.065 4.234 4.234 0 00-3.094 7.126m4.045 4.45v.189m0-4.078v.188m3.788 5.09v.227m0-3.958v.19m3.814 2.155v.186m0-4.172v.188"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCloudSnow;
