import * as React from 'react';

const SvgVolume2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgVolume2"
    {...props}
  >
    <path
      d="M18.089 4.532a10.112 10.112 0 01-.229 14.461m-3.572-3.571a5.058 5.058 0 00.237-7.322M11.29 6.9c0-.859-.443-1.016-.985-.349L8.9 8.282a3.726 3.726 0 01-2.545 1.213H4.568a1.567 1.567 0 00-1.562 1.563v1.884a1.567 1.567 0 001.562 1.563h1.787A3.726 3.726 0 018.9 15.718l1.4 1.728c.542.667.985.51.985-.349z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgVolume2;
