import * as React from 'react';

const SvgHelix2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    width={24}
    height={24}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    viewBox="0 0 24 24"
    data-icon="SvgHelix2"
    {...props}
  >
    <path d="M7.5 3.5a8.492 8.492 0 001.4 5.3M16 3.5c0 2.6-1.6 5.5-3.8 7.7S7.1 15 7.1 20m7.2-5.3a9.654 9.654 0 011.7 5.8" />
  </svg>
);

export default SvgHelix2;
