import * as React from 'react';

const SvgCloudDrizzle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCloudDrizzle"
    {...props}
  >
    <path
      d="M20.2 14.925a3.677 3.677 0 00-1.308-5.612A5.551 5.551 0 107.88 7.919a4.158 4.158 0 00-.645-.065 4.234 4.234 0 00-3.094 7.126M8.2 18v1.614m0-6.966v2m3.788 4.738V21m0-6.966v2M15.8 18v1.614m0-6.966v2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCloudDrizzle;
