import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import clsx from 'clsx';
import Grid, { GridProps } from '@mui/material/Grid';
import { Text } from '@lifeomic/chroma-react/components/Text';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(8),
  },
  dot: {
    height: theme.pxToRem(12),
    width: theme.pxToRem(12),
    border: 'solid 1px',
    borderRadius: theme.pxToRem(4),
    marginRight: theme.spacing(1),
  },
  dotValid: {
    borderColor: theme.palette.primary.main,
    position: 'relative',
    '&::before': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.pxToRem(2),
      content: `''`,
      position: 'absolute',
      width: theme.pxToRem(6),
      height: theme.pxToRem(6),
      left: theme.pxToRem(2),
      top: theme.pxToRem(2),
    },
  },
  dotInvalid: {
    borderColor: theme.palette.text.secondary,
  },
  textValid: {
    textDecoration: 'line-through',
  },
  caption: {
    color: theme.palette.text.secondary,
    letterSpacing: 'normal',
  },
}));

export type CriteriaIndicatorClasses = GetClasses<typeof useStyles>;

export interface CriteriaIndicatorOwnProps {
  label: string;
  isCriteriaMet: boolean;
}

export type CriteriaIndicatorProps = GridProps & CriteriaIndicatorOwnProps;

const CriteriaIndicator = (props: CriteriaIndicatorProps) => {
  const classes = useStyles(props);

  const { isCriteriaMet, label, className, ...rest } = props;

  const dotValidityClass = isCriteriaMet
    ? classes.dotValid
    : classes.dotInvalid;
  const textValidityClass = isCriteriaMet ? classes.textValid : undefined;

  return (
    <Grid {...rest} item className={clsx(classes.root, className)}>
      <span className={clsx(classes.dot, dotValidityClass)} />
      <Text size="caption" className={clsx(textValidityClass, classes.caption)}>
        {label}
      </Text>
    </Grid>
  );
};

export default React.memo(CriteriaIndicator);
