import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Text, TextProps } from '@lifeomic/chroma-react/components/Text';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
}));

export type PromptHeaderClasses = GetClasses<typeof useStyles>;

export type PromptHeaderOwnProps = {};

export type PromptHeaderProps = PromptHeaderOwnProps & TextProps;

const PromptHeader = ({ children, ...textProps }: PromptHeaderProps) => (
  <Text {...textProps} size="headline">
    {children}
  </Text>
);

export default React.memo(PromptHeader);
