import useAPI, { RequestConfig } from './useApi';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import urls from '../../util/urls';
import { useIntl } from 'react-intl';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthResponse } from '../../util/handleAuthResponse';
import { loginSelectors } from '../../redux/modules/Login';
import config from '../../util/config';

const messages = {
  apiErrorMessage: {
    id: 'verificationEndpoint.apiErrorMessage',
    defaultMessage: 'An error occurred when signing up user.  Error: "{error}"',
  },
  genericError: {
    id: 'verificationEndpoint.genericError',
    defaultMessage: 'An error occurred when verifying code.',
  },
};

export type CodeConfirmationResponse = AuthResponse & {
  originalUrl: string;
};

function useVerificationEndpoint(providedUsername?: string) {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);
  const username =
    useSelector(loginSelectors.selectSignupUsername) || providedUsername;

  const clientId = customClientId || config.oauthClientId;

  const onFailure = (error: AxiosError) => {
    const errorMessage = get(error, 'response.data.error', null) as string;

    const notificationMessage = errorMessage
      ? formatMessage(messages.apiErrorMessage, { error: errorMessage })
      : formatMessage(messages.genericError);

    dispatch(
      notificationsActions.addNotification(notificationMessage, {
        statusType: 'error',
      })
    );
  };

  const makeAPIRequest = (
    {
      code,
      issueCookies,
      cookieDomain,
    }: { code: string; issueCookies: boolean; cookieDomain: boolean },
    onSuccess: (axiosResponse: AxiosResponse<CodeConfirmationResponse>) => void
  ): RequestConfig => ({
    url: urls.api.auth.confirm,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      username,
      code,
      clientId,
      issueCookies,
      cookieDomain,
    },
    onSuccess,
    onFailure,
  });

  return useAPI(makeAPIRequest);
}

export default useVerificationEndpoint;
