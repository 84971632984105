import * as React from 'react';

export const Apple = (props: any) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23.673,13.236A4.778,4.778,0,0,0,24.79,9.809,4.836,4.836,0,0,0,21.6,11.444a4.475,4.475,0,0,0-1.144,3.294A4.036,4.036,0,0,0,23.673,13.236Zm1.1,1.738c-1.778-.105-3.289,1-4.138,1s-2.15-.946-3.556-.921a5.25,5.25,0,0,0-4.457,2.681c-1.91,3.259-.5,8.094,1.353,10.749.9,1.314,1.99,2.76,3.422,2.708,1.354-.053,1.884-.868,3.529-.868s2.122.868,3.555.842c1.486-.027,2.415-1.314,3.317-2.629a11.622,11.622,0,0,0,1.485-3.022A4.782,4.782,0,0,1,26.4,21.176,4.862,4.862,0,0,1,28.729,17.1,5.1,5.1,0,0,0,24.776,14.974Z" />
    </svg>
  );
};
