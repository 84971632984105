import * as React from 'react';

const SvgZapOff = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgZapOff"
    {...props}
  >
    <path
      d="M12.456 6.6l.45-3.6m5.431 9.323l1.769-2.123h-4.05m-7.85-1.5l-2.15 2.55-2.15 2.55h8.1l-.45 3.6-.45 3.6 4.5-5.4M12.906 3l-1.744 2.118M3 3.012L21.008 21"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgZapOff;
