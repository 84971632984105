import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { alpha } from '@mui/material';
import clsx from 'clsx';
import { DotLoader } from '@lifeomic/chroma-react/components/DotLoader';
import SignupContainer from '../../common/SignupContainer';
import SignInContainer from '../SignInContainer';
import { theme } from '@lifeomic/phc-web-toolkit/dist/theme/phc';
import { ContentContainerProps } from '.';
import { SEA_GREEN } from '@lifeomic/phc-web-toolkit/dist/theme/phc/styles/content';
import { Paper } from '@lifeomic/chroma-react/components/Paper';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    background:
      'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.25))',
    boxShadow: theme.boxShadows.header,
    animation: '$fadeInDownBig 1s',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.pxToRem(680),
    borderRadius: theme.pxToRem(10),
    overflow: 'hidden',
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: 0,
  },
  loadingRoot: {
    background: alpha(theme.palette.common.white, 0.9),
    height: theme.pxToRem(530),
  },
  '@keyframes fadeInDownBig': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  platformDots: {
    width: theme.pxToRem(100),
    '& > circle': {
      '&:nth-child(1)': {
        fill: theme.palette.blue.main,
      },
      '&:nth-child(2)': {
        fill: '#3170a8',
      },
      '&:nth-child(3)': {
        fill: '#437b79',
      },
      '&:nth-child(4)': {
        fill: SEA_GREEN,
      },
    },
  },
}));

export type ContentContainerClasses = GetClasses<typeof useStyles>;

const PhcContentContainer = (props: ContentContainerProps) => {
  const classes = useStyles(props);

  const content = props.isInitializing ? (
    <DotLoader className={classes.platformDots} />
  ) : (
    <>
      <SignInContainer />
      <SignupContainer />
    </>
  );

  return (
    <Paper
      className={clsx(
        classes.root,
        props.isInitializing && classes.loadingRoot
      )}
    >
      {content}
    </Paper>
  );
};

export default PhcContentContainer;
