import useAPI, { RequestConfig } from './useApi';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import { AuthResponse } from '../../util/handleAuthResponse';
import { appErrorsActions } from '../../redux/modules/AppErrors';
import urls from '../../util/urls';
import { useIntl, MessageDescriptor } from 'react-intl';
import { AxiosError, AxiosResponse } from 'axios';
import config from '../../util/config';
import { loginSelectors } from '../../redux/modules/Login';

const messages = {
  apiErrorMessage: {
    id: 'loginEndpoint.apiErrorMessage',
    defaultMessage: 'An error occurred when signing in user.  Error: "{error}"',
  },
  genericError: {
    id: 'loginEndpoint.genericError',
    defaultMessage: 'An error occurred when signing in user.',
  },
  incorrectCredsNotification: {
    id: 'loginEndpoint.incorrectCredsNotification',
    defaultMessage: 'Incorrect username/email or password',
  },
};

export type LoginResponse = AuthResponse;

interface UseLoginEndpointOptions {
  username: string;
  password: string;
  issueCookies?: boolean;
  cookieDomain?: string;
  app?: string;
  destination?: string;
}

function useLoginEndpoint() {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);

  const clientId = customClientId || config.oauthClientId;

  if (!clientId) {
    const error = new Error(
      'A client ID could not be found in environment configuration'
    );
    dispatch(appErrorsActions.registerError(error));
  }

  const onFailure = (error: AxiosError) => {
    const errorCode = get(error, 'response.data.code', null);
    const errorMessage = get(error, 'response.data.error', null) as string;

    const notify = (intlMessage: MessageDescriptor, values?: any) => {
      dispatch(
        notificationsActions.addNotification(
          formatMessage(intlMessage, values),
          { statusType: 'error' }
        )
      );
    };

    if (errorCode === 'NotAuthorizedException') {
      notify(messages.incorrectCredsNotification);
    } else if (errorMessage) {
      notify(messages.apiErrorMessage, { error: errorMessage });
    } else {
      notify(messages.genericError);
    }
  };

  const makeAPIRequest = (
    {
      username,
      password,
      issueCookies,
      cookieDomain,
      app,
      destination,
    }: UseLoginEndpointOptions,
    onSuccess: (axiosResponse: AxiosResponse<LoginResponse>) => void
  ): RequestConfig => ({
    url: urls.api.auth.login,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      username,
      password,
      issueCookies,
      cookieDomain,
      clientId,
      app,
      destination,
    },
    onSuccess,
    onFailure,
  });

  return useAPI(makeAPIRequest);
}

export default useLoginEndpoint;
