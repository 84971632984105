import React from 'react';
import { useIntl } from 'react-intl';
import Grid, { GridProps } from '@mui/material/Grid';
import CriteriaIndicator from './CriteriaIndicator';
import { validationChecks } from '../../../hooks/useValidatiors';

const messages = {
  oneLowerCase: {
    id: 'criteria.oneLowerCase',
    defaultMessage: 'One lowercase letter',
  },
  oneUpperCase: {
    id: 'criteria.oneUpperCase',
    defaultMessage: 'One uppercase letter',
  },
  oneSpecialCharacter: {
    id: 'criteria.oneSpecialCharacter',
    defaultMessage: 'One special character',
  },
  oneNumber: {
    id: 'criteria.oneNumber',
    defaultMessage: 'One number',
  },
  min8Chars: {
    id: 'criteria.min8Chars',
    defaultMessage: 'At least 8 characters',
  },
};

export interface PasswordCriteriaOwnProps {
  passwordValue: string;
}

export type PasswordCriteriaProps = PasswordCriteriaOwnProps & GridProps;

const PasswordCriteria = (props: PasswordCriteriaProps) => {
  const formatMessage = useIntl().formatMessage;

  const { passwordValue, ...rest } = props;

  return (
    <Grid {...rest} container>
      <CriteriaIndicator
        xs={12}
        label={formatMessage(messages.oneLowerCase)}
        isCriteriaMet={
          !!passwordValue && validationChecks.oneLowerCaseLetter(passwordValue)
        }
      />
      <CriteriaIndicator
        xs={12}
        label={formatMessage(messages.oneNumber)}
        isCriteriaMet={
          !!passwordValue && validationChecks.oneNumber(passwordValue)
        }
      />
      <CriteriaIndicator
        xs={12}
        label={formatMessage(messages.oneUpperCase)}
        isCriteriaMet={
          !!passwordValue && validationChecks.oneUpperCaseLetter(passwordValue)
        }
      />
      <CriteriaIndicator
        xs={12}
        label={formatMessage(messages.min8Chars)}
        isCriteriaMet={
          !!passwordValue && validationChecks.min8Chars(passwordValue)
        }
      />
      <CriteriaIndicator
        xs={12}
        label={formatMessage(messages.oneSpecialCharacter)}
        isCriteriaMet={
          !!passwordValue && validationChecks.oneSpecialCharacter(passwordValue)
        }
      />
    </Grid>
  );
};

export default React.memo(PasswordCriteria);
