import lifeologyGreen from './colors/lifeologyGreen';
import lifeologyTeal from './colors/lifeologyTeal';
import { createPalette } from '@lifeomic/chroma-react/styles';

export const palette = createPalette({
  primary: lifeologyTeal,
  secondary: lifeologyGreen,
  background: {
    paper: '#fff',
    default: '#f5f8fa',
  },
});
