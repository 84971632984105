import useAPI, { RequestConfig } from './useApi';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import urls from '../../util/urls';
import { useIntl } from 'react-intl';
import { AxiosError, AxiosResponse } from 'axios';
import config from '../../util/config';
import { AuthResponse } from '../../util/handleAuthResponse';
import { push } from 'connected-react-router';
import { loginSelectors } from '../../redux/modules/Login';

const messages = {
  errorMessage: {
    id: 'passwordlessEndpoint.errorMessage',
    defaultMessage: 'An error occurred when verifying credentials',
  },
};

function usePasswordlessVerificationEndpoint(
  session: string,
  username: string
) {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);

  const clientId = customClientId || config.oauthClientId;
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('challengeCode');
  // retryCode signals that the user entered a challenge code manually (as opposed to clicking the link in their email)
  const retryCode = urlParams.get('retryCode');

  const onFailure = (error: AxiosError) => {
    const errorMessage = error?.response?.data?.error;
    if (errorMessage) {
      console.error(`Error: ${errorMessage}`);
    }

    const invalidChallengeCode =
      errorMessage === 'Request failed with status code 404';
    if (retryCode && invalidChallengeCode) {
      dispatch(push(urls.app.roots.passwordlessLogin({ incorrectCode: true })));
    }

    const notificationMessage = formatMessage(messages.errorMessage);

    dispatch(
      notificationsActions.addNotification(notificationMessage, {
        statusType: 'error',
      })
    );
  };

  const makeAPIRequest = (
    {
      issueCookies,
      cookieDomain,
      app,
      destination,
    }: {
      issueCookies: boolean;
      cookieDomain: string;
      app?: string;
      destination?: string;
    },
    onSuccess: (axiosResponse: AxiosResponse<AuthResponse>) => void
  ): RequestConfig => ({
    url: urls.api.auth.verifyPasswordlessAuth,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      username,
      clientId,
      session,
      code,
      issueCookies,
      cookieDomain,
      app,
      destination,
    },
    onSuccess,
    onFailure,
  });

  return useAPI(makeAPIRequest);
}

export default usePasswordlessVerificationEndpoint;
