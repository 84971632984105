import * as React from 'react';

const SvgWatch = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgWatch"
    {...props}
  >
    <path
      d="M7.9 8.6a5.717 5.717 0 011.787-1.45 5.137 5.137 0 014.625 0A5.71 5.71 0 0116.1 8.6m0 6.8a4.587 4.587 0 00.9-1.55 5.628 5.628 0 00.3-1.85 5.084 5.084 0 00-.337-1.812A7.265 7.265 0 0016.1 8.6m0 6.8a5.71 5.71 0 01-1.788 1.45 5.137 5.137 0 01-4.625 0A5.717 5.717 0 017.9 15.4m0-6.8a5.453 5.453 0 00-.9 1.588A5.349 5.349 0 006.7 12a5.084 5.084 0 00.337 1.812A7.265 7.265 0 007.9 15.4m0-6.8l.25-1.95.25-1.95a2.057 2.057 0 01.537-1.225A1.474 1.474 0 0110 3h4a1.627 1.627 0 011.112.475A1.96 1.96 0 0115.7 4.7l.25 1.95.25 1.95m-.1 6.8l-.25 1.95-.25 1.95a1.993 1.993 0 01-.55 1.225A1.6 1.6 0 0113.9 21H10a1.632 1.632 0 01-1.113-.475A1.964 1.964 0 018.3 19.3l-.25-1.95-.25-1.95M12 10v2l.85.6.85.6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgWatch;
