import deepFreeze from 'deep-freeze-strict';
import queryString from 'query-string';
import _isEmpty from 'lodash/isEmpty';
import { window } from './globals';

type Id = string | number;

export interface FhirUrlOptions {
  account: string;
  id?: Id;
  params?: any;
  isPostQuery?: boolean;
}

export interface EvaluatedPolicyUrlOptions {
  user?: string;
}

const encodedOrigin = encodeURIComponent(window.location.origin);

export interface EvaluatedPolicyParams {
  user?: string;
}

export type PatientFilesParams = {
  cohort: string;
} & object;

const urls = {
  oauth: {
    token: () => '/api/v1/oauth/token',
  },
  api: {
    accounts: {
      accounts: () => '/api/v1/accounts',
      logo: (id: Id) => `/api/v1/accounts/${id}/logo`,
      account: (id: Id) => `/api/v1/accounts/${id}`,
      groups: ({ id, params }: { id?: Id; params?: any } = {}) =>
        `/api/v1/account/groups${id ? '/' + id : ''}${stringifyParams(params)}`,
      groupMembership: (groupId: Id, params: object) =>
        `/api/v1/account/groups/${groupId}/members${stringifyParams(params)}`,
      groupMembershipAddDelete: (groupId: Id, userId: Id) =>
        `/api/v1/account/groups/${groupId}/members/${userId}`,
      reseller: {
        managedAccounts: () => '/api/v1/account-management/managed-accounts',
        managedAccountDetails: (id: Id) =>
          `/api/v1/account-management/managed-accounts/${id}`,
        tiles: () => '/api/v1/account-management/app-tiles',
      },
    },
    export: {
      insights: () => '/api/v1/data-export/insights',
    },
    wearables: (params: object, id?: Id) =>
      `/api/v1/wearables${id ? '/' + id : ''}${stringifyParams(params)}`,
    oauth: () => '/v1/oauth',
    products: () => '/api/v1/billing/products',
    checkoutPages: (creationToken?: string) =>
      `/api/v1/billing/checkout-pages${
        creationToken ? '/' + creationToken : ''
      }?uiVersion=${encodedOrigin}`,
    paymentMethodPages: () =>
      `/api/v1/billing/payment-method-pages?uiVersion=${encodedOrigin}`,
    usages: () => '/api/v1/usages',
    usageReports: (relativeUri: string, params = {}) =>
      `/api${relativeUri}${stringifyParams(params)}`,
    accountAuditing: (params: Record<string, unknown>) =>
      `/api/v1/audit/events${stringifyParams(params)}`,
    invoices: (id?: Id) => `/api/v1/invoices${id ? '/' + id : ''}`,
    customApps: () => '/api/v1/oauth-apps?appType=launched',
    ocrAggregation: {
      classificationAggregation: (projectId: Id, subjectId: Id) =>
        `/api/v1/ocr-aggregate/classify/projects/${projectId}/subjects/${subjectId}`,
      indexProject: (project: Id) =>
        `/api/v1/ocr-aggregate/ontology-index/projects/${project}`,
      generateCSV: (project: Id) =>
        `/api/v1/ocr-aggregate/ontology-index/projects/${project}/csv`,
      lastIndexDate: (project: Id) =>
        `/api/v1/ocr-aggregate/ontology-index/projects/${project}/indexed/date`,
      quickSearch: (project: Id) =>
        `/api/v1/ocr-aggregate/ontology-index/projects/${project}/quick-search`,
      patientAssignment: (projectId: Id, docRefId: Id) =>
        `/api/v1/ocr-aggregate/patient-assignment/projects/${projectId}/documents/${docRefId}`,
      documentDuplicates: (projectId: Id, docRefId: Id, params = {}) =>
        `/api/v1/ocr-aggregate/duplicates/projects/${projectId}/documentReferences/${docRefId}${stringifyParams(
          params,
        )}`,
    },
    ocr: {
      projectOntologies: (project: Id) =>
        `/api/v1/ocr/documents/projects/${project}/ontologies`,
      updateOntologies: (project: Id, fileId: string) =>
        `/api/v1/ocr/documents/projects/${project}/files/${fileId}/ontologies`,
      ontologiesTemplate: () => `/api/v1/ocr/documents/ontologies/template`,
      documents: (
        id: Id,
        params: { project?: Id; subjectId?: Id; cohort?: Id },
      ) =>
        `/api/v1/ocr/documents${id ? '/' + id : ''}${stringifyParams(params)}`,
      documentsSync: (
        id: Id,
        params: { project?: Id; subjectId?: Id; cohort?: Id },
      ) =>
        `/api/v1/ocr/documents${id ? '/' + id : ''}/sync${stringifyParams(
          params,
        )}`,
      reingest: (projectId: string, id: Id, params?: object) =>
        `/api/v1/ocr/documents/projects/${projectId}/documentReferences/${id}/reingest${stringifyParams(
          params,
        )}`,
      redact: (projectId: string, id: Id, params?: object) =>
        `/api/v1/ocr/fhir/projects/${projectId}/documentReferences/${id}/redact${stringifyParams(
          params,
        )}`,
      suggestDate: (projectId: string) =>
        `/api/v1/ocr/suggest-date/projects/${projectId}`,
      config: (projectId?: string, params: any = {}) =>
        `/api/v1/ocr/config${projectId ? '/' + projectId : ''}${stringifyParams(
          params,
        )}`,
      documentReference: (projectId: string, id: Id, params?: object) =>
        `/api/v1/ocr/fhir/projects/${projectId}/documentReferences/${id}${stringifyParams(
          params,
        )}`,
      documentReferenceSuggestions: (
        projectId: string,
        id: Id,
        params: any = {},
      ) =>
        `/api/v1/ocr/fhir/projects/${projectId}/documentReferences/${id}/suggestions${stringifyParams(
          params,
        )}`,
      ocrImage: (
        projectId: string,
        documentReferenceId: string,
        page: number,
        params: any = {},
      ) =>
        `/api/v1/ocr/images/projects/${projectId}/documentReferences/${documentReferenceId}/pages/${page}${stringifyParams(
          params,
        )}`,
      ocrSnippet: (
        projectId: string,
        documentReferenceId: string,
        page: number,
        params: any = {},
      ) =>
        `/api/v1/ocr/snippets/projects/${projectId}/documentReferences/${documentReferenceId}/pages/${page}${stringifyParams(
          params,
        )}`,
      reportExtractors: (project: string, extractorId?: string, params?: any) =>
        `/api/v1/ocr/report-extraction/${project}/report-extractors${
          extractorId ? `/${extractorId}` : ''
        }${stringifyParams(params)}`,
      extract: (project: string, docRefId: string, extractorId?: string) =>
        `/api/v1/ocr/report-extraction/${project}/document/${docRefId}/extract${
          extractorId ? `/${extractorId}` : ''
        }`,
      testReportExtractor: (project: string) =>
        `/api/v1/ocr/report-extraction/${project}/report-extractors/test`,
      generateExtractorData: (project: string) =>
        `/api/v1/ocr/report-extraction/${project}/report-extractors/test`,
      analyze: (
        projectId: string,
        documentReferenceId: string,
        params?: object,
      ) =>
        `/api/v1/ocr/fhir/projects/${projectId}/documentReferences/${documentReferenceId}/analyze${stringifyParams(
          params,
        )}`,
      split: (projectId: string, documentReferenceId: string) =>
        `/api/v1/ocr/documents/projects/${projectId}/documentReferences/${documentReferenceId}/split`,
      preprocess: (projectId: string, params?: any) =>
        `/api/v1/ocr/documents/projects/${projectId}/preprocess/file${stringifyParams(
          params,
        )}`,
      preprocessInitialize: (projectId: string, params?: any) =>
        `/api/v1/ocr/documents/projects/${projectId}/preprocess/initialize${stringifyParams(
          params,
        )}`,
      preprocessFile: (projectId: string, fileId: string, params?: any) =>
        `/api/v1/ocr/documents/projects/${projectId}/preprocess/file/${fileId}${stringifyParams(
          params,
        )}`,
      ingestPreprocessedFiles: (projectId: string, params?: any) =>
        `/api/v1/ocr/documents/projects/${projectId}/preprocess/ingest${stringifyParams(
          params,
        )}`,
    },
    rules: (id?: Id, params?: any) =>
      `/api/v1/rules${id ? '/' + id : ''}${stringifyParams(params)}`,
    content: {
      type: (account: string, type: string) =>
        `/api/v1/content/public/${account}?type=${type}`,
      saveDraft: (project: string) => `/api/v1/content/${project}/content`,
      publishDraft: (project: string, id: string) =>
        `/api/v1/content/${project}/content/${id}/publish`,
      archive: (project: string, id: string) =>
        `/api/v1/content/${project}/content/${id}/archive`,
      content: (project: string, id: string) =>
        `/api/v1/content/${project}/content/${id}`,
      v2: {
        create: () => '/api/v2/content/entity',
        update: (id: string) => `/api/v2/content/entity/${id}`,
        delete: (id: string) => `/api/v2/content/entity/${id}`,
        owned: (projectId: string) =>
          `/api/v2/content/entity/owner/project/${projectId}`,
        all: () => `/api/v2/content/entity/user`,
        assignment: () => `/api/v2/content/entity/assignment`,
        listAssignmentsForOwner: (projectId: string) =>
          `/api/v2/content/entity/assignment/owner/project/${projectId}`,
      },
    },
    fhirSearch: {
      projects: (projectId: Id, params?: any) =>
        `/api/v1/fhir-search/projects/${projectId}${stringifyParams(params)}`,
      projectsDistinct: (projectId: Id, params?: any) =>
        `/api/v1/fhir-search/projects/${projectId}/distinct${stringifyParams(
          params,
        )}`,
      cohort: (projectId: Id, cohortId: Id, params?: any) =>
        `/api/v1/fhir-search/projects/${projectId}/cohorts/${cohortId}${stringifyParams(
          params,
        )}`,
      cohortDistinct: (projectId: Id, cohortId: Id, params?: any) =>
        `/api/v1/fhir-search/projects/${projectId}/cohorts/${cohortId}/distinct${stringifyParams(
          params,
        )}`,
      patient: (projectId: Id, patientId: string) =>
        `/api/v1/fhir-search/projects/${projectId}/patients/${patientId}`,
    },
    jobs: {
      jobs: (id?: Id, params?: any) =>
        `/api/v1/rules/jobs${id ? '/' + id : ''}${stringifyParams(params)}`,
    },
    workbookIngest: {
      ingest: (project: string) =>
        `/api/v1/workbook-ingest/projects/${project}/ingest`,
      template: () => `/api/v1/workbook-ingest/template`,
      history: (project: string, params?: any) =>
        `/api/v1/workbook-ingest/projects/${project}/history${stringifyParams(
          params,
        )}`,
      status: (project: string, params?: any) =>
        `/api/v1/workbook-ingest/projects/${project}/status${stringifyParams(
          params,
        )}`,
    },
    notes: {
      get: ({
        projectId,
        patientId,
        dateFrom,
        dateTo,
      }: {
        projectId: Id;
        patientId: Id;
        dateFrom: string;
        dateTo: string;
      }) =>
        `/api/v1/notes/projects/${projectId}/patient/${patientId}?date=gt${dateFrom}&date=lt${dateTo}`,
    },
    trackTile: {
      metrics: (params?: Record<string, unknown>) =>
        `/api/v1/track-tiles/metrics${stringifyParams(params)}`,
      trackers: (params?: Record<string, unknown>) =>
        `/api/v1/track-tiles/metrics/installs${stringifyParams(params)}`,
      tracker: (metricId: string, projectId: string) =>
        `/api/v1/track-tiles/metrics/installs/${metricId}?project=${projectId}`,
    },
    fhir: {
      bundleUpload: ({ account, params }: FhirUrlOptions) =>
        `/fhir/${account}/dstu3${stringifyParams(params)}`,
      baseFhirUrl: (_account: string) => `/api/v1/fhir/dstu3/`,
      condition: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Condition${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      encounter: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Encounter${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      immunization: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Immunization${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      observation: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Observation${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      questionnaire: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Questionnaire${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      questionnaireResponse: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/QuestionnaireResponse${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      patient: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Patient${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      person: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Person${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      procedure: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Procedure${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      goal: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Goal${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      media: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Media${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      procedureRequest: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/ProcedureRequest${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      coverage: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Coverage${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      explanationOfBenefit: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/ExplanationOfBenefit${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      documentReference: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/DocumentReference${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      diagnosticReport: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/DiagnosticReport${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      referralRequest: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/ReferralRequest${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      specimen: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Specimen${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      consent: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Consent${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      imagingStudy: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/ImagingStudy${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      sequence: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/Sequence${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      medicationStatements: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/MedicationStatement${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      medicationAdministrations: ({
        id,
        params,
        isPostQuery,
      }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/MedicationAdministration${
          id ? '/' + id : ''
        }${isPostQuery ? '/_search' : stringifyParams(params)}`;
      },
      medicationRequests: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/MedicationRequest${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      medicationDispense: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/MedicationDispense${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      diagnosticReports: ({ id, params, isPostQuery }: FhirUrlOptions) => {
        return `/api/v1/fhir/dstu3/DiagnosticReport${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`;
      },
      me: () => `/api/v1/fhir/dstu3/$me`,
    },
    surveys: {
      surveys: ({
        id,
        project,
        params,
      }: {
        id?: Id;
        project: Id;
        params?: object;
      }) =>
        `/api/v1/survey/projects/${project}/surveys${
          id ? `/${id}` : ''
        }${stringifyParams(params)}`,
      responses: ({
        id,
        project,
        params,
      }: {
        id?: Id;
        project: Id;
        params?: object;
      }) =>
        `/api/v1/survey/projects/${project}/responses${
          id ? `/${id}` : ''
        }${stringifyParams(params)}`,
      acceptOpenSurveyInvite: (inviteId: Id) =>
        `/api/v1/survey-invitations/accept/${inviteId}`,
      reminder: ({ surveyId, projectId }: { surveyId: Id; projectId: Id }) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/reminder`,
      previewData: (projectId: string, params: object) =>
        `/api/v1/survey/projects/${projectId}/responses/preview-data${stringifyParams(
          params,
        )}`,
      surveyImage: (projectId: string, surveyId: string, imageId?: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/image${
          imageId ? `/${imageId}` : ''
        }`,
      autopopulate: (projectId: string) =>
        `/api/v1/survey/projects/${projectId}/autopopulate`,
      templates: (projectId: string, surveyId: string, templateId?: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/templates${
          templateId ? `/${templateId}` : ''
        }`,
      testTemplate: (projectId: string, surveyId: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/templates/test`,
      responseTemplate: (
        projectId: string,
        surveyId: string,
        templateId: string,
        responseId: string,
      ) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/templates/${templateId}/responses/${responseId}`,
    },
    apiKeys: (params?: object, id?: Id) =>
      `/api/v1/api-keys${id ? '/' + id : ''}${stringifyParams(params)}`,
    graphQL: () => '/api/v1/graphql',
    users: {
      user: () => '/api/v1/user',
      groups: (userId: Id, params: object) =>
        `/api/v1/users/${userId}/groups${stringifyParams(params)}`,
      groupsInAccount: (userId: Id, params: object) =>
        `/api/v1/account/users/${userId}/groups${stringifyParams(params)}`,
      groupMember: (user: Id, group: Id) =>
        `/api/v1/groups/${group}/members/${user}`,
      // Not including api as we are manually setting the base url as apiBaseUri
      picture: (user: Id) => `/v1/users/${user}/picture`,
    },
    fileActions: {
      fileAction: (id?: string, params?: any) =>
        `/api/v1/file-actions${id ? '/' + id : ''}${stringifyParams(params)}`,
      ingestionFileAction: () => '/api/v1/file-actions/ingestion',
      ingest: (projectId: string) =>
        `/api/v1/file-actions/projects/${projectId}/ingestions`,
    },
    genomicIngestion: {
      fileAction: (projectId: string, id?: string, params?: any) =>
        `/api/v1/genomic-ingestion/projects/${projectId}/file-actions${
          id ? '/' + id : ''
        }${stringifyParams(params)}`,
      ingestions: (projectId: string, id?: string, params?: any) =>
        `/api/v1/genomic-ingestion/projects/${projectId}/ingestions${
          id ? '/' + id : ''
        }${stringifyParams(params)}`,
      ingestionPatientInfo: (projectId: string, id: string) =>
        `/api/v1/genomic-ingestion/projects/${projectId}/ingestions/${id}/patient-info`,
    },
    files: {
      patientFile: (
        projectId: string,
        params: object & { cohort?: string; subject?: string },
        id?: Id,
      ) =>
        `/api/v1/fhir/files/projects/${projectId}/files${
          id ? '/' + id : ''
        }${stringifyParams(params)}`,
      listPatientFiles: (
        projectId: string,
        params: object & { cohort: string },
      ) =>
        `/api/v1/fhir/files/projects/${projectId}/list/files${stringifyParams(
          params,
        )}`,
      searchPatientFiles: (
        projectId: string,
        params: object & { cohort: string },
      ) =>
        `/api/v1/fhir/files/projects/${projectId}/search/files${stringifyParams(
          params,
        )}`,
      files: (params?: object, id?: Id) =>
        `/api/v1/files${id ? '/' + id : ''}${stringifyParams(params)}`,
      transferJobs: (params: object, id?: Id) =>
        `/api/v1/files/transfer/jobs${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
      transferJobsRun: (id: Id) => `/api/v1/files/transfer/jobs/${id}/run`,
    },
    clients: {
      clients: (params: object = null, id?: Id) =>
        `/api/v1/clients${id ? '/' + id : ''}${stringifyParams(params)}`,
    },
    ontology: {
      system: (project: string, id: string) =>
        `/api/v1/terminology/projects/${project}/code-systems/${id}`,
      systems: (project: string, params?: object) =>
        `/api/v1/terminology/projects/${project}/code-systems${stringifyParams(
          params,
        )}`,
      localSystems: (project: string, params?: object) =>
        `/api/v1/terminology/projects/${project}/local/code-systems${stringifyParams(
          params,
        )}`,
      associatedSystems: (id: string, params: object) =>
        `/api/v1/terminology/projects/${id}/associations/code-systems/${stringifyParams(
          params,
        )}`,
      valueSet: (project: string, id: string) =>
        `/api/v1/terminology/projects/${project}/value-sets/${id}`,
      valueSets: (project: string, params?: object) =>
        `/api/v1/terminology/projects/${project}/value-sets${stringifyParams(
          params,
        )}`,
      localValueSets: (project: string, params?: object) =>
        `/api/v1/terminology/projects/${project}/local/value-sets${stringifyParams(
          params,
        )}`,
      associatedValueSets: (id: string, params: object) =>
        `/api/v1/terminology/projects/${id}/associations/value-sets/${stringifyParams(
          params,
        )}`,
      codeSystemCodings: (
        projectId: string,
        systemId: string,
        params: object,
      ) =>
        `/api/v1/terminology/projects/${projectId}/code-systems/${systemId}/codings/${stringifyParams(
          params,
        )}`,
      valueSetCodings: (
        projectId: string,
        valueSetId: string,
        params?: object,
      ) =>
        `/api/v1/terminology/projects/${projectId}/value-sets/${valueSetId}/codings/${stringifyParams(
          params,
        )}`,
      codings: (projectId: string, params: object) =>
        `/api/v1/terminology/projects/${projectId}/codings${stringifyParams(
          params,
        )}`,
      traverseCodings: (projectId: string, codeId: string, params: object) =>
        `/api/v1/terminology/projects/${projectId}/codings/${codeId}/codings/${stringifyParams(
          params,
        )}`,
      codingsRelatedCodings: (
        projectId: string,
        systemId: string,
        codingId: string,
        params: object,
      ) =>
        `/api/v1/terminology/projects/${projectId}/code-systems/${systemId}/codings/${codingId}/codings${stringifyParams(
          params,
        )}`,
      modifyCodeSystemAssociation: (
        projectId: string,
        codeSystemId: string,
        params: object = {},
      ) =>
        `/api/v1/terminology/projects/${projectId}/associations/code-systems/${codeSystemId}${stringifyParams(
          params,
        )}`,
      modifyValueSetAssociation: (
        projectId: string,
        valueSetId: string,
        params: object = {},
      ) =>
        `/api/v1/terminology/projects/${projectId}/associations/value-sets/${valueSetId}${stringifyParams(
          params,
        )}`,
    },
    patients: {
      patientsForProject: (projectId: string) =>
        `/api/v1/patient-mappings/projects/${projectId}`,
    },
    policies: {
      policies: (params?: object, id?: Id) =>
        `/api/v1/policies${id ? '/' + id : ''}${stringifyParams(params)}`,
    },
    projects: {
      datasets: (params?: object, id?: Id) =>
        `/api/v1/projects${id ? '/' + id : ''}${stringifyParams(params)}`,
      stats: () => '/api/v1/project-usages',
      patientCount: (params: object) =>
        `/phc/api/analytics/patient-count${stringifyParams(params, {
          arrayFormat: 'comma',
        })}`,
      files: (params: object, id: Id) =>
        `/api/v1/projects/${id}/files${stringifyParams(params)}`,
    },
    tags: (projectId: Id, tagId?: Id) =>
      `/phc/api/tags/${projectId}${tagId ? '/' + tagId : ''}`,
    analytics: {
      dsl: () => '/api/v1/analytics/dsl',
      configurations: (params: object, id?: Id) =>
        `/api/v1/analytics/configurations${id ? `/${id}` : ''}${stringifyParams(
          params,
        )}`,
      asyncDsl: (params?: object, id?: Id) =>
        `/api/v1/analytics/async-dsl${id ? `/${id}` : ''}${stringifyParams(
          params,
        )}`,
      allSummaries: (id: Id, params: object) =>
        `/api/v1/analytics/summary/${id}${stringifyParams(params)}`,
      medicationSummary: (id: Id, params: object) =>
        `/api/v1/analytics/summary/${id}/clinical/medication/counts${stringifyParams(
          params,
        )}`,
      procedureSummary: (id: Id, params: object) =>
        `/api/v1/analytics/summary/${id}/clinical/procedure/counts${stringifyParams(
          params,
        )}`,
      conditionSummary: (id: Id, params: object) =>
        `/api/v1/analytics/summary/${id}/clinical/condition/counts${stringifyParams(
          params,
        )}`,
      observationSummary: (id: Id, params: object) =>
        `/api/v1/analytics/summary/${id}/clinical/observation/counts${stringifyParams(
          params,
        )}`,
      mediaSummary: (id: Id, params: object) =>
        `/api/v1/analytics/summary/${id}/clinical/media/counts${stringifyParams(
          params,
        )}`,
    },
    configuration: {
      layouts: (params: object, id?: Id) =>
        `/phc/api/configuration/layouts${id ? `/${id}` : ''}${stringifyParams(
          params,
        )}`,
    },
    terms: {
      suggest: () => '/api/v1/terminology/suggest',
      search: () => '/api/v1/terminology/search',
    },
    subjects: {
      search: () => '/phc/api/subjects/search',
    },
    survey: {
      surveyAdapter: (projectId: string, surveyId: string, name?: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/adapters${
          name ? `/${name}` : ''
        }`,
      globalAdapters: (projectId: string, surveyId: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/adapters/global`,
      surveySchedule: (projectId: string, surveyId: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/schedule`,
      surveyConfig: (projectId: string, surveyId: string) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/config`,
      surveyAttachment: (
        projectId: string,
        responseId: string,
        linkId: string,
        fileId?: string,
        params?: any,
      ) =>
        `/api/v1/survey/projects/${projectId}/responses/${responseId}/attachments/${linkId}${
          fileId ? `/${fileId}` : ''
        }${stringifyParams(params)}`,
      surveys: (projectId: string, id?: string, params?: any) =>
        `/api/v1/survey/projects/${projectId}/surveys${
          id ? `/${id}` : ''
        }${stringifyParams(params)}`,
      primarySurveyVersions: (
        projectId: string,
        primarySurveyId: string,
        params?: any,
      ) =>
        `/api/v1/survey/projects/${projectId}/surveys/${primarySurveyId}/versions${stringifyParams(
          params,
        )}`,
      versionSurvey: (projectId: string, surveyId: string, params?: any) =>
        `/api/v1/survey/projects/${projectId}/surveys/${surveyId}/versions${stringifyParams(
          params,
        )}`,
      surveyResponses: (projectId: string, id?: string, params?: any) =>
        `/api/v1/survey/projects/${projectId}/responses${
          id ? `/${id}` : ''
        }${stringifyParams(params)}`,
    },
    alert: (projectId: string, alertId?: string) =>
      `/api/v1/alerts/projects/${projectId}/${alertId}`,
    alerts: (projectId: string) => `/api/v1/alerts/projects/${projectId}`,
    knowledge: {
      biochemistry: (filterString?: string, params?: object) =>
        `/api/v1/knowledge/biochemistry${stringifyParams(
          params,
        )}${filterString}`,
      evidence: (filterString?: string, params?: object) =>
        `/api/v1/knowledge/evidence${stringifyParams(params)}${filterString}`,
      geneSets: (params?: object, id?: string) =>
        `/api/v1/knowledge/gene-sets${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
      genes: (params?: object, id?: Id) =>
        `/api/v1/knowledge/genes${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
      geneProfiles: (params?: object) =>
        `/api/v1/knowledge/profiles${stringifyParams(params)}`,
      geneValidation: (params?: object, id?: Id) =>
        `/api/v1/knowledge/genes/validate${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
      geneAggregates: ({ gene }: { gene: any }) =>
        `/api/v1/knowledge/expression/aggregates/${gene}`,
      pfamDataSets: (transcriptId: string) =>
        `/api/v1/knowledge/proteins?transcriptId=${transcriptId}`,
      variants: (transcriptId: string) =>
        `/api/v1/knowledge/variants?transcriptId=${transcriptId}`,
      drugClass: (params?: object) =>
        `/api/v1/knowledge/drugclass${stringifyParams(params)}`,
    },
    evaluatedPolicy: (params?: EvaluatedPolicyUrlOptions) =>
      `/api/v1/evaluatedPolicy${stringifyParams(params)}`,
    tasks: (params?: object, id?: Id) =>
      `/api/v1/tasks${id ? '/' + id : ''}${stringifyParams(params)}`,
    sftp: {
      users: (project: Id, params?: object, user?: Id) =>
        `/api/v1/sftp/projects/${project}/users${
          user ? '/' + user : ''
        }${stringifyParams(params)}`,
      userEvents: (project: Id, user: Id, params?: object) =>
        `/api/v1/sftp/projects/${project}/users/${user}/events${stringifyParams(
          params,
        )}`,
    },
    invitations: (params?: object, id?: Id) =>
      `/api/v1/invitations${id ? '/' + id : ''}${stringifyParams(params)}`,
    cohorts: (params?: object, id?: Id) =>
      `/api/v1/cohorts${id ? '/' + id : ''}${stringifyParams(params)}`,
    childCohorts: (projectId: Id, id: Id, params?: object) =>
      `/api/v1/cohorts/projects/${projectId}/cohorts/${id}/child-cohorts${stringifyParams(
        params,
      )}`,
    cohortsPatients: (projectId: Id, id: Id) =>
      `/api/v1/cohorts/projects/${projectId}/cohorts/${id}/patients`,
    features: (params?: object, id?: Id) =>
      `/api/v1/features${id ? '/' + id : ''}${stringifyParams(params)}`,
    publicFeatures: (params?: object, id?: Id) =>
      `/api/v1/features-public${id ? '/' + id : ''}${stringifyParams(params)}`,
    ga4gh: {
      variantsSearch: (account: Id) => `/ga4gh/${account}/v1/variants/search`,
      variantSets: (account: Id, id?: Id) =>
        `/ga4gh/${account}/v1/variantsets${id ? '/' + id : ''}`,
      variantSetsSearch: (account: Id) =>
        `/ga4gh/${account}/v1/variantsets/search`,
      readSets: (account: Id, id?: Id) =>
        `/ga4gh/${account}/v1/readgroupsets${id ? '/' + id : ''}`,
      readSetsSearch: (account: Id) =>
        `/ga4gh/${account}/v1/readgroupsets/search`,
      rnaSets: (account: Id, id?: Id) =>
        `/ga4gh/${account}/v1/rnaquantificationsets${id ? '/' + id : ''}`,
      rnaSetsSearch: (account: Id) =>
        `/ga4gh/${account}/v1/rnaquantificationsets/search`,
      copyNumberSets: (account: Id, id?: Id) =>
        `/ga4gh/${account}/v1/copynumbersets${id ? '/' + id : ''}`,
      structuralVariantSets: (account: Id, id?: Id) =>
        `/ga4gh/${account}/v1/fusionsets${id ? '/' + id : ''}`,
      genomicSets: (account: Id, id?: Id, params?: object) =>
        `/ga4gh/${account}/v1/genomicsets${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
    },
    auth: {
      login: (
        subdomain: string,
        redirectDomain: string,
        destination: string,
        search = {},
      ) =>
        `/auth/v1/login${stringifyParams({
          subdomain,
          redirectDomain,
          app: 'phc',
          destination,
          ...search,
        })}`,
      signup: (
        subdomain: string,
        redirectDomain: string,
        destination: string,
        search = {},
      ) =>
        `/auth/v1/signup${stringifyParams({
          subdomain,
          redirectDomain,
          app: 'phc',
          destination,
          ...search,
        })}`,
      redirectUri: () =>
        process.env.NODE_ENV === 'production'
          ? baseEnvUrl({ uri: '/auth/v1/app-redirect', window })
          : process.env.LOCAL_DEV_AUTH_REDIRECT_URL,
      logout: () => '/auth/v1/api/logout',
      cookie: () => '/auth/v1/api/cookie',
      clientIdWithSubdomain: (subdomain: string) =>
        `/auth/v1/api/client-id?subDomain=${subdomain}`,
    },
    workflows: {
      root: (params?: object, id?: Id) =>
        `/api/v1/workflows/ga4gh/wes/runs${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
      parse: () => '/api/v1/workflows/ga4gh/wes/runs/v2/parse',
    },
    toolsRegistry: {
      root: (params?: object, id?: Id) =>
        `/api/v1/trs/v2/tools${id ? '/' + id : ''}${stringifyParams(params)}`,
      filters: (column: string) => `/api/v1/trs/${column}`,
      getDownloadLink: (id: string) => `/api/v1/trs/files/${id}/download`,
      addVersion: (id: string) => {
        return `/api/v1/trs/v2/tools/${id}/versions`;
      },
      getUploadLink: () => `/api/v1/trs/files`,
      removeInstall: (id: string, version: string) =>
        `/api/v1/trs/v2/tools/installs/${id}/${version}`,
    },
    consent: {
      projectConsentForm: (projectId: Id) =>
        `/api/v1/consent/projects/${projectId}/default-form`,
      projectConsentFormVersion: (projectId: Id) =>
        `/api/v1/consent/projects/${projectId}/default-form/version`,
      projectConsentForms: (projectId: Id) =>
        `/api/v1/consent/projects/${projectId}/forms`,
      projectConsentFormById: (projectId: Id, formId: Id) =>
        `/api/v1/consent/projects/${projectId}/forms/${formId}`,
      projectConsents: (params: object) =>
        `/api/v1/consent/directives${stringifyParams(params)}`,
      patient: (projectId: Id, patientId: Id) =>
        `/api/v1/consent/projects/${projectId}/patients/${patientId}`,
      userConsents: (params: object, id?: Id) =>
        `/api/v1/consent/directives/me${id ? '/' + id : ''}${stringifyParams(
          params,
        )}`,
      consentForms: (params: object) =>
        `/api/v1/consent/forms${stringifyParams(params)}`,
    },
    genomics: {
      queryTests: ({
        projectId,
        patientId,
      }: {
        projectId: Id;
        patientId: Id;
      }) =>
        `/api/v1/genomics/projects/${projectId}/subjects/${patientId}/tests`,
      projectEvents: ({
        params,
        projectId,
      }: {
        params: object;
        projectId: Id;
      }) =>
        `/api/v1/genomics/projects/${projectId}/events${stringifyParams(
          params,
        )}`,
      projectTests: ({
        params,
        projectId,
      }: {
        params: object;
        projectId: Id;
      }) =>
        `/api/v1/genomics/projects/${projectId}/tests${stringifyParams(
          params,
        )}`,
      projectTest: ({
        projectId,
        testId,
        params,
      }: {
        projectId: Id;
        testId: Id;
        params?: object;
      }) =>
        `/api/v1/genomics/projects/${projectId}/tests/${testId}${stringifyParams(
          params,
        )}`,
      getProjectResources: ({
        projectId,
        ...rest
      }: {
        projectId: Id;
        cohortId?: Id;
      }) =>
        `/api/v1/genomics/projects/${projectId}/resources${stringifyParams(
          rest,
        )}`,
      deleteTest: ({ projectId, testId }: { projectId: Id; testId: Id }) =>
        `/api/v1/genomics/projects/${projectId}/tests/${testId}`,
      variants: (params: object, id?: Id, isPostQuery?: boolean) =>
        `/api/v1/genomics/variants${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`,
      copyNumbers: (params: object, id?: Id, isPostQuery?: boolean) =>
        `/api/v1/genomics/copyNumbers${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`,
      structuralVariants: (params: object, id?: Id, isPostQuery?: boolean) =>
        `/api/v1/genomics/structural-variants${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`,
      expressions: (params: object, id?: Id, isPostQuery?: boolean) =>
        `/api/v1/genomics/expressions${id ? '/' + id : ''}${
          isPostQuery ? '/_search' : stringifyParams(params)
        }`,
      summary: (projectId: Id, testId: Id) =>
        `/api/v1/genomics/projects/${projectId}/tests/${testId}/summary`,
      fmiImport: () => '/api/v1/tasks/system/foundation-xml-import',
      ashionImport: () => '/api/v1/tasks/system/ashion-import',
      manifestPatientInfo: (projectId: Id, manifestId: Id) =>
        `/api/v1/genomics/manifest/${projectId}/${manifestId}`,
      manifestRun: () => '/api/v1/genomics/manifest/process',
      hydrateOmicsExplorer: ({
        projectId,
        subjectId,
      }: {
        projectId: string;
        subjectId: string;
      }) => `/phc/api/omics/hydrate?project=${projectId}&subject=${subjectId}`,
      createOmicsFilter: () => '/phc/api/omics-filters',
      updateOmicsFilter: (id: string) => `/phc/api/omics-filters/${id}`,
      deleteOmicsFilter: (id: string) => `/phc/api/omics-filters/${id}`,
      defaultOmicsFilter: () => '/phc/api/omics-filters/default',
    },
    engagement: {
      engagements: (params: object) =>
        `/api/v1/engagement/flow${stringifyParams(params)}`,
      engagement: (params: object, id: Id) =>
        `/api/v1/engagement/flow/${id}${stringifyParams(params)}`,
      engagementCounts: (params: object, id: Id) =>
        `/api/v1/engagement/flow/${id}/counts${stringifyParams(params)}`,
      engagementInteractions: (id: string, subjectId: string, params: object) =>
        `/api/v1/engagement/flow/${id}/subjects/${subjectId}/interactions${stringifyParams(
          params,
        )}`,
      engagementSubjects: (params: object, id: Id, index: string) =>
        `/api/v1/engagement/flow/${id}/milestones/${index}/subjects${stringifyParams(
          params,
        )}`,
      subjectCompletion: (flowId: string, subjectId: string) =>
        `/api/v1/engagement/flow/${flowId}/subjects/${subjectId}/completion`,
    },
    ehrs: {
      ehrs: (id?: Id) => `/api/v1/ehrs${id ? '/' + id : ''}`,
      connectors: (ehrId: Id, id?: Id) =>
        `/api/v1/ehrs/${ehrId}/connectors${id ? '/' + id : ''}`,
      ingestions: (ehrId: Id, reingestAll?: boolean) =>
        `/api/v1/ehrs/${ehrId}/ingestions${
          reingestAll ? '?reingestAll=true' : ''
        }`,
      scheduledIngestions: (ehrId: Id, id?: Id) =>
        `/api/v1/ehrs/${ehrId}/scheduled-ingestions${id ? '/' + id : ''}`,
    },
    addData: () => '/phc/api/fhir/import',
    transcriptions: {
      getWebSocketURL: () => '/api/v1/transcriptions',
      comprehend: () => '/api/v1/transcriptions/comprehend',
      analyze: () => '/api/v1/transcriptions/analyze',
      persist: () => '/api/v1/transcriptions/persist',
    },
    synoptics: {
      analyze: (patientId: string) =>
        `/api/v1/synoptics/patients/${patientId}/analyze`,
      cardiovascularRisk: {
        patient: (patientId: string) =>
          `/api/v1/synoptics/patients/${patientId}/calculator/cardiovascularrisk`,
        calculator: () => '/api/v1/synoptics/calculators/cardiovascularrisk',
      },
      diabeticRisk: {
        patient: (patientId: string) =>
          `/api/v1/synoptics/patients/${patientId}/calculator/diabeticrisk`,
        calculator: () => '/api/v1/synoptics/calculators/diabeticrisk',
      },
      hIRRisk: {
        patient: (patientId: string) =>
          `/api/v1/synoptics/patients/${patientId}/calculator/hirrisk`,
        calculator: () => '/api/v1/synoptics/calculators/hirrisk',
      },
      hypertensionRisk: {
        patient: (patientId: string) =>
          `/api/v1/synoptics/patients/${patientId}/calculator/hypertensionrisk`,
        calculator: () => '/api/v1/synoptics/calculators/hypertensionrisk',
      },
      metabolicRisk: {
        patient: (patientId: string) =>
          `/api/v1/synoptics/patients/${patientId}/calculator/metabolicrisk`,
        calculator: () => '/api/v1/synoptics/calculators/metabolicrisk',
      },
      tyGIRisk: {
        patient: (patientId: string) =>
          `/api/v1/synoptics/patients/${patientId}/calculator/tygirisk`,
        calculator: () => '/api/v1/synoptics/calculators/tygirisk',
      },
    },
    lifeResearch: {
      appConfig: (projectId: string) =>
        `/api/v1/life-research/projects/${projectId}/app-config`,
    },
    mtb: {
      publish: () => '/api/v1/mtb/notes',
      get: (params: object) => `/api/v1/mtb/notes${stringifyParams(params)}`,
      tempaltesUrl: `/api/v1/mtb/templates`,
      publishDraft: `/api/v1/mtb/draft/notes`,
      getQuestionnaireResponse: (params: object) =>
        `/api/v1/mtb/questionnaireResponse${stringifyParams(params)}`,
      draftResource: (status: string, params: object) =>
        `/api/v1/mtb/draft/notes/${status.concat(stringifyParams(params))}`,
      putDraft: (fileId: string) => `/api/v1/mtb/draft/notes/${fileId}`,
      getFile: (fileId: string, params: object) =>
        `/api/v1/mtb/file/${fileId.concat(stringifyParams(params))}`,
    },
    tokenService: {
      listIdentities: (lifeomicClientId: string, oidcIssuer: string) =>
        lifeomicClientId && oidcIssuer
          ? `/api/v1/identities${stringifyParams({
              clientId: lifeomicClientId,
              issuer: oidcIssuer,
            })}`
          : `/api/v1/identities`,
      addRemoveIdentity: (
        lifeomicClientId: string,
        oidcIssuer: string,
        oidcSubject: string,
      ) =>
        `/api/v1/identities/${lifeomicClientId}/${oidcIssuer}/${oidcSubject}`,
    },
    ema: {
      project: (projectId: string) => `/api/v1/ema/project/${projectId}`,
      patch: (project: string, code: string, version: string) =>
        `/api/v1/ema/${project}/${code}/${version}`,
      create: () => `/api/v1/ema`,
      delete: ({
        project,
        code,
        version,
      }: {
        project: string;
        code: string;
        version: string;
      }) => `/api/v1/ema/${project}/${code}/${version}`,
      uploadImage: () => `/api/v1/ema/upload-image`,
    },
    trialMatching: {
      alerts: (projectId: string, params?: Record<string, string>) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/alerts/me${stringifyParams(
          params,
        )}`,
      alert: (projectId: string, alertId: string) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/alerts/${alertId}/me`,
      favorite: ({
        projectId,
        tagId,
        trialId,
      }: {
        projectId: string;
        tagId: string;
        trialId: string;
      }) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/tags/${tagId}/trials/${trialId}/me`,
      demo: (projectId: string) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/demo`,
      filters: (projectId: string) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/filters`,
      filter: (projectId: string, filterName: string) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/filters/${filterName}`,
      interaction: (projectId: string) =>
        `/api/v1/trial-matching-alert/projects/${projectId}/interactions`,
    },
    trialMatchingNotes: {
      note: (projectId: string, trialId: string) =>
        `/api/v1/trial-matching-note/projects/${projectId}/trials/${trialId}/me`,
    },
  },
  app: {
    home: () => '/home',
    circles: (id?: Id) => `/project/circles${id ? '/' + id : ''}`,
    invitations: () => '/account/accounts/invitations',
    dicomViewer: (fileId: Id) => `/dicom-viewer${fileId ? `/${fileId}` : ''}`,
    files: (params: object) => `/project/files/list${stringifyParams(params)}`,
    ocrDashboard: () => '/project/ocr/dashboard',
    ocr: {
      allFiles: () => '/project/ocr/all-files',
      subjects: (id?: Id) => `/project/ocr/subjects${id ? '/' + id : ''}`,
      viewer: (id?: Id, params?: any) =>
        `/project/ocr/viewer${id ? `/${id}` : ''}${stringifyParams(params)}`,
    },
    manageSurveys: (id?: Id, queryString?: string) =>
      `/project/manage-surveys${id ? `/${id}` : ''}${
        queryString ? `?${queryString}` : ''
      }`,
    programs: (id?: Id) => `/project/programs${id ? `/${id}` : ''}`,
    auditing: (params: Record<string, string>) =>
      `/account/auditing${stringifyParams(params)}`,
    trialMatching: {
      root: () => '/project/trial-matching',
      trial: (trial: string, params?: Record<string, string>) =>
        `/project/trial-matching/${trial}${stringifyParams(params)}`,
    },
  },
  resources: {
    project: (id: Id) => `/v1/projects/${id}`,
    file: (id: Id) => `/v1/files/${id}`,
    rules: () =>
      'https://support.lifeapps.io/hc/en-us/articles/360016255033-LifeOmic-Content-Guidelines',
  },
  marketplace: {
    domain: () => {
      if (window.location.host.startsWith('localhost:')) {
        return 'https://marketplace.dev.lifeomic.com';
      }
      if (window.location.host.startsWith('lifeomic.localhost:')) {
        return 'https://lifeomic.marketplace.dev.lifeomic.com';
      }

      return `${window.location.protocol}//${window.location.host?.replace(
        'apps.',
        'marketplace.',
      )}/`;
    },
    graphqlEndpoint: () => '/api/v1/marketplace/graphql',
    authenticatedGraphqlEndpoint: () =>
      '/api/v1/marketplace/authenticated/graphql',
  },
};

/**
 * Helpers
 */
export function stringifyParams(params: object, options?: object) {
  return _isEmpty(params) ? '' : `?${queryString.stringify(params, options)}`;
}

interface Params {
  uri: string;
  window: any;
  baseSubdomain?: string;
}

export function baseEnvUrl({ uri, window, baseSubdomain = 'apps' }: Params) {
  const hostParts = window.location.host.split('.');
  const host =
    hostParts.length === 1
      ? hostParts.join('.')
      : hostParts[0] === baseSubdomain && hostParts[1] !== baseSubdomain
      ? hostParts.join('.')
      : hostParts.slice(1).join('.');
  return `${window.location.protocol}//${host}${
    uri.startsWith('/') ? uri : '/' + uri
  }`;
}

export function getHashParams(href: string) {
  if (!href) return null;
  const hashIndex = href.indexOf('#');
  return href
    .substring(hashIndex + 1)
    .split('&')
    .reduce<{ [key: string]: string }>((params, part) => {
      const [key, ...value] = part.split('=');
      params[key] = value.join('=');
      return params;
    }, {});
}

export default {
  ...deepFreeze(urls),
};
