import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Paper } from '@lifeomic/chroma-react/components/Paper';
import clsx from 'clsx';
import useIsMobile from '../../../hooks/useIsMobile';
import { ContentContainerProps } from '.';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: (props: SharedContentContainerProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    backgroundColor:
      props.loginApp === LoginApps.lifeology ? 'rgba(255,255,255,0.33)' : '',
    zIndex: 2,
    position: 'relative',
  }),
  rootWidth: {
    width: 680,
  },
}));

/**
 * Separating this out into its on styles after the root styles
 * creates ordering which didn't happen prior. The purpose of moving this
 * is to ensure that _IF_ this is being viewed on a mobile device that
 * flex direction and width here would take precedence
 */
export const useMobileStyles = makeStyles((_theme: Theme) => ({
  mobileVariant: (_: SharedContentContainerProps) => ({
    flexDirection: 'column',
    width: 375,
    marginBottom: 0,
  }),
}));

export interface LoginAppProps {
  loginApp: string;
}

type SharedContentContainerProps = ContentContainerProps & LoginAppProps;

export type ContentContainerClasses = GetClasses<typeof useStyles>;

const SharedContentContainer = (props: ContentContainerProps) => {
  const loginApp = useLoginApp();

  const sharedProps: SharedContentContainerProps = {
    ...props,
    ...{ loginApp },
  };
  const rootStyles = useStyles(sharedProps);
  const mobileStyles = useMobileStyles(sharedProps);
  const isMobile = useIsMobile();
  const classes = clsx(
    rootStyles.root,
    isMobile && mobileStyles.mobileVariant,
    rootStyles.rootWidth
  );

  const { children, ...rest } = props;

  return (
    <Paper padding={0} className={classes} {...rest}>
      {props.children}
    </Paper>
  );
};

export default SharedContentContainer;
