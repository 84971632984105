import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import config from '../config';

let singletonClient: any = null;

export default (options: any) => {
  if (singletonClient) {
    return singletonClient;
  } else {
    singletonClient = bugsnag(options).use(bugsnagReact, React);
    return singletonClient;
  }
};

export const safeBugsnagNotify = (error: Error) => {
  if (
    config.disableBugsnagReporting === 'true' ||
    !singletonClient ||
    !singletonClient.notify
  )
    return;

  singletonClient.notify(error);
};
