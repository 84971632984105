import { connectRouter } from 'connected-react-router';
import { appErrorsReducer } from './modules/AppErrors';
import { loginReducer } from './modules/Login';
import { History } from 'history';

// Please keep these alphabetized for simpler debugging
const reducers = (history: History<any>) => ({
  appErrors: appErrorsReducer,
  login: loginReducer,
  router: connectRouter(history),
});

export default reducers;
