import * as React from 'react';

const SvgScreenShareOff = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgScreenShareOff"
    {...props}
  >
    <path
      d="M11.96 14.131v6.876m.05-9l-2.787 2.782m5.574 0L12.01 12M3.019 7.5v6.87c0 1.1.668 2 1.484 2h1.484m14.567-.57A2.372 2.372 0 0021 14.37V5.009a2 2 0 00-2-2H7.4m-4.383 0l18.034 18.035"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgScreenShareOff;
