import * as React from 'react';

const SvgLink2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgLink2"
    {...props}
  >
    <path
      d="M14.456 7.907h2.456a4.093 4.093 0 010 8.186h-2.456m-4.912 0H7.088a4.093 4.093 0 010-8.186h2.456M8.726 12h6.548"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgLink2;
