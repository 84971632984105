import { createSelector } from 'reselect';
import { notificationsSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';

const appNotification = createSelector(
  notificationsSelectors.selectNotifications,
  (notifications) => (notifications.length === 0 ? null : notifications[0])
);

const hasNotification = createSelector(
  appNotification,
  (notification) => notification !== null
);

export default {
  appNotification,
  hasNotification,
};
