import React, { useMemo, useRef, useState } from 'react';
import _isString from 'lodash/isString';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, FormikProps, getIn, FormikHelpers } from 'formik';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import {
  Radio,
  RadioGroupMinimal,
} from '@lifeomic/chroma-react/components/Radio';
import useValidators, {
  trimWhitespaceFromForm,
} from '../../../hooks/useValidatiors';
import { Button } from '@lifeomic/chroma-react/components/Button';
import PasswordCriteria from '../PasswordCriteria';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../redux/modules/Login';
import { useSignupEndpoint } from '../../../hooks/api';
import { Collapse, Fade } from '@mui/material';
import { push } from 'connected-react-router';
import urls from '../../../util/urls';
import { getTestProps } from '../../../util/testProps';
import { LoginSearchParam } from '../../../redux/modules/Login/actions';
import { SignupFormProps } from '.';
import { configSelectors } from '@lifeomic/phc-web-toolkit/dist/redux/modules/config';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';
import useIsRunningInE2E from '../../../hooks/useIsRunningInE2E';
import handleAuthResponse from '../../../util/handleAuthResponse';
import { AxiosResponse } from 'axios';
import { generatePassword } from '../../../util/passwordlessAuth';
import { Text } from '@lifeomic/chroma-react/components/Text';

export const testIds = {
  firstName: 'signupForm-firstName',
  lastName: 'signupForm-lastName',
  username: 'signupForm-username',
  email: 'signupForm-email',
  reenterEmail: 'signupForm-reenterEmail',
  password: 'signupForm-password',
  reenterPassword: 'signupForm-reenterPassword',
  submitButton: 'signupForm-submitButton',
  signupRecaptcha: 'signupForm-recaptcha',
  passwordToggle: 'signupForm-passwordToggle',
  passwordlessToggle: 'signupForm-passwordlessToggle',
};

const messages = {
  firstName: {
    id: 'signupForm.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'signupForm.lastName',
    defaultMessage: 'Last Name',
  },
  username: {
    id: 'signupForm.userName',
    defaultMessage: 'Username',
  },
  email: {
    id: 'signupForm.email',
    defaultMessage: 'Email',
  },
  reenterEmail: {
    id: 'signupForm.reenterEmail',
    defaultMessage: 'Confirm Email',
  },
  password: {
    id: 'signupForm.password',
    defaultMessage: 'Password',
  },
  reenterPassword: {
    id: 'signupForm.reenterPassword',
    defaultMessage: 'Confirm Password',
  },
  signUpWithoutPasswordRadio: {
    id: 'signupForm.signUpWithoutPasswordRadio',
    defaultMessage: 'Passwordless',
  },
  signUpWithPasswordRadio: {
    id: 'signupForm.signUpWithPasswordRadio',
    defaultMessage: 'Password',
  },
  signUpWithPassword: {
    id: 'signupForm.signUpWithPassword',
    defaultMessage: 'We’ll email you a link for a passwordless sign in.',
  },
  signUp: {
    id: 'signupForm.signUp',
    defaultMessage: 'Sign Up',
  },
  misMatchedEmails: {
    id: 'signupForm.misMatchedEmails',
    defaultMessage: 'Emails must match',
  },
  misMatchedPassword: {
    id: 'signupForm.misMatchedPassword',
    defaultMessage: 'Passwords must match',
  },
};

export type FormValues = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  reenteredEmail: string;
  password: string;
  reenteredPassword: string;
  inviteId?: string;
  evc?: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  signupButton: {
    marginTop: theme.spacing(1),
    height: theme.pxToRem(40),
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  criteria: {
    marginTop: theme.spacing(1),
  },
  confirmPassword: {
    marginTop: theme.spacing(1),
  },
  collapseContainer: {
    '&.MuiCollapse-hidden': {
      marginBottom: 0,
    },
  },
  radioBtn: {
    flex: 1,
  },
  signUpWithPassword: {
    color: theme.palette.text.hint,
    margin: theme.spacing(0, 3, 1),
    textAlign: 'center',
  },
  box: {
    strokeOpacity: 0.5,
  },
}));

export type SignupFormClasses = GetClasses<typeof useStyles>;

const safeGetEmailValue = (email: string | string[]): string => {
  const getValueFromString = (emailString: string) => {
    if (!emailString || !_isString(emailString)) return '';

    return emailString.toLowerCase();
  };

  return Array.isArray(email)
    ? getValueFromString(email[0])
    : getValueFromString(email);
};

const PhcSignupForm = (props: SignupFormProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const validators = useValidators();
  const dispatch = useDispatch();
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);
  const inviteId = useSelector(loginSelectors.selectInviteId);
  const evc = useSelector(loginSelectors.selectEVC);
  const search = useSelector(loginSelectors.selectSearch);
  const recaptchaKey = useSelector(
    configSelectors.selectConfigState
  ).recaptchaKey;
  const recaptchaEnabled = useSelector(
    configSelectors.selectConfigState
  ).recaptchaEnabled;
  const isRunningInE2E = useIsRunningInE2E();
  const recaptchaRef = useRef<ReCAPTCHA>();
  const emailFromUrl = (search || ({} as LoginSearchParam)).email;
  const projectFromUrl = (search || ({} as LoginSearchParam)).projectId;
  const [signupRequestState, signupUser] = useSignupEndpoint();
  const [isPasswordSignUp, setIsPasswordSignUp] = useState<boolean>(false);

  const onSubmit = React.useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      // do not execute recaptcha if running e2e.  Recaptcha's goal is to prevent bots.
      const recaptchaResponseToken =
        recaptchaEnabled && !isRunningInE2E
          ? await recaptchaRef.current.executeAsync()
          : undefined;

      const { firstName, lastName, username, email } =
        trimWhitespaceFromForm(formValues);
      const randomPassword = {
        password: generatePassword(),
      };
      const { password } = isPasswordSignUp ? formValues : randomPassword;

      const persistSignupParamsAction = loginActions.persistSignupParams(
        username,
        email,
        password,
        firstName,
        lastName
      );

      const preRequestTime = new Date();
      signupUser(
        username,
        password,
        email,
        originalUrl,
        (res: AxiosResponse) => {
          if (res?.data?.userConfirmed && res?.data?.accessToken) {
            const redirectUrl = res.data.originalUrl || originalUrl;
            return handleAuthResponse(
              res.data,
              preRequestTime,
              email,
              redirectUrl
            );
          }
          dispatch(persistSignupParamsAction);
          dispatch(
            push(urls.app.roots.verification({ originalUrl, email, username }))
          );
        },
        firstName,
        lastName,
        recaptchaResponseToken,
        inviteId,
        evc
      );
      actions.setSubmitting(false);
    },
    [dispatch, originalUrl, signupUser]
  );

  const handlePasswordCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPasswordSignUp(e.target.value === 'password');
  };

  const emailValidator = validators.signupEmail;
  const passwordValidator = validators.password;
  const usernameValidator = validators.signupUsername;
  const nameValidator = validators.signupName;

  const validateForm = React.useCallback((formValues: FormValues) => {
    const errors: any = {};
    if (
      !emailValidator(formValues.email) &&
      !!formValues.reenteredEmail &&
      formValues.email !== formValues.reenteredEmail
    ) {
      const mismatchEmailError = formatMessage(messages.misMatchedEmails);
      errors.email = mismatchEmailError;
      errors.reenteredEmail = mismatchEmailError;
    }

    if (
      !passwordValidator(formValues.password) &&
      !!formValues.reenteredPassword &&
      formValues.password !== formValues.reenteredPassword
    ) {
      const mismatchedPasswordError = formatMessage(
        messages.misMatchedPassword
      );
      errors.password = mismatchedPasswordError;
      errors.reenteredPassword = mismatchedPasswordError;
    }

    return errors;
  }, []);

  const initialValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      username: '',
      email: safeGetEmailValue(emailFromUrl),
      reenteredEmail: safeGetEmailValue(emailFromUrl),
      password: '',
      reenteredPassword: '',
    }),
    [emailFromUrl]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validateForm}
    >
      {(formikProps: FormikProps<FormValues>) => {
        const showReenterEmail = !emailValidator(formikProps.values.email);
        const showReenterPassword = !passwordValidator(
          formikProps.values.password
        );

        return (
          <Form>
            <FormBox>
              <Field
                {...getTestProps(testIds.firstName)}
                aria-label="First Name"
                fullWidth
                name="firstName"
                autoComplete="firstName"
                component={FormikTextField}
                placeholder={formatMessage(messages.firstName)}
                validate={nameValidator}
              />
              <Field
                {...getTestProps(testIds.lastName)}
                aria-label="Last Name"
                fullWidth
                name="lastName"
                autoComplete="lastName"
                component={FormikTextField}
                placeholder={formatMessage(messages.lastName)}
                validate={nameValidator}
              />
              <Field
                {...getTestProps(testIds.username)}
                aria-label="Username"
                fullWidth
                name="username"
                autoComplete="username"
                component={FormikTextField}
                placeholder={formatMessage(messages.username)}
                validate={usernameValidator}
              />
              <Field
                {...getTestProps(testIds.email)}
                aria-label="Email"
                fullWidth
                name="email"
                autoComplete="email"
                component={FormikTextField}
                placeholder={formatMessage(messages.email)}
                validate={emailValidator}
                disabled={
                  !projectFromUrl && safeGetEmailValue(emailFromUrl) !== ''
                }
              />
              <Collapse
                in={showReenterEmail}
                className={classes.collapseContainer}
              >
                <Fade in={showReenterEmail}>
                  <div>
                    <Field
                      {...getTestProps(testIds.reenterEmail)}
                      aria-label="Re-entered Email"
                      fullWidth
                      name="reenteredEmail"
                      autoComplete="email"
                      component={FormikTextField}
                      placeholder={formatMessage(messages.reenterEmail)}
                      validate={validators.required}
                      disabled={
                        !projectFromUrl &&
                        safeGetEmailValue(emailFromUrl) !== ''
                      }
                    />
                  </div>
                </Fade>
              </Collapse>
              <RadioGroupMinimal
                aria-label="Password Selector"
                name="pageSelector"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePasswordCheck(e)
                }
                value={'passwordless'}
                fullWidth
              >
                <Radio
                  className={classes.radioBtn}
                  {...getTestProps(testIds.passwordlessToggle)}
                  value="passwordless"
                  label={formatMessage(messages.signUpWithoutPasswordRadio)}
                />
                <Radio
                  className={classes.radioBtn}
                  {...getTestProps(testIds.passwordToggle)}
                  value="password"
                  label={formatMessage(messages.signUpWithPasswordRadio)}
                />
              </RadioGroupMinimal>
              <Collapse
                in={isPasswordSignUp}
                className={classes.collapseContainer}
              >
                <Fade in={isPasswordSignUp}>
                  <div>
                    <Field
                      {...getTestProps(testIds.password)}
                      aria-label="Password"
                      fullWidth
                      name="password"
                      autoComplete="new-password"
                      component={FormikTextField}
                      placeholder={formatMessage(messages.password)}
                      validate={isPasswordSignUp && passwordValidator}
                      type="password"
                    />
                  </div>
                </Fade>
                <Collapse
                  in={showReenterPassword && isPasswordSignUp}
                  className={classes.collapseContainer}
                >
                  <Fade in={showReenterPassword && isPasswordSignUp}>
                    <div>
                      <Field
                        {...getTestProps(testIds.reenterPassword)}
                        aria-label="Re-entered Password"
                        fullWidth
                        className={classes.confirmPassword}
                        name="reenteredPassword"
                        autoComplete="new-password"
                        component={FormikTextField}
                        type="password"
                        placeholder={formatMessage(messages.reenterPassword)}
                        validate={isPasswordSignUp && validators.required}
                      />
                    </div>
                  </Fade>
                </Collapse>
                <PasswordCriteria
                  className={classes.criteria}
                  passwordValue={getIn(formikProps.values, 'password')}
                />
              </Collapse>
              <Collapse
                in={!isPasswordSignUp}
                className={classes.collapseContainer}
              >
                <Text className={classes.signUpWithPassword} size="caption">
                  {formatMessage(messages.signUpWithPassword)}
                </Text>
              </Collapse>
              <Button
                {...getTestProps(testIds.submitButton)}
                className={classes.signupButton}
                disabled={
                  signupRequestState.isLoading || formikProps.isSubmitting
                }
                type="submit"
                variant="contained"
              >
                {formatMessage(messages.signUp)}
              </Button>
              {recaptchaEnabled && (
                <ReCAPTCHA
                  {...getTestProps(testIds.signupRecaptcha)}
                  sitekey={recaptchaKey}
                  size="invisible"
                  ref={recaptchaRef}
                />
              )}
            </FormBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhcSignupForm;
