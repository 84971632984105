import * as React from 'react';

const SvgCloudOff = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCloudOff"
    {...props}
  >
    <path
      d="M15.5 9.5a5.438 5.438 0 011.8 0 4.276 4.276 0 012.237 1.175A4.612 4.612 0 0120.8 12.9a3.6 3.6 0 01.1 1.137 5.138 5.138 0 01-.2 1.063m-2.7 3a4.1 4.1 0 01-.763.225 4.432 4.432 0 01-.837.075H8.8a6.049 6.049 0 01-2.137-.375A4.941 4.941 0 014.9 16.9a7.3 7.3 0 01-1.4-2.162A6.613 6.613 0 013 12.2a6.354 6.354 0 013.3-5.6m5.4-.5a6.293 6.293 0 013.9 3.9M3 3l9 9 9 9"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCloudOff;
