import * as React from 'react';

const SvgSettings = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgSettings"
    {...props}
  >
    <path
      d="M21 11.95h0a1.967 1.967 0 00-1.9-1.9h-.6a1.923 1.923 0 01-1.675-.95 1.815 1.815 0 01-.025-1.85l.15-.25.15-.25a1.965 1.965 0 00.175-1.45 1.837 1.837 0 00-.875-1.15h0a1.965 1.965 0 00-1.45-.175 1.837 1.837 0 00-1.15.875l-.15.25-.15.25a1.809 1.809 0 01-1.613.975A1.982 1.982 0 0110.2 5.35l-.1-.2-.1-.2a1.808 1.808 0 00-1.1-.875 1.852 1.852 0 00-1.4.175h0a2.09 2.09 0 00-.875 1.188A1.876 1.876 0 006.8 6.85l.15.25.15.25a1.914 1.914 0 01-.012 1.888 1.82 1.82 0 01-1.588.912h-.6a1.967 1.967 0 00-1.9 1.9h0a1.967 1.967 0 001.9 1.9h.6a1.923 1.923 0 011.675.95 1.815 1.815 0 01.025 1.85l-.15.25-.15.25a1.965 1.965 0 00-.175 1.45 1.837 1.837 0 00.875 1.15h0a1.965 1.965 0 001.45.175 1.837 1.837 0 001.15-.875l.15-.25.15-.25a1.809 1.809 0 011.613-.975 1.982 1.982 0 011.687.975l.15.25.15.25a1.837 1.837 0 001.15.875 1.965 1.965 0 001.45-.175h0a1.837 1.837 0 00.875-1.15 1.965 1.965 0 00-.175-1.45l-.15-.25-.15-.25a1.912 1.912 0 01.013-1.888 1.817 1.817 0 011.587-.912h.6a1.886 1.886 0 001.188-.625A2.046 2.046 0 0021 11.95zm-9-1.9a2 2 0 11-1.414.586A1.994 1.994 0 0112 10.05z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgSettings;
