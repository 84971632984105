import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import AuthPromptPaper from '../common/AuthPromptPaper';
import PromptHeader from '../common/PromptHeader';
import PromptSubHeader from '../common/PromptSubHeader';
import { withRouter, RouteProps } from 'react-router-dom';

const messages = {
  header: {
    id: 'PendingSharedLogout.header',
    defaultMessage: 'Logout Complete',
  },
  subHeader: {
    id: 'PendingSharedLogout.subHeader',
    defaultMessage: 'Please close this browser before continuing.',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
}));

export type PendingSharedLogoutClasses = GetClasses<typeof useStyles>;

export type PendingSharedLogoutOwnProps = {};

export type PendingSharedLogoutProps = PendingSharedLogoutOwnProps & RouteProps;

const PendingSharedLogout = (props: PendingSharedLogoutProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);

  return (
    <AuthPromptPaper className={classes.root}>
      <PromptHeader>{formatMessage(messages.header)}</PromptHeader>
      <PromptSubHeader>{formatMessage(messages.subHeader)}</PromptSubHeader>
    </AuthPromptPaper>
  );
};

export default React.memo(withRouter(PendingSharedLogout));
