import { generate } from 'generate-password';

export const localStorageKey = '@@lifeomic/store/auth-session';

type PasswordlessAuthData = {
  session: string;
  originalUrl: string;
  username: string;
};

export function setPasswordlessAuth(data: PasswordlessAuthData) {
  window.localStorage.setItem(localStorageKey, JSON.stringify(data));
}

export function getPasswordlessAuth(): PasswordlessAuthData {
  const data = window.localStorage.getItem(localStorageKey);
  return data
    ? JSON.parse(data)
    : { session: '', originalUrl: '', username: '' };
}

export const generatePassword = () =>
  generate({
    length: Math.random() * 12 + 8,
    strict: true,
    numbers: true,
    symbols: true,
  });
