import useAPI, { RequestConfig } from './useApi';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import { appErrorsActions } from '../../redux/modules/AppErrors';
import urls from '../../util/urls';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import config from '../../util/config';
import { loginSelectors } from '../../redux/modules/Login';

const messages = {
  apiErrorMessage: {
    id: 'resendCode.apiErrorMessage',
    defaultMessage:
      'An error occurred when resending the verification code.  Error: "{error}"',
  },
  genericError: {
    id: 'resendCode.genericError',
    defaultMessage: 'An error occurred when resending the verification code.',
  },
  resendNotificationSuccess: {
    id: 'resendCode.resendSuccess',
    defaultMessage: 'Successfully resent the verification code',
  },
};

function useResendCodeEndpoint() {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);

  const clientId = customClientId || config.oauthClientId;

  if (!clientId) {
    const error = new Error(
      'A client ID could not be found in environment configuration'
    );
    dispatch(appErrorsActions.registerError(error));
  }

  const onFailure = (error: AxiosError) => {
    const errorMessage = get(error, 'response.data.error', null) as string;

    const notificationMessage = errorMessage
      ? formatMessage(messages.apiErrorMessage, { error: errorMessage })
      : formatMessage(messages.genericError);

    dispatch(
      notificationsActions.addNotification(notificationMessage, {
        statusType: 'error',
      })
    );
  };

  const onSuccess = () => {
    dispatch(
      notificationsActions.addNotification(
        formatMessage(messages.resendNotificationSuccess),
        { statusType: 'success' }
      )
    );
  };

  const makeAPIRequest = (username: string): RequestConfig => ({
    url: urls.api.auth.resendCode,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      username,
      clientId,
    },
    onSuccess,
    onFailure,
  });

  return useAPI(makeAPIRequest);
}

export default useResendCodeEndpoint;
