import appErrorsActionTypes from './actionTypes';

export interface RegisterErrorAction {
  type: string;
  error: Error;
}

function registerError(error: Error): RegisterErrorAction {
  return {
    type: appErrorsActionTypes.APP_ERRORS_REGISTER_ERROR,
    error,
  };
}

export default {
  registerError,
};
