'use strict';

import 'core-js';
import 'regenerator-runtime/runtime';
import './polyfills';
import React, { lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap-reboot.css';
// load styles.global.css later than bootstrap to take precedence
import './styles/styles.global.css';
import { render } from 'react-dom';
import Root from './components/Root';
import lifeOmicHelpers from './util/lifeOmicHelpers';
import store from './redux/store';
import config from './util/config';
import { LoginApps } from './util/loginApps';
import { getAppFromOriginalUrl } from './util/getAppFromOriginalUrl';

(window as any).LifeOmic = {
  util: lifeOmicHelpers(store),
  buildManifest: process.env.BUILD_MANIFEST || {},
};

const loginApp = config.loginApp ?? LoginApps.phc;

const LifeologyStyles = lazy(() => import('./styles/LifeologyStyles'));

function renderApp() {
  render(
    <>
      <Suspense fallback={<></>}>
        {loginApp === LoginApps.lifeology && <LifeologyStyles />}
      </Suspense>
      <Root store={store} />
    </>,
    document.getElementById('root')
  );
}

async function conditionallyImportCernerLib() {
  const app = getAppFromOriginalUrl(window.location.href);
  if (loginApp === LoginApps.phc && app === 'sof') {
    await import('cerner-smart-embeddable-lib');
    console.log('cerner-smart-embeddable-lib loaded!');
  }
}

conditionallyImportCernerLib().then(() => renderApp());
