import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import SocialButton from './SocialButton';
import { getTestProps } from '../../../util/testProps';
import { useIsIOSDevice } from '../../../hooks/useIsIOSDevice';
import useIsMobile from '../../../hooks/useIsMobile';

export const testIds = {
  appleLoginButton: 'socialLogin-apple',
  googleLoginButton: 'socialLogin-google',
  facebookLoginButton: 'socialLogin-facebook',
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export type SocialLoginClasses = GetClasses<typeof useStyles>;

export interface SocialLoginOwnProps {
  disableApple?: boolean;
  disableGoogle?: boolean;
  disableFacebook?: boolean;
}

export type SocialLoginProps = SocialLoginOwnProps;

const SocialLogin = (props: SocialLoginProps) => {
  const classes = useStyles(props);
  const isIOSDevice = useIsIOSDevice();
  const isMobile = useIsMobile();

  const shouldDisableApple = props?.disableApple || (isMobile && !isIOSDevice);

  return (
    <div className={classes.root}>
      {!shouldDisableApple && (
        <SocialButton
          {...getTestProps(testIds.appleLoginButton)}
          provider="signinwithapple"
        />
      )}
      {!props.disableGoogle && (
        <SocialButton
          {...getTestProps(testIds.googleLoginButton)}
          provider="google"
        />
      )}
      {!props.disableFacebook && (
        <SocialButton
          {...getTestProps(testIds.facebookLoginButton)}
          provider="facebook"
        />
      )}
    </div>
  );
};

export default React.memo(SocialLogin);
