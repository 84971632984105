import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Snackbar } from '@lifeomic/chroma-react/components/Snackbar';
import { appNotificationSelectors } from '../../redux/modules/AppNotifications';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';

export type AppNotificationsOwnProps = {};

export type AppNotificationsProps = AppNotificationsOwnProps;

const AppNotifications = (_props: AppNotificationsProps) => {
  const hasNotification = useSelector(appNotificationSelectors.hasNotification);
  const notification = useSelector(appNotificationSelectors.appNotification);
  const dispatch = useDispatch();
  const handleSnackbarClose = useCallback(() => {
    dispatch(notificationsActions.removeNotification());
  }, []);

  const options = get(notification, 'options', {}) as any;
  const message = get(notification, 'message', '');

  return (
    <Snackbar
      isOpen={hasNotification}
      onClose={handleSnackbarClose}
      statusType={options.statusType || 'info'}
      title={message}
    />
  );
};

export default React.memo(AppNotifications);
