import React from 'react';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  StyledEngineProvider,
} from '@lifeomic/chroma-react/styles';
import App from './App';
import { IntlProvider } from 'react-intl';
import history from '../redux/util/history';
import { ConnectedRouter } from 'connected-react-router';
import { RootErrorBoundary } from '../util/errorReporting';
import { getTheme } from '../styles/theme';

const Root = ({ store }: any) => (
  <RootErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={getTheme()}>
            <IntlProvider locale="en" textComponent={React.Fragment}>
              <App />
            </IntlProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ConnectedRouter>
    </Provider>
  </RootErrorBoundary>
);

export default Root;
