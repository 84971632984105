import * as React from 'react';

const SvgCornerRightUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCornerRightUp"
    {...props}
  >
    <path
      d="M15.066 3v13a5 5 0 01-5 5h-7.1m12.1-18l5.973 5.816M15.066 3L9.092 8.974"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCornerRightUp;
