import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { loginSelectors } from '../../redux/modules/Login';
import VerificationCodeForm from './VerificationCodeForm';
import ResendCodeSection from './ResendCodeSection';
import AuthPromptPaper from '../common/AuthPromptPaper';
import PromptHeader from '../common/PromptHeader';
import queryString from 'query-string';
import { Text } from '@lifeomic/chroma-react/components/Text';

const messages = {
  confirmAccount: {
    id: 'verification.confirmAccount',
    defaultMessage: 'Confirm Account',
  },
  emailIndicator: {
    id: 'verification.emailIndicator',
    defaultMessage: 'We have sent a code and link {br} by email to:',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  prompt: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  contentContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emailIndicator: {
    marginTop: theme.spacing(4),
  },
  emailDisplay: {
    marginBottom: theme.spacing(4),
    wordBreak: 'break-all',
    maxWidth: '100%',
  },
  subHeader: {
    marginTop: theme.spacing(4),
    color: theme.palette.black[500],
    textAlign: 'center',
  },
}));

export type VerificationClasses = GetClasses<typeof useStyles>;

export type VerificationOwnProps = {};

export type VerificationProps = VerificationOwnProps;

const Verification = (props: VerificationProps) => {
  const { email: providedEmail } = queryString.parse(window.location.search);
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const email = useSelector(loginSelectors.selectSignupEmail) || providedEmail;

  return (
    <AuthPromptPaper className={classes.prompt}>
      <div className={classes.contentContainer}>
        <PromptHeader>{formatMessage(messages.confirmAccount)}</PromptHeader>
        <Text className={classes.subHeader}>
          {formatMessage(messages.emailIndicator, { br: <br /> })}
        </Text>
        <Text className={classes.emailDisplay} weight="bold">
          {email}
        </Text>
        <VerificationCodeForm />
      </div>
      <ResendCodeSection />
    </AuthPromptPaper>
  );
};

export default React.memo(Verification);
