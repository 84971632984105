import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Paper } from '@lifeomic/chroma-react/components/Paper';
import SignupContainer from '../common/SignupContainer';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.pxToRem(10),
    width: theme.pxToRem(375),
    overflow: 'hidden',
    maxWidth: '90%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

export type SignupClasses = GetClasses<typeof useStyles>;

export type SignupOwnProps = {};

export type SignupProps = SignupOwnProps;

const Signup = (props: SignupProps) => {
  const classes = useStyles(props);

  return (
    <Paper className={classes.root}>
      <SignupContainer enableAutoSignUp />
    </Paper>
  );
};

export default React.memo(Signup);
