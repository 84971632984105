import { theme as defaultTheme } from '@lifeomic/phc-web-toolkit/dist/theme/phc';
import { theme as lifeologyTheme } from '@lifeomic/lifeology-web-toolkit/theme/lifeology';
import { theme as phcTheme } from './phc';
import { theme as skillSpringTheme } from './skillspring';
import { theme as marketplaceTheme } from './marketplace';

import config from '../../util/config';
import { LoginApps } from '../../util/loginApps';
import { Theme } from '@lifeomic/chroma-react/styles';

export const getTheme = (): Theme => {
  const loginApp = config.loginApp ?? LoginApps.phc;

  switch (loginApp) {
    case LoginApps.lifeology:
      return lifeologyTheme;
    case LoginApps.phc:
      return phcTheme;
    case LoginApps.skillspring:
      return skillSpringTheme;
    case LoginApps.marketplace:
      return marketplaceTheme;
    default:
      return defaultTheme;
  }
};
