import * as React from 'react';

const SvgPaintCan = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgPaintCan"
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.033 4.418l7.256 10.086M13.033 4.418L2.65 12.348a1.739 1.739 0 00-.7 1.062 1.542 1.542 0 00.268 1.218l4.716 6.556a1.565 1.565 0 001.052.616 1.713 1.713 0 001.247-.3l10.383-7.931"
    />
    <path
      d="M13.674 1.053v11.365h0a2.086 2.086 0 11-3.559-1.476M20.29 18l1.419 2.111h0a1.792 1.792 0 11-2.936.14h0l.007-.011z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgPaintCan;
