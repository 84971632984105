import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { FormikTextField } from '@lifeomic/phc-web-toolkit/dist/components/ChromaFormik';
import useValidators from '../../hooks/useValidatiors';
import { Button } from '@lifeomic/chroma-react/components/Button';
import { useResetPasswordEndpoint } from '../../hooks/api';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import { Collapse, Fade } from '@mui/material';
import { ResetPasswordResponse } from '../../hooks/api/useResetPasswordEndpoint';
import PasswordCriteria from '../common/PasswordCriteria';
import { push } from 'connected-react-router';
import urls from '../../util/urls';
import { getTestProps } from '../../util/testProps';
import { loginSelectors } from '../../redux/modules/Login';
import { ologyColors } from '../../styles';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';
import { Hash, Lock } from '@lifeomic/chromicons';
import clsx from 'clsx';
import { FormBox } from '@lifeomic/chroma-react/components/FormBox';

const messages = {
  username: {
    id: 'resetPasswordForm.resetCode',
    defaultMessage: 'Reset Code',
  },
  password: {
    id: 'resetPasswordForm.password',
    defaultMessage: 'Password',
  },
  reenterPassword: {
    id: 'resetPasswordForm.reenterPassword',
    defaultMessage: 'Confirm Password',
  },
  misMatchedPassword: {
    id: 'resetPasswordForm.misMatchedPassword',
    defaultMessage: 'Passwords must match',
  },
  resetSuccess: {
    id: 'resetPasswordForm.sendSuccess',
    defaultMessage: 'Successfully reset password',
  },
  resetSuccessVerbose: {
    id: 'resetPasswordForm.sendSuccessVerbose',
    defaultMessage:
      'You have successfully reset your password.  You may now close the browser window.',
  },
  resetPassword: {
    id: 'resetPasswordForm.resetPassword',
    defaultMessage: 'Reset password',
  },
};

export const testIds = {
  resetCode: 'resetPasswordForm-resetCode',
  password: 'resetPasswordForm-password',
  reenterPassword: 'resetPasswordForm-reenterPassword',
  submitButton: 'resetPasswordForm-submitButton',
};

export type FormValues = {
  resetCode: string;
  password: string;
  reenteredPassword: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  forgotPasswordButton: {
    marginTop: theme.spacing(1),
    height: 40,
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
  lifeologyButton: {
    borderRadius: theme.pxToRem(20),
    backgroundColor: 'none',
    backgroundImage: ologyColors.btnGradient,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundImage: ologyColors.btnGradientHover,
    },
    '&:disabled': {
      backgroundImage: ologyColors.btnGradient,
      opacity: 0.65,
    },
  },
  criteria: {
    marginTop: theme.spacing(1),
  },
  collapseContainer: {
    '&.MuiCollapse-hidden': {
      marginBottom: 0,
    },
  },
}));

export type ResetPasswordFormClasses = GetClasses<typeof useStyles>;

export interface ResetPasswordFormOwnProps {
  username: string;
  resetCode?: string;
  noRedirect?: string;
}

export type ResetPasswordFormProps = ResetPasswordFormOwnProps;

const initialValues: FormValues = {
  resetCode: '',
  password: '',
  reenteredPassword: '',
};

function getInitialValues(props: ResetPasswordFormProps) {
  return {
    ...initialValues,
    resetCode: props.resetCode || initialValues.resetCode,
  };
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const validators = useValidators();
  const passwordValidator = validators.password;
  const dispatch = useDispatch();
  const loginApp = useLoginApp();
  const isLifeologyLogin = loginApp === LoginApps.lifeology;
  const [resetPasswordRequestState, resetPassword] = useResetPasswordEndpoint();
  const [success, setSuccess] = React.useState(null);
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);

  const onSubmit = React.useCallback(
    (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      const { resetCode, password } = formValues;

      resetPassword(
        resetCode,
        password,
        props.username,
        (_axiosResponse: AxiosResponse<ResetPasswordResponse>) => {
          dispatch(
            notificationsActions.addNotification(
              formatMessage(messages.resetSuccess),
              { statusType: 'success' }
            )
          );
          if (!props.noRedirect) {
            dispatch(push(urls.app.roots.login({ originalUrl })));
          } else {
            setSuccess(true);
          }
        }
      );

      actions.setSubmitting(false);
    },
    []
  );

  const validateForm = React.useCallback((formValues: FormValues) => {
    const errors: any = {};

    if (
      !passwordValidator(formValues.password) &&
      !!formValues.reenteredPassword &&
      formValues.password !== formValues.reenteredPassword
    ) {
      const mismatchedPasswordError = formatMessage(
        messages.misMatchedPassword
      );
      errors.password = mismatchedPasswordError;
      errors.reenteredPassword = mismatchedPasswordError;
    }

    return errors;
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={getInitialValues(props)}
      validate={validateForm}
    >
      {(formikProps: FormikProps<FormValues>) => {
        const showReenterPassword = !passwordValidator(
          formikProps.values.password
        );

        return !success ? (
          <Form>
            <FormBox>
              <Field
                {...getTestProps(testIds.resetCode)}
                aria-label="Reset Code"
                fullWidth
                name="resetCode"
                component={FormikTextField}
                placeholder={formatMessage(messages.username)}
                validate={validators.required}
                startAdornment={<Hash aria-hidden />}
              />
              <Field
                {...getTestProps(testIds.password)}
                aria-label="Password"
                fullWidth
                name="password"
                autoComplete="new-password"
                component={FormikTextField}
                placeholder={formatMessage(messages.password)}
                validate={passwordValidator}
                type="password"
                startAdornment={<Lock aria-hidden />}
              />
              <Collapse
                in={showReenterPassword}
                className={classes.collapseContainer}
              >
                <Fade in={showReenterPassword}>
                  <div>
                    <Field
                      {...getTestProps(testIds.reenterPassword)}
                      aria-label="Re-enter Password"
                      fullWidth
                      name="reenteredPassword"
                      autoComplete="new-password"
                      component={FormikTextField}
                      type="password"
                      placeholder={formatMessage(messages.reenterPassword)}
                      validate={validators.required}
                      startAdornment={<Lock aria-hidden />}
                    />
                  </div>
                </Fade>
              </Collapse>
              <PasswordCriteria
                className={classes.criteria}
                passwordValue={formikProps.values.password}
              />
              <Button
                {...getTestProps(testIds.submitButton)}
                className={clsx(
                  classes.forgotPasswordButton,
                  isLifeologyLogin && classes.lifeologyButton
                )}
                disabled={
                  resetPasswordRequestState.isLoading ||
                  formikProps.isSubmitting ||
                  !formikProps.isValid
                }
                type="submit"
                variant="contained"
              >
                {formatMessage(messages.resetPassword)}
              </Button>
            </FormBox>
          </Form>
        ) : (
          <div>{formatMessage(messages.resetSuccessVerbose)}</div>
        );
      }}
    </Formik>
  );
};

export default React.memo(ResetPasswordForm);
