import config from '../util/config';
import { useSelector } from 'react-redux';
import { loginSelectors } from '../redux/modules/Login';

function useIsCustomSSOProvider() {
  // if a subdomain was found in the login query params, an API will request the client ID to use for that subdomain/account
  const subDomainOAuthClientId = useSelector(
    loginSelectors.selectCustomClientId
  );

  // if the subdomain's client ID is different from the configured (default) client ID, a custom provider is being used
  return (
    !!config.oauthClientId &&
    !!subDomainOAuthClientId &&
    config.oauthClientId !== subDomainOAuthClientId
  );
}

export default useIsCustomSSOProvider;
