import * as React from 'react';

const SvgCloudLightning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCloudLightning"
    {...props}
  >
    <path
      d="M15.543 6.8a5.438 5.438 0 011.8 0 4.283 4.283 0 012.238 1.175 4.618 4.618 0 011.262 2.225 4.457 4.457 0 01-2.6 5.1m-12.2-.3a4.144 4.144 0 01-.587-.362 4.491 4.491 0 01-.513-.438 7.3 7.3 0 01-1.4-2.162 6.613 6.613 0 01-.5-2.538 6.54 6.54 0 011.888-4.561A6.32 6.32 0 019.443 3a6.351 6.351 0 014.65 1.863 6.527 6.527 0 011.95 4.637m-4.7 2.9l1.4 2.1 1.4 2.1h-4.2l1.5 2.2 1.5 2.2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCloudLightning;
