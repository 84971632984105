/**
 * This function determines the app from the original url. The original url must be part of the query parameter.
 * The method returns undefined if the determination cannot be made.
 *
 * @param href
 * @returns {string|undefined}
 */
function getAppFromOriginalUrl(href) {
  if (!href) {
    return undefined;
  }

  let url;
  try {
    url = new URL(href);
  } catch (error) {
    console.error('Unable to retrieve app from original url! Invalud href!');
    return undefined;
  }

  const loginAppParams = url.searchParams;
  if (!loginAppParams) {
    return undefined;
  }

  const originalUrlString = url.searchParams.get('originalUrl');
  if (!originalUrlString) {
    return undefined;
  }

  let originalUrl;
  try {
    originalUrl = new URL(originalUrlString);
  } catch (error) {
    console.error('Invalid originalUrl!');
    return undefined;
  }

  if (!originalUrl || !originalUrl.pathname || originalUrl.pathname === '/') {
    return undefined;
  }
  const app = originalUrl.pathname.split('/');
  if (!app || app.length < 1 || !app[1]) {
    return undefined;
  }
  return app[1].toLowerCase();
}

module.exports = {
  getAppFromOriginalUrl,
};
