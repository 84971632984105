import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';

import urls from '../../util/urls';

const messages = {
  apiErrorMessage: {
    id: 'useUniqueUsernameEndpoint.apiErrorMessage',
    defaultMessage:
      'An error occurred when checking username. Error: "{error}"',
  },
  genericError: {
    id: 'useUniqueUsernameEndpoint.genericError',
    defaultMessage: 'An error occurred when checking username.',
  },
};

/**
 * This does not use the "useApi" utility on purpose, because it's only
 * being called imperatively right now.
 */
const useUniqueUsernameEndpoint = (): ((email: string) => Promise<string>) => {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;

  const makeRequest = async (email: string) => {
    try {
      const response = await Axios.get<{ username: string }>(
        urls.api.auth.uniqueUsername,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
          },
          params: {
            email,
          },
        }
      );

      return response.data.username;
    } catch (error) {
      const errorMessage = get(error, 'response.data.error', null) as string;
      const notificationMessage = errorMessage
        ? formatMessage(messages.apiErrorMessage, { error: errorMessage })
        : formatMessage(messages.genericError);

      dispatch(
        notificationsActions.addNotification(notificationMessage, {
          statusType: 'error',
        })
      );
    }
  };

  return makeRequest;
};

export default useUniqueUsernameEndpoint;
