import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import AuthPromptPaper from '../common/AuthPromptPaper';
import ForgotPasswordForm from './ForgotPasswordForm';
import PromptHeader from '../common/PromptHeader';
import PromptSubHeader from '../common/PromptSubHeader';
import ResetPasswordForm from './ResetPasswordForm';
import queryString from 'query-string';
import { withRouter, RouteProps } from 'react-router-dom';

const messages = {
  forgotHeader: {
    id: 'forgotHeader.header',
    defaultMessage: 'Forgot Password?',
  },
  resetHeader: {
    id: 'resetHeader.header',
    defaultMessage: 'Reset Password',
  },
  forgotSubHeader: {
    id: 'forgotSubHeader.subHeader',
    defaultMessage:
      "Enter your username or email and we'll send a password reset code to your email.",
  },
  resetSubHeader: {
    id: 'resetSubHeader.subHeader',
    defaultMessage:
      'You should have received a reset confirmation code in your email.  Enter the reset code and your new password.',
  },
  resetSubHeaderPreFilled: {
    id: 'resetSubHeader.subHeaderPreFilled',
    defaultMessage: 'Enter your new password in the form below.',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  authPromptPaper: {
    paddingBottom: theme.spacing(4),
  },
  formContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
}));

export type ForgotPasswordClasses = GetClasses<typeof useStyles>;

export type ForgotPasswordOwnProps = {};

export type ForgotPasswordProps = ForgotPasswordOwnProps & RouteProps;

export interface ForgotPasswordQueryStringParameters {
  noRedirect?: string;
  resetCode?: string;
  username?: string;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const queryStringParams: ForgotPasswordQueryStringParameters =
    queryString.parse(props.location.search);
  const { noRedirect, resetCode, username } = queryStringParams;
  const formatMessage = useIntl().formatMessage;
  const [resetPasswordUsername, setResetPasswordUsername] =
    React.useState(username);
  const classes = useStyles(props);

  const header = resetPasswordUsername
    ? formatMessage(messages.resetHeader)
    : formatMessage(messages.forgotHeader);

  let subHeader;
  if (resetPasswordUsername) {
    if (resetCode) {
      subHeader = formatMessage(messages.resetSubHeaderPreFilled, {
        username: resetPasswordUsername,
      });
    } else {
      subHeader = formatMessage(messages.resetSubHeader, {
        username: resetPasswordUsername,
      });
    }
  } else {
    subHeader = formatMessage(messages.forgotSubHeader);
  }

  const inputForm = resetPasswordUsername ? (
    <ResetPasswordForm
      username={resetPasswordUsername}
      resetCode={resetCode}
      noRedirect={noRedirect}
    />
  ) : (
    <ForgotPasswordForm onSendSuccess={setResetPasswordUsername} />
  );

  return (
    <AuthPromptPaper className={classes.authPromptPaper}>
      <PromptHeader>{header}</PromptHeader>
      <PromptSubHeader>{subHeader}</PromptSubHeader>
      <div className={classes.formContainer}>{inputForm}</div>
    </AuthPromptPaper>
  );
};

export default React.memo(withRouter(ForgotPassword));
