/**
 * Determines if the app is an sof app that came from Cerner's PowerChart. Cerner's PowerChart uses an embedded IE and
 * requires additional polyfills. This check ensures that those polyfills are only done when the app is loaded via
 * Cerner's PowerChart.
 *
 * Taken from https://github.com/cerner/fhir-client-cerner-additions/blob/main/src/js/utils.js#L14
 */
import { getAppFromOriginalUrl } from './getAppFromOriginalUrl';

export default function () {
  const app = getAppFromOriginalUrl(window.location.href);
  return (
    app === 'sof' &&
    window.external &&
    Object.prototype.hasOwnProperty.call(
      window.external,
      'DiscernObjectFactory'
    )
  );
}
