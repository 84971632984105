import { createPalette, createTheme } from '@lifeomic/chroma-react/styles';
import chromaBlue from '@lifeomic/chroma-react/colors/blue';

const marketplaceBlue = {
  ...chromaBlue,
  800: '#126FFB',
};

export const palette = createPalette({
  primary: {
    ...marketplaceBlue,
    main: marketplaceBlue[800],
  },
});

export const theme = createTheme({
  palette,
});
