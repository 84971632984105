import { Theme } from '@lifeomic/chroma-react/styles';
import { Components } from '@lifeomic/chroma-react/styles/overrides/ChromaComponents';
import { alpha } from '@mui/material/styles';
import {
  primaryGradient270Deg as primaryGradient270DegStyles,
  SEA_GREEN,
} from './styles/content';

export const overrides = (theme: Theme): Components => ({
  ChromaAvatar: {
    styleOverrides: {
      root: {
        borderColor: theme.palette.common.white,
        '&:hover, &:focus': {
          borderColor: alpha(theme.palette.common.white, 0.5),
        },
      },
      clickable: {
        '&:hover': {
          borderColor: alpha(theme.palette.common.white, 0.5),
        },
      },
      defaultSrc: {
        fill: 'none',
        stroke: theme.palette.primary.main,
        strokeWidth: theme.pxToRem(5),
      },
    },
  },
  ChromaButtonFloat: {
    styleOverrides: {
      root: {
        ...primaryGradient270DegStyles,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          backgroundImage: 'none',
        },
      },
    },
  },
  ChromaDotLoader: {
    styleOverrides: {
      root: {
        '& > circle': {
          '&:nth-child(1)': {
            fill: theme.palette.blue.main,
          },
          '&:nth-child(2)': {
            fill: '#3170a8',
          },
          '&:nth-child(3)': {
            fill: '#437b79',
          },
          '&:nth-child(4)': {
            fill: SEA_GREEN,
          },
        },
      },
    },
  },
  ChromaHeader: {
    styleOverrides: {
      root: {
        ...primaryGradient270DegStyles,
      },
      logo: {
        marginRight: theme.spacing(0.5),
      },
      centerLogo: {
        [theme.breakpoints.down('lg')]: {
          margin: theme.spacing(0, 1),
          position: 'unset',
          transform: 'unset',
        },
      },
    },
  },
  ChromaIconButtonFloat: {
    styleOverrides: {
      root: {
        ...primaryGradient270DegStyles,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          backgroundImage: 'none',
        },
      },
    },
  },
  ChromaLayoutManager: {
    styleOverrides: {
      sidebar: {
        boxShadow: `0px 4px 24px ${theme.hexToRgba(
          theme.palette.common.black,
          0.05,
        )}`,
      },
    },
  },
  ChromaLinearProgress: {
    styleOverrides: {
      root: {
        '--linear-progress-bar-color':
          primaryGradient270DegStyles.backgroundImage,
      },
    },
  },
  ChromaPageHeader: {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.graphite[100], 0.75),
        backgroundImage: 'none',
      },
    },
  },
  ChromaPill: {
    styleOverrides: {
      highlight: {
        ...primaryGradient270DegStyles,
        borderBottomColor: theme.palette.primary.dark,
        borderRightColor: theme.palette.primary.dark,
      },
    },
  },
  ChromaPageLayout: {
    styleOverrides: {
      backgroundCover: {
        backgroundColor: 'transparent',
        backgroundImage: 'none',
      },
    },
  },
  ChromaPrimaryNavigation: {
    styleOverrides: {
      root: {
        '--link-color': theme.palette.text.primary,
        '--link-hover': theme.palette.blue.main,
        '--link-active': theme.palette.blue.main,
        '--link-active-bg': alpha(theme.palette.graphite.main, 0.15),
        backgroundColor: theme.palette.common.white,
        paddingTop: 0,
        '& > div > svg': {
          color: theme.palette.black[500],
        },
      },
      footToggle: {
        color: theme.palette.text.primary,
        '& svg': {
          color: theme.palette.black[500],
        },
        '&:hover': {
          color: 'var(--link-hover)',
          '& svg': {
            color: 'var(--link-hover)',
          },
        },
      },
      navItems: {
        paddingTop: theme.pxToRem(12),
      },
    },
  },
  ChromaPrimaryNavigationItem: {
    styleOverrides: {
      link: {
        '&:hover': {
          '& > div > svg': {
            color: 'var(--link-hover)',
          },
        },
      },
      linkActive: {
        color: theme.palette.text.primary,
        '&:hover': {
          opacity: 1,
        },
      },
      icon: {
        color: theme.palette.black[500],
      },
    },
  },
  ChromaPrimaryNavigationExpansionItem: {
    styleOverrides: {
      link: {
        '&:hover': {
          '& > div > svg': {
            color: 'var(--link-hover)',
          },
        },
      },
      linkActive: {
        color: theme.palette.text.primary,
        '&:hover': {
          opacity: 1,
        },
        '& > div > svg': {
          color: theme.palette.black[500],
        },
      },
      icon: {
        color: theme.palette.black[500],
      },
    },
  },
  ChromaTableModule: {
    styleOverrides: {
      tableModuleActions: {
        ...primaryGradient270DegStyles,
        '&:empty': {
          display: 'none',
        },
      },
    },
  },
  ChromaText: {
    styleOverrides: {
      headline: {
        lineHeight: 1.65,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      checked: {},
      colorPrimary: {
        '&$checked': {
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      colorSecondary: {
        '&$checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: theme.palette.primary.main,
      },
      colorSecondary: {
        color: theme.palette.secondary.main,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        color: theme.palette.common.white,
        backgroundImage: `linear-gradient(135deg, #0abff1 0%, #0096e1 22%, rgb(166, 66, 254) 92%)`,
        '&:hover': {
          backgroundImage: `linear-gradient(135deg, #0abff1 0%, #0096e1 7.5%, rgb(166, 66, 254) 92%)`,
        },
        '&:focus': {
          outline: 'none',
        },
        '&$focusVisible': {
          backgroundColor: theme.palette.primary.main,
          backgroundImage: 'none',
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      focused: {},
      root: {
        '&$focused': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:after': {
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      barColorPrimary: {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      colorPrimary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      colorSecondary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      checked: {},
      colorPrimary: {
        '&$checked': {
          color: theme.palette.primary.main,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: `solid 1px ${theme.palette.divider}`,
      },
      head: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[900],
        textTransform: 'uppercase',
      },
      body: {
        fontSize: theme.typography.subtitle1.fontSize,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&$selected': {
          backgroundColor: theme.palette.grey[300],
        },
        '&$hover:hover': {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
  },
});
