import useAPI, { RequestConfig } from './useApi';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { notificationsActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/notifications';
import { appErrorsActions } from '../../redux/modules/AppErrors';
import urls from '../../util/urls';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import config from '../../util/config';
import { loginSelectors } from '../../redux/modules/Login';

const messages = {
  apiErrorMessage: {
    id: 'signupEndpoint.apiErrorMessage',
    defaultMessage: 'An error occurred when signing up user.  Error: "{error}"',
  },
  genericError: {
    id: 'signupEndpoint.genericError',
    defaultMessage: 'An error occurred when signing up user.',
  },
  useAlreadyExistsError: {
    id: 'signupEndpoint.useAlreadyExistsError',
    defaultMessage:
      'Username or email already taken. When selecting a username and email for your new account, you must find one that is not already in use by another user.',
  },
};

function useSignupEndpoint() {
  const dispatch = useDispatch();
  const formatMessage = useIntl().formatMessage;
  const customClientId = useSelector(loginSelectors.selectCustomClientId);

  const clientId = customClientId || config.oauthClientId;

  if (!clientId) {
    const error = new Error(
      'A client ID could not be found in environment configuration'
    );
    dispatch(appErrorsActions.registerError(error));
  }

  const onFailure = (error: AxiosError) => {
    const errorMessage = get(error, 'response.data.error', null) as string;

    let notificationMessage = errorMessage
      ? formatMessage(messages.apiErrorMessage, { error: errorMessage })
      : formatMessage(messages.genericError);

    if (errorMessage === 'User already exists') {
      notificationMessage = formatMessage(messages.useAlreadyExistsError);
    }

    dispatch(
      notificationsActions.addNotification(notificationMessage, {
        statusType: 'error',
      })
    );
  };

  const makeAPIRequest = (
    username: string,
    password: string,
    email: string,
    originalUrl: string,
    onSuccess: () => void,
    firstName?: string,
    lastName?: string,
    recaptchaToken?: string,
    inviteId?: string,
    evc?: string,
    app?: string,
    destination?: string
  ): RequestConfig => ({
    url: urls.api.auth.signup,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      givenName: firstName,
      familyName: lastName,
      username,
      password,
      originalUrl,
      email,
      clientId,
      recaptchaToken,
      inviteId,
      evc,
      app,
      destination,
    },
    onSuccess,
    onFailure,
  });

  return useAPI(makeAPIRequest);
}

export default useSignupEndpoint;
