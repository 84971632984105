import React from 'react';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';
import PhcSignInButton from './PhcSignInButton';
import SharedSignInButton from './SharedSignInButton';

export const appSignInButtons: Record<
  LoginApps,
  React.FC<SignInButtonProps>
> = {
  [LoginApps.phc]: PhcSignInButton,
  [LoginApps.lifeology]: SharedSignInButton,
  [LoginApps.marketplace]: PhcSignInButton,
  [LoginApps.skillspring]: SharedSignInButton,
};

export type SignInButtonOwnProps = {
  className?: string;
  sso?: boolean;
};

export type SignInButtonProps = SignInButtonOwnProps;

const SignInButton = (props: SignInButtonProps) => {
  const loginApp = useLoginApp();

  return appSignInButtons[loginApp](props);
};

export default React.memo(SignInButton);
