import get from 'lodash/get';
import { createSelector } from 'reselect';
import { AppErrorsState } from './reducer';

const selectAppErrorsState = (state: any): AppErrorsState => state.appErrors;

const emptyErrorArray: Error[] = [];

const selectAppErrors = createSelector(
  selectAppErrorsState,
  (appErrorsState) => appErrorsState.errors || emptyErrorArray
);

const selectHasAppErrorsOccurred = createSelector(
  selectAppErrors,
  (appErrors) => !!appErrors.length
);

const selectErrorMessages = createSelector(selectAppErrors, (appErrors) =>
  appErrors
    .map((error) => get<Error, 'message', string>(error, 'message', null))
    .filter(Boolean)
);

export default {
  selectAppErrors,
  selectHasAppErrorsOccurred,
  selectErrorMessages,
};
