import { createTheme, Theme } from '@lifeomic/chroma-react/styles';
import { IThemeOverride } from '../theme';
import { overrides as componentOverrides } from './overrides';
import { palette } from './palette';

declare module '@lifeomic/chroma-react/styles/createTheme' {
  interface Theme {
    lifeology: {
      themeable: IThemeOverride;
    };
  }

  interface ThemeOptions {
    lifeology?: {
      themeable?: IThemeOverride;
    };
  }
}

const LIFEOLOGY_THEMEABLE_DEFAULTS = {
  headerBackgroundColor: undefined,
  headerTextColor: undefined,
  cardTextColor: undefined,
  navigationIconColor: undefined,
  navigationIconHoverColor: undefined,
  footerButtonBackgroundColor: undefined,
  footerButtonHoverBackgroundColor: undefined,
  footerButtonIconColor: undefined,
  footerButtonIconHoverColor: undefined,
  progressBarBackground: undefined,
  progressBarStartColor: undefined,
  progressBarEndColor: undefined,
  logoImage: undefined,
  backgroundColor: undefined,
  buttonBackgroundColor: undefined,
  buttonTextColor: undefined,
  buttonHoverColor: undefined,
  linkColor: undefined,
  linkHoverColor: undefined,
  navArrowColor: undefined,
  navArrowHoverColor: undefined,
  allowSharing: true,
  allowPrinting: true,
};

const lifeology = {
  themeable: LIFEOLOGY_THEMEABLE_DEFAULTS,
};

export const createDefaultTheme = (options: any) => {
  return createTheme({
    palette,
    ...options,
    lifeology: {
      themeable: {
        ...LIFEOLOGY_THEMEABLE_DEFAULTS,
        ...options.lifeology.themeable
      }
    },
    components: componentOverrides,
  });
};

export const theme: Theme = createTheme({
  palette,
  //@ts-ignore
  lifeology,
  components: componentOverrides,
});
