import takeRight from 'lodash/takeRight';
import appErrorsActionTypes from './actionTypes';
import createReducer from '../../util/createReducer';
import { RegisterErrorAction } from './actions';

export interface AppErrorsState {
  errors: Error[];
}

export const MAX_ERROR_LIST_LENGTH = 20;

const INITIAL_STATE: AppErrorsState = {
  errors: [],
};

const appErrorsReducer = createReducer(INITIAL_STATE, {
  [appErrorsActionTypes.APP_ERRORS_REGISTER_ERROR]: (
    state: AppErrorsState,
    action: RegisterErrorAction
  ): AppErrorsState => ({
    ...state,
    errors: takeRight([...state.errors, action.error], MAX_ERROR_LIST_LENGTH),
  }),
});

export default appErrorsReducer;
