import React from 'react';
import { useSelector } from 'react-redux';
import { appErrorSelectors } from '../../redux/modules/AppErrors';
import ErrorDisplay from './ErrorDisplay';
import { BugsnagErrorBoundary } from '../../util/errorReporting';
import { BugsnagErrorBoundaryProps } from '../../util/errorReporting/BugsnagErrorBoundary';

export type ErrorHandlerOwnProps = {};

export type ErrorHandlerProps = ErrorHandlerOwnProps &
  BugsnagErrorBoundaryProps;

const ErrorHandler: React.FC<ErrorHandlerProps> = (
  props: ErrorHandlerProps
) => {
  const hasRegisteredAppError = useSelector(
    appErrorSelectors.selectHasAppErrorsOccurred
  );

  const FallbackComponent = <ErrorDisplay />;

  if (hasRegisteredAppError) return FallbackComponent;

  return (
    <BugsnagErrorBoundary {...props} FallbackComponent={FallbackComponent}>
      {props.children}
    </BugsnagErrorBoundary>
  );
};

export default React.memo(ErrorHandler);
