import * as React from 'react';

const SvgHardDrive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgHardDrive"
    {...props}
  >
    <path
      d="M21 15.664V13.8a2.007 2.007 0 00-2.015-2H5.015A2.007 2.007 0 003 13.8v1.7M7.727 4.806h8.561a1.8 1.8 0 011.61 1L21 12v5.4a1.8 1.8 0 01-1.8 1.8H4.814a1.8 1.8 0 01-1.8-1.8V12l3.1-6.2a1.8 1.8 0 011.613-.994zm-.85 10.857h.863"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgHardDrive;
