import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import clsx from 'clsx';
import { BrandedBackgroundProps } from '.';
import Bkgd from '@lifeomic/phc-web-toolkit/dist/static/images/TessellatedMountains.svg';
import { CustomTheme, useCustomTheme } from '../../../hooks/useCustomTheme';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundImage: `url(${Bkgd})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    backfaceVisibility: 'hidden',
  },
  customThemeRoot: ({ customTheme }: { customTheme: CustomTheme }) => ({
    backgroundImage:
      customTheme?.backgroundImageUrl &&
      `url(${customTheme.backgroundImageUrl})`,
  }),
  stackTop: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2,
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
}));

const PhcBrandedBackground: React.FC<BrandedBackgroundProps> = (
  props: BrandedBackgroundProps
) => {
  const customTheme = useCustomTheme();
  const classes = useStyles({ ...props, customTheme });
  const { className, ...toPass } = props;

  return (
    <div
      className={clsx(
        classes.root,
        className,
        customTheme?.backgroundImageUrl && classes.customThemeRoot
      )}
      {...toPass}
    >
      <div className={classes.stackTop}>{props.children}</div>
    </div>
  );
};

export default PhcBrandedBackground;
