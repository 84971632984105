import reducers from './reducers';
import history from './util/history';
import { routerMiddleware } from 'connected-react-router';
import configureCommonStore from '@lifeomic/phc-web-toolkit/dist/redux';
import config from '../util/config';
import { LoginApps } from '../util/loginApps';

const initialConfigState = {
  config: {
    loginApp: config.loginApp ?? LoginApps.phc,
    recaptchaEnabled: config.recaptchaEnabled,
    recaptchaKey: config.recaptchaKey,
  },
};

const configureStore = () =>
  configureCommonStore(initialConfigState, reducers(history) as any, [
    routerMiddleware(history),
  ]);

export default configureStore;
