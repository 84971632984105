import {
  StoredAuth,
  setAuthStorage,
} from '@lifeomic/phc-web-toolkit/dist/util/authStorage/accessors';
import getSafeRedirect, { getSafeCookieParent } from '../util/getSafeRedirect';
import config from './config';
import setLocationHref from './windowMethods';
import { LOAuth } from '@lifeomic/app-tools';

export interface TokenAuthResponse {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
}

export interface OldTokenAuthResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  expiresIn: number;
  tokenType: string;
}

export const tokenResponseToAuthResponse = (
  tokenResponse: TokenAuthResponse = {} as TokenAuthResponse
): AuthResponse => ({
  accessToken: tokenResponse.accessToken,
  refreshToken: tokenResponse.refreshToken,
  idToken: tokenResponse.idToken,
  expiresIn: tokenResponse.expiresIn,
  tokenType: tokenResponse.tokenType,
});

export const oldTokenResponseToAuthResponse = (
  tokenResponse: OldTokenAuthResponse = {} as OldTokenAuthResponse
): AuthResponse => ({
  accessToken: tokenResponse.access_token,
  refreshToken: tokenResponse.refresh_token,
  idToken: tokenResponse.id_token,
  expiresIn: tokenResponse.expires_in,
  tokenType: tokenResponse.token_type,
});

const mapAuthResponseToStoredAuth = (
  authResponse: AuthResponse,
  preRequestTime: Date,
  email: string,
  customClientId?: string
): StoredAuth => ({
  isAuthenticated: true,
  isProcessing: false,
  // convert cognito seconds to js date ms
  expiresAt: new Date(
    preRequestTime.getTime() + authResponse.expiresIn * 1000
  ).getTime(),
  accessToken: authResponse.accessToken,
  refreshToken: authResponse.refreshToken,
  idToken: authResponse.idToken,
  jwtPayload: null,
  email,
  oauthClientId: customClientId || config.oauthClientId,
  error: null,
  errorDescription: null,
});

export default (
  authResponse: AuthResponse,
  preRequestTime: Date,
  email: string,
  redirectUrl: string,
  customClientId?: string
) => {
  const storedAuth = mapAuthResponseToStoredAuth(
    authResponse,
    preRequestTime,
    email,
    customClientId
  );
  setAuthStorage(storedAuth);

  // set a 10 second lived cookie on the parent domain to
  // let safe redirect apps use the tokens
  const cookieDomain = getSafeCookieParent(window.location.href);
  LOAuth.setDomainCookieAuthState({
    // eslint-disable-next-line @typescript-eslint/camelcase
    access_token: storedAuth.accessToken,
    // eslint-disable-next-line @typescript-eslint/camelcase
    refresh_token: storedAuth.refreshToken,
    expires: storedAuth.expiresAt,
    clientId: storedAuth.oauthClientId,
    cookieDomain,
  });

  setLocationHref.setLocationHref(
    getSafeRedirect(redirectUrl, window.location.href)
  );
};
