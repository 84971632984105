import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Text } from '@lifeomic/chroma-react/components/Text';
import useIsMobile from '../../../hooks/useIsMobile';
import clsx from 'clsx';
import SignInButton from '../SignInButton';
import logo from '../../../static/images/athena-shield.svg';
import { SignInContainerProps } from '.';

const messages = {
  welcomeBack: {
    id: 'signInContainer.welcomeBack',
    defaultMessage: 'Welcome Back!',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent none',
  },
  contentRoot: {
    flex: 1,
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileRootVariant: {
    flex: 'unset',
    height: 'unset',
    width: '100',
    minHeight: 'unset',
    padding: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  logo: {
    width: 120,
  },
  mobileLogoVariant: {
    width: 100,
    height: 111,
  },
  welcomeBackText: {
    fontSize: 30,
    fontWeight: 900,
    marginBottom: theme.spacing(10),
    letterSpacing: theme.pxToRem(-1.25),
  },
  toggleContainer: {
    width: '100%',
    height: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '1em',
    '&:empty': {
      height: 0,
    },
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  loginBtn: {
    borderRadius: theme.pxToRem(20),
  },
}));

export type SignInContainerlasses = GetClasses<typeof useStyles>;

const LifeologySignInContainer = (props: SignInContainerProps) => {
  const formatMessage = useIntl().formatMessage;
  const classes = useStyles(props);
  const isMobile = useIsMobile();

  return (
    <div className={classes.root}>
      {!isMobile && (
        <div className={classes.toggleContainer}>{/*for spacing*/}</div>
      )}
      <div
        className={clsx(
          classes.contentRoot,
          isMobile && classes.mobileRootVariant
        )}
      >
        <img
          className={clsx(classes.logo, isMobile && classes.mobileLogoVariant)}
          src={logo}
          alt="Lifeology"
        />
        <Text useH1 size="headline" className={classes.welcomeBackText}>
          {formatMessage(messages.welcomeBack)}
        </Text>

        <SignInButton className={classes.loginBtn} />
      </div>
    </div>
  );
};

export default LifeologySignInContainer;
