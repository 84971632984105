import * as React from 'react';

const SvgWifiOff = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgWifiOff"
    {...props}
  >
    <path
      d="M15.892 10.787a8.536 8.536 0 012.155 1.645M6.485 6.832a12.682 12.682 0 00-3.479 2.45m18 .192a12.6 12.6 0 00-9.09-3.865m3.148 9.807a4.226 4.226 0 00-6.121-.2m.857-5.157a8.456 8.456 0 00-3.836 2.181m6.041 6.05v-.009M3 3.046l18 17.936"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgWifiOff;
