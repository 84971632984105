import { Theme } from '@lifeomic/chroma-react/styles';
import { Components } from '@lifeomic/chroma-react/styles/overrides/ChromaComponents';
import { alpha } from '@mui/material';

const bkgdHoverColor = 'rgb(241, 252, 252)'; // equivalent to lighten(theme.palette.primary[50], 0.6);

export const overrides = (theme: Theme): Components => ({
  ChromaAvatar: {
    styleOverrides: {
      img: {
        position: 'absolute',
        top: 0,
        left: 0,
      },
    }
  },
  ChromaButton: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.85)
        }
      },
      containedInverse: {
        color: theme.palette.black[600],
        '&:hover': {
          color: theme.palette.black[600]
        }
      },
      outlined: {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark
      },
      text: {
        color: theme.palette.primary.dark,
        '&:hover': {
          color: theme.palette.primary.contrastText
        }
      }
    }
  },
  ChromaDotLoader: {
    styleOverrides: {
      root: {
        '& > circle': {
          fill: theme.palette.primary[400]
        }
      }
    }
  },
  ChromaIconButton: {
    styleOverrides: {
      root: {
        '& > svg': {
          width: 32,
          height: 32,
        },
        '&:hover, &:focus': {
          color: theme.lifeology?.themeable?.footerButtonIconHoverColor || theme.palette.primary[800],
        },
        '&:focus': {
          '&.focus-visible': {
            boxShadow: '0 0 0 2px ' + alpha(theme.lifeology?.themeable?.footerButtonIconHoverColor || theme.palette.primary.main, 0.3),
          },
        },
      },
    }
  },
  ChromaLayoutManager: {
    styleOverrides: {
      sidebar: {},
    }
  },
  ChromaModal: {
    styleOverrides: {
      content: {
        overflow: 'hidden',
      },
      modalChildrenContainer: {
        maxHeight: '50vh',
        background: theme.palette.background.paper,
      },
    }
  },
  ChromaPrimaryNavigation: {
    styleOverrides: {
      header: {
        display: 'none',
      },
      root: {
        backgroundColor: theme.palette.common.white,
      },
      footToggle: {
        color: theme.palette.black[400],
        '&[aria-checked=true]': {
          color: theme.palette.black[400],
          '& > svg': {
            marginRight: 0,
          },
        },
      },
    }
  },
  ChromaPrimaryNavigationItem: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
      },
      icon: {
        outline: 'none',
      },
      link: {
        position: 'relative',
        '&:focus': {
          outline: 'none',
          color: theme.palette.primary.main,
        },
        '&:before': {
          content: '""',
          display: 'block',
          width: 5,
          top: 0,
          left: 0,
          height: '100%',
          background: theme.palette.primary.main,
          position: 'absolute',
          transform: 'translateX(-5px)',
          transition: 'transform 0.25s',
          '@media (prefers-reduced-motion: reduce)': {
            transform: 'unset',
            transition: 'unset',
          },
          borderRadius: '0 5px 5px 0',
        },
      },
      linkActive: {
        '&:before': {
          transform: 'translateX(0)',
        },
      },
    }
  },
  ChromaRadioGroupMinimal: {
    styleOverrides: {
      radios: {
        '&&': {
          '& input:checked + div label > p': {
            color: theme.palette.text.primary,
          }
        }
      }
    }
  },
  ChromaSelectOption: {
    styleOverrides: {
      checked: {
        '&::before': {
          background: theme.palette.primary[600]
        },
        '& *': {
          color: theme.palette.primary[600]
        }
      }
    }
  },
  ChromaRoverOption: {
    styleOverrides: {
      option: {
        '&:hover, &:focus': {
          backgroundColor: bkgdHoverColor,
        },
      },
    }
  },
  MuiTableCell: {
    styleOverrides: { 
      root: {
        borderBottom: `solid 1px ${theme.palette.divider}`,
      },
    }
  },
});
