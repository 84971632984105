import {
  Color,
  SimplePaletteColorOptions,
} from '@lifeomic/chroma-react/esm/colors/types';

/*
  Lifeology - Teal Scale
*/

const shades: Color | any = {
  50: '#ddf8f8',
  100: '#a9ecee',
  200: '#6ae0e4',
  300: '#00d2da',
  400: '#00c8d3',
  500: '#00bdcd',
  600: '#00adbb',
  700: '#0098a1',
  800: '#008489',
  900: '#00615e',
};

const lifeologyTeal: SimplePaletteColorOptions & Color = {
  ...shades,
  main: '#6ae0e4',
  light: '#ddf8f8',
  dark: '#008489',
  contrastText: '#00615e',
};

export default lifeologyTeal;
