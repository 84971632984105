import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import Grid from '@mui/material/Grid';
import { useIntl, MessageDescriptor } from 'react-intl';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  errorMessage: {
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.text.primary,
  },
}));

export interface ErrorFallbackOwnProps {
  intlErrorMessage: MessageDescriptor;
}

export type ErrorFallbackProps = ErrorFallbackOwnProps;

const ErrorFallback: React.FC<ErrorFallbackProps> = (
  props: ErrorFallbackProps
) => {
  const classes = useStyles(props);
  const formatMessage = useIntl().formatMessage;

  if (props.intlErrorMessage) {
    return (
      <Grid container justifyContent="center" className={classes.root}>
        <span className={classes.errorMessage}>
          {formatMessage(props.intlErrorMessage)}
        </span>
      </Grid>
    );
  } else {
    return null;
  }
};

export default React.memo(ErrorFallback);
