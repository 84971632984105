import queryString from 'query-string';
import config from './config';
import { LoginApps } from './loginApps';
import validator from 'validator';

export type SearchParams = { [key: string]: string | string[] };

export type IDP = 'facebook' | 'google' | 'signinwithapple';

export interface DefinedParams {
  redirectDomain?: string;
  subDomain?: string;
  app?: string;
  destination?: string;
  locationHash?: string;
  customClientIdAccount?: string;
  inviteId?: string;
  evc?: string;
}

export interface CognitoRedirectParams {
  code?: string;
  'login-app-redirect-url'?: string;
  clientId?: string;
  state?: string;
}

export interface LogoutParams {
  logout?: 'success' | 'pending';
  idp?: IDP;
}

export type ParsedParams = DefinedParams &
  SearchParams &
  CognitoRedirectParams &
  LogoutParams;

export type InputParams = DefinedParams &
  SearchParams &
  CognitoRedirectParams & {
    originalUrl: string;
  };

export const fullyDecodeURIComponent = (component: string) => {
  let decoded = component;
  while (decoded && decoded.includes('%')) {
    decoded = decodeURIComponent(decoded);
  }
  return decoded;
};

function isIpAddress(domain: string) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    domain
  );
}

const parseSubdomainFromUrl = (url: URL) => {
  if (!url || !url.host || !url.hostname) return null;

  if (isIpAddress(url.hostname)) return null;

  // the target subdomain should be before 'apps', (ex: customer.apps.us.lifeomic.com - "customer" is the subdomain)
  const parts = url.host.split('.');
  if (!parts || !parts.length) return null;

  // there are restrictions when creating vanity urls.  Validate against these restrictions as a safegaurd
  const ensureValidSubdomain = (maybeInvalidSubdomain: string) =>
    /^([a-z0-9]{1,16})$/.test(maybeInvalidSubdomain)
      ? maybeInvalidSubdomain
      : undefined;

  // we're running locally, get the "customer" subdomain
  if (process.env.NODE_ENV === 'development' && parts[1] === 'localhost:8080')
    return ensureValidSubdomain(parts[0]);

  // either "lifeomic.marketplace...." or "lifeomic.apps....."
  const preSubdomainPart =
    parts.indexOf('apps') === -1
      ? parts.indexOf('marketplace')
      : parts.indexOf('apps');
  if (
    preSubdomainPart === -1 ||
    preSubdomainPart === 0 ||
    parts[preSubdomainPart - 1] === 'www'
  )
    return null;

  return ensureValidSubdomain(parts[preSubdomainPart - 1]);
};

const WHITELISTED_DOMAINS = [
  'apps.us.lifeomic.com',
  'apps.dev.lifeomic.com',
  'apps.us.fed.lifeomic.com',
  'app.dev.lifeology.io',
  'app.us.lifeology.io',
  'app.us.fed.lifeology.io',
  'marketplace.dev.lifeomic.com',
  'marketplace.us.lifeomic.com',
  'marketplace.us.fed.lifeomic.com',
  'connect-console.dev.skillspring.com',
  'connect-console.dev.lifeomic.com',
  'apps.dev.skillspring.com',
  'us.fed.skillspring.com',
  'us.skillspring.com',
  'console.us.skillspring.com',
  'console.us.fed.skillspring.com',
];

/**
 * ensure redirect url is not malicious
 */
const sanitizeUrl = (maybeEncodedUrl?: string): string | undefined => {
  // only sanitize absolute urls
  if (
    !maybeEncodedUrl ||
    maybeEncodedUrl[0] === '/' ||
    fullyDecodeURIComponent(maybeEncodedUrl)[0] === '/'
  ) {
    return fullyDecodeURIComponent(maybeEncodedUrl);
  }

  const isValid = (url: string) => {
    // ensure javascript is not injected or escape characters are redirecting to a new domain
    // eslint-disable-next-line @typescript-eslint/camelcase
    const isValidURL = validator.isURL(url, {
      require_protocol: false, // eslint-disable-line @typescript-eslint/camelcase
      require_host: false, // eslint-disable-line @typescript-eslint/camelcase
      require_tld: false, // eslint-disable-line @typescript-eslint/camelcase
    });
    if (!isValidURL) return false;

    // parse out the vanity subdomain.  example "<customer>.apps.lifeomic.com"
    const subdomain = parseSubdomainFromUrl(new URL(url));

    // add explicit check for running locally
    if (
      process.env.NODE_ENV === 'development' &&
      !![
        'https://localhost:',
        `https://${subdomain}.localhost:`,
        'http://localhost:',
        `http://${subdomain}.localhost:`,
      ].find((_) => url.startsWith(_))
    ) {
      return url;
    }

    // check against explicitely whitelisted domains
    const urlDomain = url
      .replace('https://', '')
      .replace('http://', '') // strip protocol
      .split('/')[0]; // check the whole part before the route
    // ensure vanity is added to whitelist urls
    const validDomains = subdomain
      ? WHITELISTED_DOMAINS.map((_) => `${subdomain}.${_}`)
      : WHITELISTED_DOMAINS;

    return validDomains.includes(urlDomain);
  };

  // SEC-936 - prevent XSS attack via malicious redirect "url"
  // eslint-disable-next-line @typescript-eslint/camelcase
  const isValidURL =
    isValid(maybeEncodedUrl) ||
    isValid(fullyDecodeURIComponent(maybeEncodedUrl));
  return isValidURL ? fullyDecodeURIComponent(maybeEncodedUrl) : undefined;
};

const parseOriginalUrl = (originalUrl: string): ParsedParams => {
  const emptyReturnVal = {} as ParsedParams;

  const sanitized = sanitizeUrl(originalUrl);
  try {
    if (!sanitized) return emptyReturnVal;

    const asUrl = new URL(sanitized);
    const splitPath = (asUrl.pathname || '').split('/');

    const getRedirectDomain = () => `${asUrl.protocol}//${asUrl.host}`;

    // follow the lifeomic.com/<app>/dest convention
    const getApp = () => splitPath[1]; // 0 index would be a blank string

    const getDestination = () => {
      if (splitPath.length <= 2) return;

      const withoutApp = [...splitPath];
      withoutApp.splice(1, 1);
      return withoutApp.join('/');
    };

    const getSearch = () => {
      if (!asUrl?.search) return {};

      return queryString.parse(
        asUrl.search,
        { decode: false } // url search is already decoded
      );
    };

    return {
      subDomain: parseSubdomainFromUrl(asUrl),
      redirectDomain: getRedirectDomain(),
      app: getApp(),
      destination: getDestination(),
      locationHash: asUrl.hash,
      ...getSearch(),
    };
  } catch (e) {
    console.error('Error occurred parsing originalUrl Param', e);
    return emptyReturnVal;
  }
};

export default function parseQueryParams(
  urlSearch: string,
  locationUrl: URL
): ParsedParams {
  const {
    redirectDomain,
    subDomain,
    app,
    destination,
    originalUrl,
    // a param from cognito, not the app
    code,
    'login-app-redirect-url': loginAppRedirectUrl,
    clientId,
    idp,
    customClientIdAccount,
    ...search
  }: InputParams = queryString.parse(urlSearch) as InputParams;

  // explicit params should override those derived from originalUrl convencience param
  const {
    subDomain: originalUrlSubDomain,
    redirectDomain: originalUrlRedirectDomain,
    app: originalUrlApp,
    destination: originalUrlDestination,
    locationHash,
    ...originalUrlSearch
  } = parseOriginalUrl(originalUrl);

  const loginApp = config.loginApp ?? LoginApps.phc;
  const shouldParseSubdomain =
    loginApp === LoginApps.phc || loginApp === LoginApps.marketplace;

  return {
    subDomain:
      subDomain ||
      originalUrlSubDomain ||
      (shouldParseSubdomain ? parseSubdomainFromUrl(locationUrl) : undefined),
    redirectDomain: sanitizeUrl(redirectDomain) || originalUrlRedirectDomain,
    app: app || originalUrlApp,
    destination: destination || originalUrlDestination,
    ...originalUrlSearch,
    ...search,
    code,
    'login-app-redirect-url': sanitizeUrl(loginAppRedirectUrl),
    clientId,
    idp: ['google', 'facebook', 'signinwithapple'].includes(idp as string)
      ? (idp as IDP)
      : undefined,
    locationHash,
    customClientIdAccount,
  };
}
