import React from 'react';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Paper, PaperProps } from '@lifeomic/chroma-react/components/Paper';
import clsx from 'clsx';
import useLoginApp from '../../redux/hooks/useLoginApp';
import { LoginApps } from '../../util/loginApps';

export const useStyles = makeStyles((theme: Theme) => ({
  root: ({ loginApp }: CustomAuthPromptPaperProps) => ({
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(0),
    maxWidth: '100%',
    width: 330,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    marginTop: loginApp === LoginApps.phc ? 'auto' : null,
    marginBottom: loginApp === LoginApps.phc ? 'auto' : null,
    position: 'relative',
  }),
}));

export interface LoginAppProps {
  loginApp: string;
}

export type AuthPromptPaperClasses = GetClasses<typeof useStyles>;

export interface AuthPromptPaperOwnProps {
  className?: string;
}

export type AuthPromptPaperProps = AuthPromptPaperOwnProps & PaperProps;

type CustomAuthPromptPaperProps = AuthPromptPaperProps & LoginAppProps;

const AuthPromptPaper = (props: AuthPromptPaperProps) => {
  const loginApp = useLoginApp();
  const { children, className, ...paperProps } = props;

  const customProps = { ...props, ...{ loginApp } };

  const classes = useStyles(customProps);

  return (
    <Paper className={clsx(classes.root, className)} {...paperProps}>
      {props.children}
    </Paper>
  );
};

export default React.memo(AuthPromptPaper);
