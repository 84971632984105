import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Button } from '@lifeomic/chroma-react/components/Button';
import clsx from 'clsx';
import useIsMobile from '../../../hooks/useIsMobile';
import { useSelector, useDispatch } from 'react-redux';
import { loginSelectors } from '../../../redux/modules/Login';
import useIsCustomSSOProvider from '../../../hooks/useIsCustomSSOProvider';
import { push } from 'connected-react-router';
import urls from '../../../util/urls';
import { getTestProps } from '../../../util/testProps';
import { SignInButtonProps } from '.';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';

export const testIds = {
  signInButton: 'signInButton-button',
};

const getMessages = (isLifeologyLogin: boolean) => {
  const signInButtonDefaultMessage = isLifeologyLogin ? 'LOGIN' : 'Sign in';

  return {
    ssoSignInButtonText: {
      id: 'signInButton.ssoSignInButtonText',
      defaultMessage: 'Sign in with SSO',
    },
    signInButtonText: {
      id: 'signInButton.signInButtonText',
      defaultMessage: signInButtonDefaultMessage,
    },
  };
};

const getHoverStyles = (theme: Theme, isLifeologyLogin: boolean) => {
  const lifeologyHoverStyles = {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  };

  const wellnessHoverStyles = {
    color: theme.palette.graphite[900],
    backgroundColor: theme.palette.graphite[100],
  };

  return isLifeologyLogin ? lifeologyHoverStyles : wellnessHoverStyles;
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: ({ isLifeologyLogin }: SharedSignInButtonProps) => ({
    marginTop: theme.spacing(2),
    height: 40,
    color: isLifeologyLogin
      ? theme.palette.common.white
      : theme.palette.primary.main,
    backgroundColor: isLifeologyLogin
      ? theme.palette.common.black
      : theme.palette.common.white,
    boxShadow: theme.boxShadows.table,
    fontWeight: isLifeologyLogin ? 'normal' : theme.typography.fontWeightBold,
    '&:hover': getHoverStyles(theme, isLifeologyLogin),
  }),
  mobileRootVariant: {
    width: 250,
    maxWidth: '80%',
  },
}));

export type SignInButtonClasses = GetClasses<typeof useStyles>;

export interface LoginAppProps {
  isLifeologyLogin: boolean;
}

export type SharedSignInButtonProps = SignInButtonProps & LoginAppProps;

const SharedSignInButton = (props: SignInButtonProps) => {
  const formatMessage = useIntl().formatMessage;
  const loginApp = useLoginApp();
  const isLifeologyLogin = LoginApps.lifeology === loginApp;
  const messages = getMessages(isLifeologyLogin);
  const styleProps = { ...props, ...{ isLifeologyLogin } };
  const classes = useStyles(styleProps);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isCustomOAuthProvider = useIsCustomSSOProvider();
  const sso = props.sso === undefined ? isCustomOAuthProvider : props.sso;
  const originalUrl = useSelector(loginSelectors.selectOriginalUrl);

  const setLogin = useCallback(() => {
    dispatch(push(urls.app.roots.login({ originalUrl })));
  }, []);
  const loginUrl = useSelector(loginSelectors.selectCognitoLoginUrl);

  // if custom SSO in use, use cognito UI.
  return (
    <Button
      {...getTestProps(testIds.signInButton)}
      className={clsx(
        classes.root,
        isMobile && classes.mobileRootVariant,
        props.className
      )}
      onClick={!sso ? setLogin : null}
      data-href={sso ? loginUrl : null}
    >
      {formatMessage(
        sso ? messages.ssoSignInButtonText : messages.signInButtonText
      )}
    </Button>
  );
};

export default SharedSignInButton;
