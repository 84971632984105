import * as React from 'react';

const SvgCornerLeftDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgCornerLeftDown"
    {...props}
  >
    <path
      d="M8.9 21V8a5 5 0 015-5H21M8.9 21L3 15.105M8.9 21l5.9-5.895"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgCornerLeftDown;
