import React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles, Theme } from '@lifeomic/chroma-react/styles';
import { GetClasses } from '@lifeomic/chroma-react/typeUtils';
import { Link } from '@lifeomic/chroma-react/components/Link';
import { Text } from '@lifeomic/chroma-react/components/Text';
import { Button } from '@lifeomic/chroma-react/components/Button';
import clsx from 'clsx';
import { getTestProps } from '../../../util/testProps';
import useLoginApp from '../../../redux/hooks/useLoginApp';
import { LoginApps } from '../../../util/loginApps';

const messages = {
  resendLabel: {
    id: 'resendMessage.resendLabel',
    defaultMessage: "Didn't receive a code?\u00a0\u00a0",
  },
  resendLinkText: {
    id: 'resendMessage.resendLinkText',
    defaultMessage: 'Resend',
  },
  resending: {
    id: 'resendMessage.resending',
    defaultMessage: 'Resending code...',
  },
};

export const testIds = {
  link: 'resendMessage-link',
};

export const useStyles = makeStyles((theme: Theme) => ({
  labelBase: ({ isLifeologyLogin }: SharedResendMessageProps) => ({
    color: isLifeologyLogin ? null : theme.palette.primary.main,
    display: 'inline-block',
  }),
  boldText: ({ isLifeologyLogin }: SharedResendMessageProps) =>
    isLifeologyLogin
      ? {}
      : {
          fontWeight: theme.typography.fontWeightBold,
        },
}));

export type ResendMessageClasses = GetClasses<typeof useStyles>;

export interface ResendMessageOwnProps {
  onResend: () => void;
  isLoading: boolean;
}

export interface LoginAppProps {
  isLifeologyLogin: boolean;
}

export type ResendMessageProps = ResendMessageOwnProps;

export type SharedResendMessageProps = ResendMessageProps & LoginAppProps;

const ResendMessage = (props: ResendMessageProps) => {
  const formatMessage = useIntl().formatMessage;
  const loginApp = useLoginApp();
  const isLifeologyLogin = loginApp === LoginApps.lifeology;
  const customProps = { ...props, ...{ isLifeologyLogin } };
  const classes = useStyles(customProps);

  if (props.isLoading) {
    return (
      <Text size="body" className={classes.labelBase}>
        {formatMessage(messages.resending)}
      </Text>
    );
  } else {
    return (
      <>
        <Text size="body" className={classes.labelBase}>
          {formatMessage(messages.resendLabel)}
        </Text>
        {isLifeologyLogin ? (
          <Button
            {...getTestProps(testIds.link)}
            onClick={props.onResend}
            variant="text"
          >
            {formatMessage(messages.resendLinkText)}
          </Button>
        ) : (
          <Link
            {...getTestProps(testIds.link)}
            to="#"
            onClick={props.onResend}
            color="default"
            className={clsx(classes.labelBase, classes.boldText)}
          >
            {formatMessage(messages.resendLinkText)}
          </Link>
        )}
      </>
    );
  }
};

export default React.memo(ResendMessage);
