import { makeStyles } from '@lifeomic/chroma-react/styles';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  background: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '5rem',
    '@media screen and (min-height: 480px)': {
      height: '10rem',
    },
    '@media screen and (min-height: 1024px)': {
      height: '19.5rem',
    },
  },
  card: {
    marginTop: '-2rem',
  },
}));

export const MarketplaceBrandedBackground: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({ children }) => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <div className={classes.card}>{children}</div>
    </div>
  );
};
