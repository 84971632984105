import * as React from 'react';

export const Facebook = (props: any) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2,31.5V21.465h3.369l.5-3.911H22.2v-2.5c0-1.132.315-1.9,1.938-1.9h2.071v-3.5A27.59,27.59,0,0,0,23.2,9.5c-2.986,0-5.03,1.823-5.03,5.17v2.884H14.788v3.911h3.377V31.5Z"
        fill="#3b579d"
      />
    </svg>
  );
};
