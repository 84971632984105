import React from 'react';
import ErrorFallback from './ErrorFallback';
import { MessageDescriptor } from 'react-intl';

export interface ErrorBoundaryOwnProps {
  intlErrorMessage: MessageDescriptor;
  FallbackComponent: React.Component;
}

export type ErrorBoundaryProps = ErrorBoundaryOwnProps;

export type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  componentDidCatch(caughtError: any, _info: any) {
    console.error(caughtError);
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;

    const { intlErrorMessage, children, FallbackComponent } = this.props;

    const FallbackComponentToUse = FallbackComponent || (
      <ErrorFallback intlErrorMessage={intlErrorMessage} />
    );

    return hasError ? FallbackComponentToUse : children;
  }
}

export default ErrorBoundary;
