import { ButtonLink } from '@lifeomic/chroma-react/components/ButtonLink';
import { defineMessages, useIntl } from 'react-intl';
import * as React from 'react';
import urls from '../../util/urls';

const messages = defineMessages({
  signInSSO: {
    id: 'signInSSOButton',
    defaultMessage: 'Sign in with SSO',
  },
});

interface SignInSSOButtonProps {
  className?: string;
}

export const SignInSSOButton: React.FC<SignInSSOButtonProps> = ({
  className,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ButtonLink
      className={className}
      to={`${urls.app.roots.ssoRedirect}${window.location.search}`}
      variant="text"
    >
      {formatMessage(messages.signInSSO)}
    </ButtonLink>
  );
};
