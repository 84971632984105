import * as React from 'react';

const SvgUploadCloud = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgUploadCloud"
    {...props}
  >
    <path
      d="M15.588 7.8a5.437 5.437 0 011.8 0 4.276 4.276 0 012.237 1.175 4.612 4.612 0 011.263 2.225 4.478 4.478 0 01-.088 2.413 4.356 4.356 0 01-1.212 1.887m-15.4-1.1a7.176 7.176 0 01-.9-1.875 6.847 6.847 0 01-.3-2.025 6.544 6.544 0 011.887-4.561A6.324 6.324 0 019.388 4a6.351 6.351 0 014.65 1.863 6.527 6.527 0 011.95 4.637m.1 5.6l-2.05-2.2-2.05-2.2V20m0-8.3l-2 2.2-2 2.2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgUploadCloud;
