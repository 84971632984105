import config from './config';
import { LoginApps } from './loginApps';

const isRelativePath = (url: string) => {
  try {
    return !/^(?:[a-z]+:)?\/\//i.test(url);
  } catch (e) {
    console.error(
      `Error occured checking if redirect "${url}" is a relative path`,
      e
    );
    return false;
  }
};

const loginAppSubdomains: Record<LoginApps, string> = {
  [LoginApps.phc]: 'apps',
  [LoginApps.lifeology]: 'app',
  [LoginApps.marketplace]: 'marketplace',
  [LoginApps.skillspring]: 'apps',
};

const whiteListedRedirectSubdomains: Record<LoginApps, string[]> = {
  [LoginApps.phc]: [],
  [LoginApps.lifeology]: [],
  [LoginApps.marketplace]: [],
  [LoginApps.skillspring]: ['console', 'connect-console'],
};

const isDestinationDomainWhitelisted = (subdomain?: string) => {
  const loginApp = config.loginApp ?? LoginApps.phc;
  return whiteListedRedirectSubdomains[loginApp].includes(subdomain);
};

const getSubdomain = (url: URL) => {
  const loginApp = config.loginApp ?? LoginApps.phc;
  const parts = url.host.split('.');
  return parts.length > 1 && parts[1] === loginAppSubdomains[loginApp]
    ? parts.shift()
    : '';
};

// Ensure that redirects are within the same domain
export default (destinationUri: string, currentUri: string) => {
  if (isRelativePath(destinationUri)) return destinationUri;

  try {
    const curParsed = new URL(currentUri);
    const parsed = new URL(destinationUri);
    if (parsed.host === curParsed.host) return destinationUri;

    const getDestinationWithDomain = (domain: string) => {
      const copied = new URL(parsed.href);
      copied.host = domain;
      return copied.href;
    };

    if (curParsed.hostname === 'localhost')
      return getDestinationWithDomain(curParsed.host);

    // replace host, but ensure subdomain is added
    const currentSubdomain = getSubdomain(curParsed);
    const destSubdomain = getSubdomain(parsed);

    if (!destSubdomain || currentSubdomain === destSubdomain) {
      // allow redirecting to a whitelisted destination subdomain (e.g., <subdomain.?><whitelisted>.<parentdomain>)
      const curParent = currentSubdomain
        ? curParsed.host.replace(`${currentSubdomain}.`, '')
        : curParsed.host;
      const curParentSubdomain = curParent.split('.')[0];
      const destParentSubdomain = parsed.host
        .replace(currentSubdomain, destSubdomain)
        .split('.')[0];
      if (isDestinationDomainWhitelisted(destParentSubdomain)) {
        return getDestinationWithDomain(
          curParent.replace(curParentSubdomain, destParentSubdomain)
        );
      }
      // no subdomain swap necessary
      return getDestinationWithDomain(curParsed.host);
    } else if (!currentSubdomain) {
      // prepend dest subdomain to current host
      return getDestinationWithDomain(`${destSubdomain}.${curParsed.host}`);
    } else {
      // replace the subdomain
      const newDomain = curParsed.host.replace(currentSubdomain, destSubdomain);
      return getDestinationWithDomain(newDomain);
    }
  } catch (e) {
    console.error(`Error occurred parsing destination "${destinationUri}"`, e);
    return currentUri;
  }
};

export const getSafeCookieParent = (currentUri: string) => {
  try {
    const curParsed = new URL(currentUri);
    if (curParsed.hostname === 'localhost') return curParsed.host;

    const currentSubdomain = getSubdomain(curParsed);

    const curParent = currentSubdomain
      ? curParsed.host.replace(`${currentSubdomain}.`, '')
      : curParsed.host;
    const curParentSubdomain = curParent.split('.')[0];
    return curParent.replace(`${curParentSubdomain}.`, '');
  } catch (e) {
    console.error(
      `Error occurred parsing current uri for cookie parent "${currentUri}"`,
      e
    );
    return currentUri;
  }
};
