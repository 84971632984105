import React from 'react';
import { useSelector } from 'react-redux';
import getBugsnagClient from './getBugsnagClient';
import ErrorBoundary from './ErrorBoundary';
import { selectActiveAccountId } from '@lifeomic/phc-web-toolkit/dist/redux/modules/account/selectors';
import { selectActiveUserId } from '@lifeomic/phc-web-toolkit/dist/redux/modules/user/selectors';
import { selectActiveProjectId } from '@lifeomic/phc-web-toolkit/dist/redux/modules/projectPicker/selectors';
import { MessageDescriptor } from 'react-intl';
import ErrorFallback from './ErrorFallback';
import config from '../config';

const apiKeySelector = (state: any) => state.config.bugsnagKey as string;
const releaseStageSelector = (state: any) =>
  state.config.bugsnagReleaseStage as string;

export interface BugsnagErrorBoundaryOwnProps {
  intlErrorMessage: MessageDescriptor;
  FallbackComponent: React.Component;
  options?: any;
}

export type BugsnagErrorBoundaryProps = BugsnagErrorBoundaryOwnProps & any;

const BugsnagErrorBoundary = (props: BugsnagErrorBoundaryProps) => {
  const {
    options = {},
    children,
    intlErrorMessage,
    FallbackComponent,
    ...toPass
  } = props;

  const apiKey = useSelector(apiKeySelector);
  const releaseStage = useSelector(releaseStageSelector);
  const activeAccountId = useSelector(selectActiveAccountId);
  const userId = useSelector(selectActiveUserId);
  const activeProjectId = useSelector(selectActiveProjectId);

  if (config.disableBugsnagReporting === 'true') {
    return (
      <ErrorBoundary
        FallbackComponent={FallbackComponent}
        intlErrorMessage={intlErrorMessage}
      >
        {children}
      </ErrorBoundary>
    );
  }

  try {
    const beforeSend = (report: any) => {
      report.metaData.account = activeAccountId;
      report.metaData.activeProjectId = activeProjectId;
      report.breadcrumbs = report.breadcrumbs.map((b: any) => {
        if (
          (b.metaData || {}).targetText &&
          (b.metaData || {}).targetText !== '(...)'
        ) {
          b.metaData.targetText = '[REDACTED]';
        }
        return b;
      });
    };
    const client = getBugsnagClient({
      apiKey,
      releaseStage,
      beforeSend,
      ...options,
    });
    client.user = { id: userId };

    const BugsnagBoundary = client.getPlugin('react');
    const FallbackComponentToUse = FallbackComponent ? (
      <FallbackComponent />
    ) : (
      <ErrorFallback intlErrorMessage={intlErrorMessage} />
    );

    return (
      <BugsnagBoundary FallbackComponent={FallbackComponentToUse} {...toPass}>
        {children}
      </BugsnagBoundary>
    );
  } catch (e) {
    return (
      <ErrorBoundary
        FallbackComponent={FallbackComponent}
        intlErrorMessage={intlErrorMessage}
      >
        {children}
      </ErrorBoundary>
    );
  }
};
export default React.memo(BugsnagErrorBoundary);
