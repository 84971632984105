import { featuresActions } from '@lifeomic/phc-web-toolkit/dist/redux/modules/features';

export default function lifeOmicHelpers(store: any) {
  if (
    !store ||
    typeof store !== 'object' ||
    typeof store.dispatch !== 'function'
  ) {
    throw new Error('Invalid store provided to lifeOmicHelpers');
  }
  return {
    toggleFeature(feature: string, value?: boolean) {
      store.dispatch(featuresActions.toggleFeature(feature, value));
    },
  };
}
