import * as React from 'react';

const SvgMaximize2 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    stroke="currentColor"
    viewBox="0 0 24 24"
    data-icon="SvgMaximize2"
    {...props}
  >
    <path
      d="M21 3l-7 7M3 21l7-7m5-11h6v6M9 21H3v-6"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default SvgMaximize2;
