import React from 'react';
import ContentContainer from './ContentContainer';
import { Fade } from '@mui/material';
import useIsMobile from '../../hooks/useIsMobile';
import MobileContentContainer from './MobileContentContainer';
import { EntryRootProps } from '.';

const PhcEntryRoot = (props: EntryRootProps) => {
  const isMobile = useIsMobile();

  return (
    <Fade in>
      <div>
        {isMobile ? (
          <MobileContentContainer isInitializing={props.isInitializing} />
        ) : (
          <ContentContainer isInitializing={props.isInitializing} />
        )}
      </div>
    </Fade>
  );
};

export default PhcEntryRoot;
